import React, { Component, Suspense } from "react";
import NavBar from "./NavBar";
import {
  Icon,
  Toolbar,
  List,
  ListHeader,
  ListTitle,
  ListItem,
} from "react-onsenui";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { setLanguage } from "./lib/change_lang";
import "./i18n";
setLanguage();

class Settings extends Component {
  // renderToolbar(){
  //   return (
  //     <div style={{position:'absolute',top: '0px',left: '0px', margin: '0px', padding: '10px',height: '19px', width: '100%',backgroundColor: '#FFFFFF', borderBottom: '1px solid #CCCCCC'}}>
  //       {/* <div className='left' onClick={()=> this.props.history.goBack()}>Back</div> */}
  //     <div className="center" style={{fontWeight: 'bold', textAlign: 'center'}}>More</div>
  //     </div>
  //   );
  // }

  renderToolbar() {
    return (
      <Toolbar>
        <div className="left"></div>
        <div className="center" style={{ width: "100%", fontWeight: "bold" }}>
          {this.props.t("Settings")}
        </div>
        <div className="right"></div>
      </Toolbar>
    );
  }

  setLanguage(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  }

  render() {
    let lang = localStorage.getItem("language");
    const { t } = this.props;
    return (
      <div style={{ padding: "40px 0 0 0" }}>
        <Toolbar>
          <div className="left"></div>
          <div className="center" style={{ width: "100%", fontWeight: "bold" }}>
            {t("Settings")}
          </div>
          <div className="right"></div>
        </Toolbar>
        <List>
          <ListItem>
            <span className="link" onClick={() => this.setLanguage("en")}>
              English
            </span>
          </ListItem>
          <ListItem>
            <span className="link" onClick={() => this.setLanguage("cn")}>
              中文 - Chinese (Traditional)
            </span>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Link to={"/faq_" + lang}>
              <span>{t("FAQ")}</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/terms-of-use">
              <span>{t("settings.terms_of_use")}</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/logout">
              <span>{this.props.t("Logout")}</span>
            </Link>
          </ListItem>
        </List>
        <List>
          <ListItem expandable>
            <span className="link">{t("settings.about_us")}</span>
            <div className="expandable-content">
              <p>
                <strong>Temblor, Inc.</strong> is an innovative catastrophe
                modeling company specializing in seismic hazard and risk
                assessment. Founded in 2014 by former USGS scientists Ross Stein
                and Volkan Sevilgen, Temblor’s mission is to raise awareness of
                seismic risk, and to inspire people to reduce it. We serve the
                insurance, reinsurance and insurance-linked security
                communities, and mortgage lenders. We also provide the public
                our free global mobile risk app and Temblor Earthquake News,
                scientifically sound articles that report about breaking
                earthquake news and discoveries.{" "}
              </p>
              <p>
                <a
                  href="https://temblor.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Temblor Website
                </a>{" "}
              </p>
            </div>
          </ListItem>
        </List>
        <List>
          <ListTitle>Our Regional Partners</ListTitle>
          <List>
            <ListItem expandable>
              <span className="link">E-Dream - Taiwan</span>
              <div className="expandable-content">
                {" "}
                <p>
                  {" "}
                  <strong>
                    Earthquake Disaster & Risk Evaluation and Management Center
                    (E-DREaM)
                  </strong>{" "}
                  brings together experts and scholars in the disaster chains,
                  including the Department of Earth Sciences, the Institute of
                  Applied Geology, the Department of Atmospheric Sciences, the
                  Institute of Hydrology and Oceanography and the Department of
                  Civil Engineering of the National Central University, to
                  integrate and analyze the risk factors of earthquakes,
                  tsunami, landslides, landslide dam, soil liquefaction and
                  extreme weather systems, developing into a specialized agency
                  linking academics with insurance, technology and engineering
                  industries. Based on the long-term earthquake research of
                  NCU’s College of Earth Sciences, the group develops disaster
                  risk assessment and management strategies with the industry.
                </p>
                <p>
                  <a
                    href="https://e-dream.tw/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    E-DREaM Website
                  </a>{" "}
                </p>
              </div>
            </ListItem>
          </List>
        </List>
        <NavBar />
      </div>
    );
  }
}

// export default Settings;
export default withTranslation("translations")(Settings);
