import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import ErrorBoundary from "./ErrorBoundary";
import i18n from "i18next";
import "./i18n";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  release: "temblor_app@4.1.0_10",
  dsn:
    "https://5943dd9252c74b59aeed9a310b39e125@o234976.ingest.sentry.io/5862964",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

function FallbackComponent() {
  return (
    <div>
      <div>An error has occurred</div>
      <div>Please restart the app</div>
    </div>
  );
}

const myFallback = <FallbackComponent />;

const history = createBrowserHistory();

const reactApp = () => {
  // ReactDOM.render(<BrowserRouter history={history} ><ErrorBoundary><App/></ErrorBoundary></BrowserRouter>, document.getElementById('root'));
  ReactDOM.render(
    <BrowserRouter history={history}>
      <Sentry.ErrorBoundary fallback={myFallback}>
        <App />
      </Sentry.ErrorBoundary>
    </BrowserRouter>,
    document.getElementById("root")
  );

  registerServiceWorker();
};

// const cordovaApp = () => {
//   ReactDOM.render(<HashRouter history={history} ><ErrorBoundary><App/></ErrorBoundary></HashRouter>, document.getElementById('root'));
//   registerServiceWorker();
// }

const cordovaApp = () => {
  /* window.cordova.plugins.Rollbar.init(); */
  ReactDOM.render(
    <HashRouter history={history}>
      <Sentry.ErrorBoundary fallback={myFallback}>
        <App />
      </Sentry.ErrorBoundary>
    </HashRouter>,
    document.getElementById("root")
  );
  registerServiceWorker();
};

// console.log(`ons.platform.isChrome() ${ons.platform.isChrome()}`);

if (window.cordova) {
  document.addEventListener("deviceready", cordovaApp, false);
} else {
  reactApp();
}
