import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { getAllAddresses } from "./lib/user";

const addresses = getAllAddresses();
let target = "";
if (addresses != null && addresses.length > 0) {
  target = `/risk/${addresses[0].location.slug}/${addresses[0].id}`;
} else {
  target = "/risk/";
}

class Home extends Component {
  render() {
    return <Redirect to={target} />;
  }
}

export default Home;
