import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import NavBar from "./NavBar";
import RiskData from "./RiskData";
import DummyAddressBox from "./DummyAddressBox";
import { Page } from "react-onsenui";
import { initAddress, setAddressLocation, saveAddress } from "./lib/user";
import auth from "./lib/auth";
import { decodeLocation, unslugify } from "./lib/helpers";
import { apiUrl, webAppUrl } from "./lib/config";
import Providers from "./Providers";
import { withRouter } from "react-router-dom";
import slugify from "slugify";

const api = apiUrl + "/risk";

class Risk extends Component {
  constructor(props) {
    super(props);
    const id = props.match.params.id;
    const { lat, lon } = decodeLocation(props.match.params.id);
    const slug = props.match.params.slug;
    const address = unslugify(slug);
    //console.log({ lat, lon, slug, address });

    const segment = props.match.params.segment || "risk";
    const buildingParams = {
      "home-type": "singlewood",
      years: 30,
      sqrft: 2000,
      yearbuilt: "1950-1975",
      "replacement-cost": 600000,
    };
    this.state = {
      id: props.match.params.id,
      loading: true,
      risk_data: {},
      lat,
      lon,
      address,
      segment,
      buildingParams,
      redirect: { value: false, to: "" },
    };

    initAddress(id);
    setAddressLocation(id, { lat, lon, slug, address });

    this.updateRiskData = this.updateRiskData.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }
  componentDidMount() {
    if (this.state.segment !== "earthquakes") {
      this.fetchRiskData(this.state.lat, this.state.lon);
    }
  }

  saveBuildingParams(params) {
    this.setState({ buildingParams: params });
  }

  updateRiskData(params) {
    this.setState({ buildingParams: params }, () =>
      this.fetchRiskData(this.state.lat, this.state.lon, params)
    );
  }

  redirect(lat, lon, id, address, url) {
    this.setState({ lat, lon, address, id }, () => {
      initAddress(id);
      setAddressLocation(id, { lat, lon, slug: slugify(address), address });
      this.props.history.push(url);

      this.fetchRiskData(
        this.state.lat,
        this.state.lon,
        this.state.buildingParams
      );
    });
  }

  setHazardData(data) {
    const chart = {
      null: "Unknown",
      0: "None",
      1: "Very low",
      2: "Low",
      3: "Moderate",
      4: "High",
      5: "Very high",
    };
    let hazardData = {
      flood: data.flood === "X" ? "Need insurance" : false,
      gear: "M" + data.gear.toFixed(1),
      liquefaction: chart[data.liquefaction],
      landslide: chart[data.landslide],
      tsunami: data.tsunami ? "In the zone" : "",
    };
    return hazardData;
  }

  fetchRiskData(lat, lon) {
    const params = this.state.buildingParams;
    const { id, address, buildingParams } = this.state;
    saveAddress({ id, lat, lon, address, building_details: buildingParams });
    const token = auth.getToken();
    this.setState({ loading: true });
    const retrofit = params["retrofit"] || "";
    const insurance_deductible = params["insurance_deductible"] || "";
    const apiString = `${api}?lon=${lon}&lat=${lat}&home-type=${params["home-type"]}&years=30&sqrft=${params.sqrft}&yearbuilt=${params.yearbuilt}&replacement-cost=${params["replacement-cost"]}&insurance_deductible=${insurance_deductible}&retrofit=${retrofit}&token=${token}`;
    fetch(apiString)
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.is_authorized && data.is_authorized === "false") {
          auth.handleInvalidToken();
        }
        this.setState(
          {
            risk_data: data,
            loading: false,
          },
          () => {
            /* console.log("line 125");
             * console.log(this.state); */
          }
        );
      })
      .catch(function (err) {
        if (err) console.log("ERR");
        /* console.error(err); */
      });
  }

  handleShare() {
    const url = `${webAppUrl}${this.props.match.url}`;
    const message = `Check Temblor's Earthquake Score and risk`;
    window.plugins.socialsharing.share(message, null, null, url);
  }

  renderToolbar() {
    return (
      <div>
        <NavBar />
      </div>
    );
  }

  render() {
    console.log(`ADDRESS: ${this.state.address}`);
    if (!auth.isLoggedIn()) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirect.value === true) {
      return <Redirect to={this.state.redirect.to} />;
    }
    return (
      <Page renderToolbar={this.renderToolbar}>
        <section>
          <DummyAddressBox
            placeholder={this.state.address}
            handleShare={this.handleShare}
            goLink="/risk"
          />
          <div
            style={{
              position: "relative",
              height: "47px",
              backgroundColor: "#FFFFFF",
              margin: "45px 0 0 0",
            }}
          >
            <Route
              exact
              path={this.props.match.path}
              render={() => (
                <RiskData
                  loading={this.state.loading}
                  id={this.state.id}
                  risk_data={this.state.risk_data}
                  updateRiskData={this.updateRiskData}
                  buildingParams={this.state.buildingParams}
                  lat={parseFloat(this.state.lat)}
                  lon={parseFloat(this.state.lon)}
                  eq_list={this.state.eq_list}
                  updateEqList={this.updateEqList}
                  eqParams={this.state.eqParams}
                  eqLoading={this.eqLoading}
                  redirect={this.redirect}
                  url={this.props.match.url}
                  address={this.state.address}
                />
              )}
            />
            <Route
              path={this.props.match.path + "/providers"}
              render={() => (
                <Providers
                  lat={this.state.lat}
                  lon={this.state.lon}
                  provider_type="insurance"
                  url={this.props.match.url}
                />
              )}
            />
          </div>
        </section>
      </Page>
    );
  }
}

export default withRouter(Risk);
