import React from "react";
export default function MapLogoTemblor() {
  return (
    <div id="logo">
      <a href="https://temblor.net/">
        <svg height={32} viewBox="0 0 264.9 79.5">
          <linearGradient
            id="temblorGradient"
            gradientUnits="userSpaceOnUse"
            x1="191.1136"
            y1="239.3733"
            x2="238.1072"
            y2="286.3669"
            gradientTransform="matrix(-1 0 0 -1 254.8748 302.3639)"
          >
            <stop offset={0} style={{ stopColor: "#52ff00" }} />
            <stop offset={1} style={{ stopColor: "#00f" }} />
          </linearGradient>
          <path
            fill="url(#temblorGradient)"
            d="M12.7,25.9c0-7.7,6.3-14,14-14h27.2c7.7,0,14,6.3,14,14v27.2c0,7.7-6.3,14-14,14H26.7c-7.7,0-14-6.3-14-14V25.9z"
          />
          <g fill="#fff">
            <polygon points="28.4,47.7 59.4,16.5 38.4,25 44.4,26.7 20.2,45.2" />
            <polygon points="52.9,29.4 21.7,60.8 42.6,52.1 36.8,50.5 61.2,31.7" />
          </g>
          <g fill="#a4ffff">
            <path d="M18.4,28.7c4.5-6.9,16.3-10.9,27.3-9.3c0,0-0.1,0-0.1,0c-11.2-2.5-24,1.4-28.7,8.5c-3.1,4.8-1.8,9.8,2.7,13.4C16.3,37.7,15.6,33.1,18.4,28.7z" />
            <path d="M31.7,25.5c1-0.3,2-0.5,3.1-0.6c-1.4,0-2.8,0.2-4.3,0.6c-5.2,1.6-8.2,5.7-6.6,9.1c0.4,0.9,1.2,1.7,2.1,2.4c-0.6-0.5-1-1.1-1.3-1.8C23,31.5,26.1,27.2,31.7,25.5z" />
          </g>
          <g fill="#c6fc00">
            <path d="M62.4,50.3c-4.3,6.9-16.1,11.1-27.4,9.9c0,0,0.1,0,0.1,0c11.5,2.1,24.4-2,28.8-9.2c2.9-4.8,1.4-9.7-3.4-13.1C64.2,41.4,65.1,45.9,62.4,50.3z" />
            <path d="M48.7,52.8c-1,0.2-2.1,0.4-3.1,0.4c1.4,0.1,2.9,0,4.4-0.3c5.4-1.2,8.5-5.1,7-8.5c-0.4-1-1.1-1.8-2.1-2.4c0.6,0.6,1,1.2,1.3,1.8C57.8,47.4,54.4,51.5,48.7,52.8z" />
          </g>
          <g fill="#010">
            <path d="M85.9,36.4c0-0.3-0.1-0.5-0.4-0.5h-3.1c-0.3,0-0.5-0.3-0.5-0.9c0-0.8,0.1-1.2,0.2-1.5c0.1-0.2,0.3-0.3,0.6-0.3h2.8c0.2,0,0.3,0,0.4-0.1C86,33,86,32.8,86,32.5c0-0.3,0-0.7,0-1.2c0-0.5,0-1,0.1-1.5c0-0.5,0.1-1,0.1-1.5c0-0.5,0.1-1,0.1-1.4c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.4-0.3,0.7-0.4c0.1-0.1,0.3-0.1,0.6-0.2c0.3-0.1,0.5-0.2,0.8-0.2c0.3-0.1,0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.1,0.5-0.1c0.5,0,0.7,0.3,0.6,0.9c-0.1,0.6-0.2,1.3-0.3,2c-0.1,0.7-0.1,1.4-0.2,2.1c-0.1,0.7-0.1,1.2-0.1,1.8c0,0.5,0,0.8,0,1c0,0.3,0.1,0.4,0.4,0.4h5.4c0.3,0,0.5,0.2,0.5,0.6c0,0.6-0.1,1.1-0.2,1.5c-0.1,0.4-0.3,0.6-0.5,0.6h-5.4c-0.2,0-0.3,0-0.3,0.1c-0.1,0-0.1,0.2-0.1,0.4c0,0.6-0.1,1.3-0.1,2c0,0.7-0.1,1.5-0.1,2.2c0,0.8,0,1.5,0,2.2c0,0.7,0,1.3,0,1.8c0,0.9,0,1.7,0.1,2.3c0,0.7,0.1,1.2,0.3,1.7c0.1,0.5,0.3,0.9,0.5,1.3c0.2,0.4,0.6,0.7,1,1c0.5,0.3,1,0.6,1.6,0.7c0.6,0.1,1.3,0.2,2,0.2c0.2,0,0.4,0,0.7,0c0.2,0,0.5,0,0.7-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.5c0,0.2-0.1,0.5-0.3,1c-0.2,0.5-0.4,0.8-0.7,0.9c-1,0.2-2.2,0.4-3.6,0.4c-2.2,0-3.8-0.5-5-1.6c-1.2-1.1-1.7-2.9-1.7-5.4c0-1.7,0-3.5,0.1-5.2C85.7,40.4,85.8,38.5,85.9,36.4z" />
            <path d="M104.8,44.3c-0.6,0.1-0.9,0.4-0.9,0.8c0,2.1,0.6,3.6,1.9,4.7c1.3,1.1,3.2,1.6,5.6,1.6c1.1,0,2.1-0.1,3-0.2c0.9-0.1,1.8-0.3,2.7-0.5c0.1,0,0.2,0,0.3,0s0.2,0.2,0.2,0.4c0,1-0.1,1.6-0.2,1.8c-0.1,0.2-0.3,0.4-0.5,0.5c-0.7,0.3-1.6,0.5-2.8,0.6c-1.2,0.2-2.3,0.3-3.4,0.3c-1.6,0-3-0.2-4.4-0.6s-2.5-1-3.5-1.8c-1-0.8-1.8-1.9-2.4-3.3c-0.6-1.3-0.9-3-0.9-5c0-1.8,0.3-3.4,0.8-4.9c0.6-1.4,1.3-2.7,2.3-3.7c1-1,2.2-1.8,3.5-2.3c1.3-0.5,2.8-0.8,4.4-0.8c2.8,0,4.9,0.6,6.3,1.9c1.5,1.3,2.2,3.4,2.2,6.5c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.8,0.4L104.8,44.3z M104.6,41.5c0.7-0.1,1.5-0.2,2.4-0.3c0.9-0.1,1.9-0.3,2.9-0.4c1-0.1,1.9-0.3,2.8-0.4c0.9-0.1,1.6-0.3,2.2-0.4c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.2,0.1-0.4c0-1.7-0.4-2.9-1.2-3.6c-0.8-0.7-2-1-3.6-1c-0.8,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.3,0.6-1.9,1.1c-0.6,0.5-1,1.1-1.4,1.8c-0.4,0.7-0.7,1.5-0.8,2.4c-0.1,0.3,0,0.6,0.1,0.7C104.1,41.4,104.3,41.5,104.6,41.5z" />
            <path d="M133.4,35.2c-0.7,0-1.3,0.1-1.8,0.2c-0.6,0.2-1.1,0.3-1.5,0.6c-0.4,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.4,0.5-0.5,0.8c-0.1,0.3-0.1,0.9-0.1,1.7s0,1.7,0,2.6v1.5c0,0.9,0,1.8,0,2.8s0.1,2,0.1,2.9s0.1,1.7,0.1,2.4c0,0.7,0,1.1,0,1.4c0,0.4-0.2,0.7-0.5,0.9c-0.3,0.2-0.8,0.2-1.5,0.2h-0.4c-0.8,0-1.3-0.1-1.6-0.2c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.2,0-0.7,0-1.4c0-0.7,0.1-1.5,0.1-2.3s0.1-1.8,0.1-2.8c0-1,0-1.9,0-2.8v-1.5c0-0.7,0-1.4,0-2.2c0-0.8-0.1-1.6-0.1-2.4c0-0.8-0.1-1.5-0.1-2.1c0-0.6,0-1.1,0-1.3c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.9-0.2,1.6-0.2h0.8c0.5,0,0.9,0.1,1.1,0.2c0.2,0.2,0.3,0.5,0.3,0.9v0.3c0,0.2,0.1,0.2,0.4,0c0.6-0.5,1.4-1,2.4-1.4c1-0.4,2-0.6,3.2-0.6c1.6,0,2.9,0.2,3.8,0.6c0.9,0.4,1.7,0.9,2.3,1.6c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2-0.1,0.3-0.1c0.7-0.5,1.7-1,2.9-1.5c1.2-0.5,2.6-0.7,4.2-0.7c3.1,0,5.3,0.7,6.6,2.2c1.3,1.5,2,3.6,2,6.3c0,1.8,0,3.8,0,6c0,2.2-0.1,4.3-0.1,6.3c0,0.3-0.2,0.6-0.4,0.7c-0.3,0.1-0.7,0.2-1.4,0.2h-0.9c-1.1,0-1.6-0.2-1.6-0.7c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0.1-0.7c0.2-1.9,0.3-3.7,0.4-5.3c0.1-1.7,0.2-3.3,0.2-4.9c0-0.9-0.1-1.7-0.3-2.5c-0.2-0.8-0.5-1.5-0.9-2.1s-1-1.1-1.7-1.5c-0.7-0.4-1.7-0.5-2.8-0.5c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.7,0.9-2.2,1.4c-0.1,0.1-0.1,0.1-0.2,0.3c-0.1,0.1-0.1,0.2,0,0.4c0.1,0.5,0.2,0.9,0.2,1.3c0,0.4,0,0.9,0,1.4c0,1.8,0,3.8,0,6c0,2.2-0.1,4.3-0.1,6.4c0,0.3-0.2,0.6-0.4,0.7s-0.8,0.2-1.5,0.2h-0.9c-1,0-1.5-0.2-1.5-0.7c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0.1-0.7c0.2-1.9,0.3-3.7,0.4-5.3c0.1-1.7,0.2-3.3,0.2-4.9c0-0.9-0.1-1.7-0.2-2.5c-0.1-0.8-0.3-1.5-0.7-2.1c-0.4-0.6-0.9-1.1-1.5-1.5C135.5,35.4,134.6,35.2,133.4,35.2z" />
            <path d="M168.3,33.8c1.5-1.1,3.4-1.6,5.6-1.6c1.5,0,2.9,0.2,4.1,0.6s2.3,1.1,3.2,2c0.9,0.9,1.6,2,2.1,3.4c0.5,1.3,0.7,2.9,0.7,4.7c0,1.8-0.2,3.3-0.6,4.8c-0.4,1.4-1.1,2.6-2,3.7c-0.9,1-2,1.8-3.3,2.4c-1.3,0.6-2.9,0.8-4.8,0.8c-1.1,0-2.2-0.1-3.3-0.4c-1.1-0.2-2-0.5-2.8-0.9c-0.2-0.1-0.3-0.1-0.5-0.1l-2.6,0.8c-0.2,0-0.4,0-0.6-0.1s-0.2-0.2-0.2-0.4c0-0.2,0-0.6,0-1.3c0-0.7,0.1-1.6,0.1-2.6c0-1,0.1-2.2,0.1-3.6c0-1.3,0-2.7,0-4.2v-7.7c0-2.1,0-3.9-0.1-5.4c0-1.5-0.1-2.8-0.1-3.8s-0.1-1.8-0.1-2.4c0-0.6-0.1-1-0.1-1.2c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.9-0.2,1.6-0.2h0.7c0.8,0,1.3,0.1,1.6,0.2c0.3,0.2,0.5,0.5,0.5,0.9c0,0.3,0,0.6-0.1,1.1c0,0.5-0.1,1.2-0.1,2.1c0,0.9-0.1,2.1-0.1,3.5c0,1.4,0,3.2,0,5.3v0.3c0,0.1,0.1,0.2,0.2,0.3S168.2,33.9,168.3,33.8z M167.8,48.9c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.4c0.6,0.4,1.3,0.7,2.1,0.9c0.8,0.2,1.6,0.3,2.5,0.3c2.5,0,4.2-0.7,5.4-2.2s1.7-3.5,1.7-6c0-1-0.1-2-0.3-3c-0.2-0.9-0.6-1.8-1.1-2.5c-0.5-0.7-1.2-1.3-2.1-1.7s-2-0.6-3.3-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.6,0.8-2,1.3c-0.2,0.2-0.3,0.5-0.3,0.9V48.9z" />
            <path d="M193.7,43.1c0,1.3,0,2.4,0,3.3c0,0.9,0.1,1.6,0.2,2.1c0.1,0.6,0.2,1,0.3,1.3c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.3,0.7,0.6,1.2,0.7c0.4,0.1,1,0.2,1.8,0.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.5,0c0.3,0,0.4,0.2,0.4,0.5c0,0.3-0.1,0.6-0.3,1.1c-0.2,0.5-0.4,0.8-0.7,1c-0.3,0.1-0.8,0.2-1.2,0.3c-0.5,0.1-1.1,0.1-1.9,0.1c-1.2,0-2.2-0.1-2.9-0.4c-0.7-0.3-1.3-0.7-1.7-1.2c-0.4-0.5-0.7-1.2-0.8-1.9c-0.1-0.8-0.2-1.6-0.2-2.6c0-1.2,0-2.2,0-3.3c0-1,0-2,0-3v-7.7c0-2.1,0-3.9-0.1-5.4c0-1.5-0.1-2.8-0.1-3.8s-0.1-1.8-0.1-2.4c0-0.6-0.1-1-0.1-1.2c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.9-0.2,1.6-0.2h0.7c0.7,0,1.2,0.1,1.5,0.2c0.3,0.2,0.5,0.5,0.5,0.9c0,0.3,0,0.7-0.1,1.2c0,0.6-0.1,1.4-0.1,2.4s-0.1,2.3-0.1,3.8c0,1.5-0.1,3.3-0.1,5.4V43.1z" />
            <path d="M212.8,32.1c1.7,0,3.2,0.3,4.5,0.8c1.3,0.5,2.5,1.2,3.4,2.2c0.9,0.9,1.6,2.1,2.1,3.4c0.5,1.3,0.7,2.7,0.7,4.3c0,1.6-0.3,3.1-0.8,4.5c-0.5,1.4-1.2,2.7-2.2,3.7c-1,1.1-2.1,1.9-3.5,2.5c-1.4,0.6-3,0.9-4.7,0.9c-3.5,0-6.2-1-8-2.9c-1.9-1.9-2.8-4.6-2.8-8c0-1.3,0.2-2.7,0.6-4.1c0.4-1.4,1.1-2.6,2-3.7c0.9-1.1,2.1-2,3.5-2.7C209,32.5,210.7,32.1,212.8,32.1z M205.5,43c0,1.1,0.1,2.1,0.4,3.1c0.3,1,0.7,1.9,1.2,2.7c0.6,0.8,1.3,1.4,2.1,1.9s1.9,0.7,3.1,0.7c1.2,0,2.3-0.2,3.2-0.6c0.9-0.4,1.6-1,2.2-1.7c0.6-0.7,1-1.6,1.3-2.6c0.3-1,0.4-2.1,0.4-3.3c0-0.9-0.1-1.8-0.4-2.8s-0.6-1.8-1.2-2.5s-1.2-1.4-2.1-1.9c-0.8-0.5-1.9-0.7-3.1-0.7c-1.3,0-2.4,0.2-3.3,0.6c-0.9,0.4-1.6,1-2.2,1.7c-0.6,0.7-1,1.5-1.2,2.5C205.6,40.9,205.5,41.9,205.5,43z" />
            <path d="M233.5,39.2c-0.1,0.1-0.1,0.3-0.2,0.7s-0.1,1-0.1,1.9v1.5c0,0.9,0,1.8,0,2.8c0,1,0.1,1.9,0.1,2.8c0,0.9,0.1,1.7,0.1,2.3c0,0.7,0,1.1,0,1.4c0,0.4-0.2,0.7-0.5,0.9c-0.3,0.2-0.8,0.2-1.5,0.2h-0.4c-0.8,0-1.3-0.1-1.6-0.2c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.2,0-0.7,0-1.4c0-0.7,0.1-1.5,0.1-2.3s0.1-1.8,0.1-2.8c0-1,0-1.9,0-2.8v-1.5c0-0.7,0-1.4,0-2.2c0-0.8-0.1-1.6-0.1-2.4c0-0.8-0.1-1.5-0.1-2.1c0-0.6,0-1.1,0-1.3c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.9-0.2,1.6-0.2h0.4c0.8,0,1.3,0.1,1.5,0.2c0.3,0.2,0.4,0.5,0.4,0.9l-0.1,1.3c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.3,0c0.4-0.3,0.9-0.8,1.7-1.3c0.7-0.6,1.5-1.1,2.3-1.7c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.3-0.6,1.7-0.6c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.6c0.1,0.2,0.2,0.6,0.3,1.1c0.1,0.5,0.2,1,0.2,1.4c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.7,0.2-1.5,0.4-2.3,0.8s-1.7,0.8-2.5,1.3s-1.6,1-2.3,1.5C234.5,38.2,233.9,38.7,233.5,39.2z" />
            <path d="M253.9,30.7c0,1.4-1.1,2.5-2.6,2.5c-1.4,0-2.6-1.1-2.6-2.5c0-1.4,1.1-2.5,2.6-2.5C252.8,28.2,253.9,29.3,253.9,30.7z M249.4,30.7c0,1.1,0.8,2,1.9,2c1.1,0,1.9-0.9,1.9-2c0-1.1-0.8-2-1.9-2S249.4,29.6,249.4,30.7z M251,32h-0.6v-2.5c0.2,0,0.5-0.1,1-0.1c0.5,0,0.7,0.1,0.9,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.3-0.2,0.5-0.5,0.6v0c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.4,0.1,0.5,0.2,0.6h-0.6c-0.1-0.1-0.1-0.3-0.2-0.6c0-0.3-0.2-0.4-0.5-0.4H251V32z M251,30.6h0.3c0.3,0,0.6-0.1,0.6-0.4c0-0.2-0.2-0.4-0.5-0.4c-0.2,0-0.3,0-0.3,0V30.6z" />
          </g>
        </svg>
      </a>
    </div>
  );
}
