import React, { Component } from "react";
import auth from "./lib/auth";
import { Redirect } from "react-router-dom";
import NavBar from "./NavBar";
import Blog from "./Blog";
import { Page } from "react-onsenui";

class Articles extends Component {
  renderToolbar() {
    return (
      <div>
        {/* <Toolbar /> */}
        <NavBar />
      </div>
    );
  }

  render() {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/login" />;
    }
    return (
      <Page renderToolbar={this.renderToolbar}>
        <Blog />
      </Page>
    );
  }
}

export default Articles;
