import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { BottomToolbar, Icon, Row, Col } from "react-onsenui";
import { useTranslation } from "react-i18next";
import { basePath } from "./lib/config";
// class NavBar extends Component {

// render() {
//   const { t } = useTranslation();
//   return (
//  <BottomToolbar aligned='center'>
//       <div style={{margin: '10px'}}>
//       <Row verticalAlign='center'>
//       <Col width='20%'  style={{textAlign: 'left'}}><NavLink exact className="navbar" activeClassName="active"  to="/articles">{t('Articles')}</NavLink></Col>
//         <Col width='35%' style={{textAlign: 'center'}}><NavLink className="navbar" activeClassName="active" to="/risk">Your Risk</NavLink></Col>
//         <Col width='35%'  style={{textAlign: 'center'}}><NavLink className="navbar" activeClassName="active" to="/earthquakes">Quakes</NavLink></Col>
//         <Col width='10%'  style={{textAlign: 'right'}}><NavLink  className="navbar" activeClassName="active" to="/more"><Icon size={{default: 20}} icon={{default: 'ion-ellipsis-horizontal'}} /></NavLink></Col>
//         </Row>
//         </div>
//   </BottomToolbar>

//   );
// }

export function NavBar() {
  const { t } = useTranslation();
  const ellipsis = basePath + "assets/images/three_dots_icon.png";
  return (
    <BottomToolbar aligned="center">
      <div style={{ margin: "10px" }}>
        <Row verticalAlign="center">
          {/* <Col  width="20%" style={{ textAlign: "left" }}> */}
          <Col width="33%" style={{ textAlign: "left" }}>
            <NavLink className="navbar" activeClassName="active" to="/risk">
              {t("risk")}
            </NavLink>
          </Col>
          <Col width="33%" style={{ textAlign: "center" }}>
            <NavLink
              exact
              className="navbar"
              activeClassName="active"
              to="/articles"
            >
              {t("articles")}
            </NavLink>
          </Col>

          {/* <Col style={{ textAlign: "center" }}>
		      <NavLink
		      className="navbar"
		      activeClassName="active"
		      to="/earthquakes"
		      >
		      {t("quakes")}
		      </NavLink>
		      </Col> */}
          <Col width="33%" style={{ textAlign: "right" }}>
            <NavLink className="navbar" activeClassName="active" to="/more">
              <img alt="" height="26" src={ellipsis} />
            </NavLink>
          </Col>
        </Row>
      </div>
    </BottomToolbar>
  );
}

export default NavBar;
