import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import React, { Component } from "react";
import Home from "./Home";
import Risk from "./Risk";
import Articles from "./Articles";
import RiskSearch from "./RiskSearch";
import BlogPost from "./BlogPost";
import Login from "./Login";
import Logout from "./Logout";
import Earthquakes from "./Earthquakes";
import Settings from "./Settings";
import TermsOfUse from "./TermsOfUse";
import Faq_cn from "./Faq_cn";
import Faq from "./Faq";
import auth from "./lib/auth";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import i18n from "i18next";
import "./i18n";
import { setLanguage } from "./lib/change_lang";
var ons = require("onsenui");
setLanguage();

ReactGA.initialize("UA-64670221-4", {
  debug: false,
  titleCase: false,
});

ons.disableAutoStyling();

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

/* function setLanguage() {
 *   const lang = navigator.language;
 *   console.log(`LANG: ${lang}`);
 *   i18n.changeLanguage("cn");
 *   const langInStorage = localStorage.getItem("language");
 *   if (langInStorage !== null) {
 *     i18n.changeLanguage(langInStorage);
 *   } else {
 *     if (lang.includes("zh")) {
 *       i18n.changeLanguage("cn");
 *       localStorage.setItem("language", "cn");
 *     } else {
 *       i18n.changeLanguage("en");
 *       localStorage.setItem("language", "en");
 *     }
 *   }
 * } */

class App extends Component {
  render() {
    /* setLanguage(); */
    // Initialize google analytics page view tracking
    ReactGA.set({ checkProtocolTask: null });
    // ReactGA.set({ page: window.location.pathname }); // Update the user's current page
    // ReactGA.pageview(window.location.pathname); // Record a pageview for the given page

    if (window.cordova) {
      //  Enable to debug issues.
      // window.plugins.OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});
      // Set your iOS Settings

      var iosSettings = {};
      iosSettings["kOSSettingsKeyAutoPrompt"] = true;
      iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;

      function notificationhandler(jsonData) {
        that.props.history.push(
          jsonData.notification.payload.additionalData.goto_url
        );
      }

      const that = this;
      /* window.plugins.OneSignal.setLogLevel({ logLevel: 6, visualLevel: 1 }); */
      window.plugins.OneSignal.startInit("07d9cc48-1c6c-4234-aafb-379ec5e5189e")
        .iOSSettings(iosSettings)
        .inFocusDisplaying(
          window.plugins.OneSignal.OSInFocusDisplayOption.Notification
        )
        .handleNotificationOpened(function (jsonData) {
          notificationhandler(jsonData);
        })
        .endInit();
    }

    return (
      <Switch>
        <Route path="/app" component={RiskSearch} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/risk" component={RiskSearch} />
        <PrivateRoute path="/risk/:slug/:id" component={Risk} />
        <Route path="/login" component={Login} />
        <PrivateRoute exact path="/articles" component={Articles} />
        <PrivateRoute path="/articles/:slug,:id" component={BlogPost} />
        <PrivateRoute path="/articles/:id" component={BlogPost} />
        <PrivateRoute exact path="/earthquakes/:id" component={Earthquakes} />
        <PrivateRoute path="/more" component={Settings} />
        <PrivateRoute path="/terms-of-use" component={TermsOfUse} />
        <PrivateRoute path="/faq_en" component={Faq} />
        <PrivateRoute path="/faq_cn" component={Faq_cn} />
        <PrivateRoute path="/logout" component={Logout} />
      </Switch>
    );
  }
}

export default withRouter(App);
