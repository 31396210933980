import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Toolbar, Page, List, ListItem, Icon} from 'react-onsenui';
import { apiUrl } from './lib/config';
import auth from './lib/auth';
import { cleanArticlesHtml } from './lib/helpers';
import logo from './temblor-icon-logo.svg';

const blogApi = apiUrl + "/blogposts";

class Blog extends Component {
  constructor(props){
    super(props);
    this.state = {
      blog_posts: [],
      page: 1, 
      loading: true
    }
  this.fetchItems = this.fetchItems.bind(this);
  }

  componentDidMount(){
    this.fetchItems();
  }

  fetchItems(){
    this.setState({loading: true});
    const token = auth.getToken();
    fetch(`${blogApi}/${this.state.page}?token=${token}`)
        .then(response => response.json())
        .then(data => {        
          //console.log(data);
          if (data.is_authorized && data.is_authorized === 'false'){
            auth.handleInvalidToken();
          }    
          let posts = this.state.blog_posts;
          posts.concat(data)
          this.setState({ blog_posts: posts.concat(data), page: this.state.page + 1,loading: false});
        }     
      );
  }

  renderToolbar(){
    return(
      <Toolbar> 
        <div className="left" style={{margin: '0'}}>
            <img src={logo} height='31'  style={{margin: '7px'}}/>
      </div>            
      <div className="center">      
        </div>      
      <div className="right">
      </div>
    </Toolbar>
    )
  }

  renderRow(row, index){
    const innerWidth = window.innerWidth;
    const imageUrl = cleanArticlesHtml(row.image && row.image['onepress-medium'] ? row.image['onepress-medium'].source_url: '');
    return(
        <ListItem key={index} className="bloglist">
        <Link to={`/articles/${row.slug},${row.id}`}>
           <div className='center' style={{position: 'relative', marginBottom: '10px', width: window.innerWidth, height: '200px', backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
              <span style={{position: 'absolute',bottom: '0px',left: '0px', right: '0px',backgroundColor: '#000000',color: '#FFFFFF', fontSize: '16px', padding: '10px',opacity: '0.75',lineHeight: '1.25'}}><span style={{color: '#FFFFFF', opacity: '0.80'}}>{row.title}</span></span>
            </div>
            </Link>
          </ListItem>
    );
  }

  effectiveHeight(){
    //return window.innerHeight-(48+47+5+44+25)
    return window.innerHeight-90;
  }

  render() {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/login" />;
    }
    return (
    <Page>
      {this.renderToolbar()}
      <section style={{paddingTop: '45px'}}>
      <div style={{overflowY: 'scroll', height:this.effectiveHeight()}}>
        <List
         dataSource={this.state.blog_posts}
         renderRow={this.renderRow}
       />
        <div style={{width: '100%', textAlign: 'center', margin: '20px 0px'}}>
        {this.state.loading === true &&
          <Icon icon="ion-ios-refresh" size={30} spin={true} />
        }
        {this.state.loading === false &&
         <span onClick={this.fetchItems}>Load more</span>
        }
        </div>
      </div>
      </section>
      </Page>
    );
  }
}

export default Blog;
