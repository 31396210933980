import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import auth from "./lib/auth";
import { Page, Icon, Toolbar } from "react-onsenui";
import { apiUrl, webAppUrl } from "./lib/config";
import { cleanArticlesHtml } from "./lib/helpers";
import ReactGA from "react-ga";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      post: {},
      loading: true,
    };
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentDidMount() {}

  renderToolbar() {
    return (
      <Toolbar>
        <div className="left" onClick={() => this.props.history.goBack()}>
          <Icon icon="ion-ios-arrow-left" size={24} />
        </div>
        <div
          className="center"
          style={{
            fontSize: "14px",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          常見問題
        </div>
        {window.cordova && (
          <div className="right" onClick={() => this.handleShare()}>
            <Icon icon="ion-share" size={24} />
          </div>
        )}
      </Toolbar>
    );
  }

  effectiveHeight() {
    //return window.innerHeight-(48+47+5+44+25)
    return window.innerHeight - 45;
  }

  render() {
    ReactGA.set({ page: this.props.match.url }); // Update the user's current page
    ReactGA.set({ title: this.state.post.title });
    ReactGA.pageview(this.props.match.url); // Record a pageview for the given page

    return (
      <Page>
        {this.renderToolbar()}
        <div style={{ marginTop: "45px" }}>
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: this.effectiveHeight(),
            }}
          >
            <div
              className="blogpost"
              style={{ margin: "10px", fontSize: "14px" }}
            >
              <p>
                <strong>
                  地震規模是什麼？地震規模與地表震動強度有何不同？
                </strong>
              </p>
              <p>
                地震規模是表示地震能量的單位。地表震動強度為地震所導致的位址震動程度，震動強度與其他因素影響有關。一個地震只有一個地震規模，地震動強度則隨著測量場址而有所不同。
              </p>
               
              <p>
                <strong>為什麼要同時看強地動地圖以及地震規模地圖？</strong>
              </p>
              <p>
                簡單來說：地震規模是重要指標，然而震度與地震危害相關性更高。
              </p>
              <p>
                 一個地震規模6.0的地震可能摧毀一棟建物卻同時對同一個城鎮的另一棟建築毫無影響。建築物所在的位置岩層類型、地震傳播的方向以及與震央的距離等因素都會影響地震中某個位置的震動強度。
                 
              </p>
               
              <p>
                <strong>Temblor 如何評估「一生中最大地震規模」？</strong>
              </p>
              <p>
                「一生中最大地震規模」意旨生命週期中發生該規模地震的機率比不發生還要高，因此需要針對該規模的地震進行防範。這個規模的地震每年有1%的機率發生在你的所在地周圍100公里以內。假使你活到85歲，你將有57%的機率在一生中遭遇到該規模的地震。
              </p>
               
              <p>
                在美國，如果你居住在每年有1%(也就是100年)發生洪水氾濫的平原，會被強制保洪水險。其實我們也以同樣的觀點告知地震風險。
              </p>
               
              <p>
                在台灣，我們利用台灣地震模型所計算的地震危害為基準（詳情請見：Chan
                et al., 2020)。台灣以外地區，我們從Bird
                等人的「全球地震活動率模型」推估「一生中最大地震規模」
                (詳情請見：Bull. Seismol. Soc. Amer., 2015)
                。經「國際地震可預測性研究國際合作中心」測試，此模型比其他模型準確性更高（詳情請見：Strader
                et al., Seismol. Res. Letts.,
                2018）。詳見「為什麼台灣的資料看起來和其他地區不一樣？」  
              </p>
               
              <p>
                <strong>Temblor 如何估算一地的「最大可能震動機率」?</strong>
              </p>
              <p>
                在美國，我們考慮美國地質調查所針對美國主要斷層上發生的850
                個景況地震；而台灣地區，為國立中央大學地震災害鏈風險評估及管理研究中心利用台灣地震模型計算的地震景況為基準。然後找出造成你的所在地最大震動強度的特徵地震，並且呈現最大地震動加速度、發生破裂的斷層及地震規模。
              </p>
               
              <p>
                <strong>Temblor 如何估算一地的「終生可能最大震動機率」?</strong>
              </p>
              <p>
                在台灣，我們利用台灣地震模型所評估的地震危害為基準。台灣以外地區，我們將「全球地震活動率」模型轉換為「概率均勻地震危害」的地震動模型，該模型為全球統一標準，並針對土壤場址放大進行校正。由美國地質調查所地震動模型訓練而成。
              </p>
               <p>關於PUSH.</p> 
              <p>
                在台灣以外，我們將地震動彙報為麥卡利震度（每年1%
                機率的震度)，以及由美國地質調查所定義的標準化損壞描述。
              </p>
               
              <p>
                <strong>  我可以用這些資訊做什麼？? </strong>
              </p>
              <p>
                地震造成的危害很少見，因此很容易被忽視。然而在毫無準備的狀況下面臨地震會造成災難性的後果。無論你正在買房子或忽然發現你居住在地震好發國家，對風險有認知是保護自己、家人以及你的財產的第一步。請參考我們推薦的供應商列表，與地震保險代理人、改造承包商和結構產品製造商聯繫，立即採取行動。
              </p>
                
              <p>
                <strong>什麼是土壤液化（在美國某些州為公開資料）？</strong>
              </p>
              <p>
                當地震震動鬆散的、水飽和的沉積物時，就可能發生液化，導致地面失去強度。當土壤液化發生時，房屋會嚴重毀損甚至倒塌。在美國，我們從州立地質調查所獲得土壤液化潛勢資料。在台灣，該數據來自於經濟部中央地質調查所土壤液化潛勢查詢系統。
              </p>
                
              <p>
                <strong>當地震發生在我附近時Temblor會通知我嗎？</strong>
              </p>
              <p>
                會的。當地震發生在您周圍時，Temblor會發送通知。請注意這不是地震預警系統，也不是通知您即將發生地震。世界上許多單位會發布地震預警。我們建議您尋找當地的相關機構以獲得資訊。
                 
              </p>
                
              <p>
                <strong>
                  Temblor 從哪裡獲得山崩潛勢數據（加州、俄勒岡和台灣）？
                </strong>
              </p>
              <p>台灣—李錫堤，Engineering geology，2014</p>  
              <p>奧勒岡—俄勒岡州地質礦產部</p>  <p>加州—加州地質調查所</p>  
              <p>
                <strong>Temblor 從哪裡獲得洪水潛勢數據（美國某些州）？</strong>
              </p>
              <p>聯邦緊急事務管理署  </p> 
              <p>
                <strong>Temblor 從哪裡獲得海嘯數據（美國和台灣）？</strong>
              </p>
              <p>美國 — 各州立機關</p>  
              <p>台灣 — 吳祚任， Journal of Earthquake and Tsunami，2012</p> 
              <p>
                <strong> Temblor 從哪裡獲得野火危害地圖（加州）？</strong>
              </p>
              <p>加州林業和消防部</p> 
              <p>為什麼台灣的資料看起來和其他地區不一樣？</p> 
              <p>
                我們與地震災害鏈風險評估及管理研究中心合作共同建立地震危害模型，整合中央氣象局地震資料以及台灣地震模型地震危害分析成果。
              </p>
               <p>E-DREaM 與 Temblor 的合作：</p> 
              <p>
                總部位於矽谷的Temblor,
                Inc與國立中央大學地震災害鏈風險評估及管理研究中心在高等教育深耕計畫的資助下，合作開發了
                「Temblor – Taiwan
                」風險應用程式。在台灣，該應用程式結合了台灣地震模型最新機率式與景況地震危害評估，以及中央氣象局和Palert地震網的數據，並包含液化、滑坡和海嘯等複合天災分佈圖。這些功能皆容易理解並開放給每個人使用。
              </p>
               
              <p>
                地震災害鏈風險評估及管理研究中心匯集國立中央大學地球科學系、應用地質研究所、大氣科學系、水文海洋研究所、土木工程系等災害鏈專家學者，分析地震、海嘯、山崩、堰塞湖、土壤液化和極端天氣系統等風險，發展成為連接學術界與保險、技術和工程公司的專業機構。以國立中央大學地球科學學院的長期地震研究為基礎，團隊與業界共同製定災害風險評估和管理策略。https://e-dream.tw/
              </p>
               
              <p>
                Temblor, Inc.
                是一家新創的災害模型公司，專門從事地震危害和風險評估。Temblor
                由前 美國地質調查所科學家 Ross Stein 和 Volkan Sevilgen 於 2014
                年創立，以提高人們對地震風險的認識為使命，並啟發大眾地震減災概念。我們為保險、再保險和保險相關的安全社區以及抵押貸款人提供服務。並且向公眾提供免費的全球風險手機應用程式和地震新聞，報導有關即時地震新聞和科學新知。https://temblor.net/about-temblor/
              </p>
               
              <p>
                <strong>參考資料</strong>
              </p>
              <p>
                Bird, P., Jackson, D. D., Kagan, Y. Y., Kreemer, C., & Stein, R.
                S. (2015). GEAR1: A Global Earthquake Activity Rate Model
                Constructed from Geodetic Strain Rates and Smoothed
                SeismicityGEAR1: A Global Earthquake Activity Rate Model
                Constructed from Geodetic Strain Rates and Smoothed Seismicity.
                Bulletin of the Seismological Society of America, 105(5),
                2538-2554.
              </p>
              <p>
                Lee, C. T. (2014). Statistical seismic landslide hazard
                analysis: An example from Taiwan. Engineering geology, 182,
                201-212.
              </p>
              <p>
                Strader, A., Werner, M., Bayona, J., Maechling, P., Silva, F.,
                Liukis, M., & Schorlemmer, D. (2018). Prospective evaluation of
                global earthquake forecast models: 2 yrs of observations provide
                preliminary support for merging smoothed seismicity with
                geodetic strain rates. Seismological Research Letters, 89(4),
                1262-1271.
              </p>
              <p>
                Wu, T. R. (2012). Deterministic study on the potential large
                tsunami hazard in Taiwan. Journal of Earthquake and Tsunami,
                6(03), 1250034.
              </p>
              <p>https://www.conservation.ca.gov/cgs</p>
              <p>https://www.fema.gov/</p>
              <p>https://www.fire.ca.gov/</p>
              <p>https://www.liquid.net.tw/cgs/public/</p>
              <p>https://www.oregongeology.org/</p>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default Faq;
