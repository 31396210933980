import React, { Component } from 'react';
import { List, ListItem, ListHeader, Icon } from 'react-onsenui'; 
import { Redirect } from 'react-router-dom';
import auth from './lib/auth';
import { apiUrl } from './lib/config';
import ReactGA from 'react-ga';

const api = apiUrl;

const providerTitles = {insurance: 'Insurance Agents', retrofit: 'Retrofit Contractors', engineer: 'Structural Engineers', consultant: "Prep Consultants"};
const safety_codes = {'before-1950': 0, '1950-1975': 1, 'after-1975': 2};

class Providers extends Component {
  constructor(props){
      super(props);
      let {id, lat, lon} = props;
      this.state = { providers_data: [], id, lat, lon};
  }

  componentDidMount() {
    this.fetchProvidersData(this.state.lat, this.state.lon, 'insurance');
    this.fetchProvidersData(this.state.lat, this.state.lon, 'retrofit');
    this.fetchProvidersData(this.state.lat, this.state.lon, 'engineer');
    this.fetchProvidersData(this.state.lat, this.state.lon, 'consultant');
  } 

  fetchProvidersData(lat, lon, provider_type) {
    const token = auth.getToken();
    this.setState({loading: true});
    fetch(`${api}/providers?lon=${lon}&lat=${lat}&tags=${provider_type}&token=${token}`)
        .then(response => response.json())
        .then(data => { 
          if (data.is_authorized && data.is_authorized === 'false'){
            auth.handleInvalidToken();
          }   
            let providers_data = this.state.providers_data;
            providers_data[provider_type] = data;
            this.setState({ providers_data });
        }     
      );
  }

  trackClick(data){
    ReactGA.event({
      category: 'Provider',
      action: 'Clicked to call',
      label: data
    });
  }

  effectiveHeight(){
    //return window.innerHeight-(48+47+5+44+25)
    return window.innerHeight-90;
  }

  render() {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/login" />;
    }
    ReactGA.set({ page: '/providers' }); // Update the user's current page
      ReactGA.set({ title: 'Providers' }); // Update the user's current page
      ReactGA.pageview('/providers'); // Record a pageview for the given page
  
    return ( 
      <div style={{marginTop: '45px', overflowY: 'scroll', height:this.effectiveHeight()}}>    
      <section>
         <ListHeader>{providerTitles['insurance']} in Your Area</ListHeader>
          <List
              dataSource={this.state.providers_data.insurance}
              renderRow={(row) => <a href={`tel:${row.phone}`} onClick={() => this.trackClick(`Insurance | ${row.name} | ${row.phone}`)}><ListItem tappable tapBackgroundColor='#0cce0c' key={row.gid}><div className='center'>{row.name}</div><div className='right'><span style={{marginLeft: '10px'}}><Icon icon='fa-phone' size={24} style={{color: '#0cce0c'}} /></span></div></ListItem></a>}
            />
            <ListHeader>{providerTitles['retrofit']} in Your Area</ListHeader>
          <List
              dataSource={this.state.providers_data.retrofit}
              renderRow={(row) => <a href={`tel:${row.phone}`}  onClick={() => this.trackClick(`Retrofit | ${row.name} | ${row.phone}`)}><ListItem tappable tapBackgroundColor='#0cce0c' key={row.gid}><div className='center'>{row.name}</div><div className='right'><span style={{marginLeft: '10px'}}><Icon icon='fa-phone' size={24} style={{color: '#0cce0c'}} /></span></div></ListItem></a>}
            />
             <ListHeader>{providerTitles['engineer']} in Your Area</ListHeader>
          <List
              dataSource={this.state.providers_data.engineer}
              renderRow={(row) => <a href={`tel:${row.phone}`}  onClick={() => this.trackClick(`Structural Engineer| ${row.name} | ${row.phone}`)}><ListItem tappable tapBackgroundColor='#0cce0c' key={row.gid}><div className='center'>{row.name}</div><div className='right'><span style={{marginLeft: '10px'}}><Icon icon='fa-phone' size={24} style={{color: '#0cce0c'}} /></span></div></ListItem></a>}
            />
             <ListHeader>{providerTitles['consultant']} in Your Area</ListHeader>
             <List
              dataSource={this.state.providers_data.consultant}
              renderRow={(row) => <a href={`tel:${row.phone}`}  onClick={() => this.trackClick(`Prep Consultant | ${row.name} | ${row.phone}`)}><ListItem tappable tapBackgroundColor='#0cce0c' key={row.gid}><div className='center'>{row.name}</div><div className='right'><span style={{marginLeft: '10px'}}><Icon icon='fa-phone' size={24} style={{color: '#0cce0c'}} /></span></div></ListItem></a>}
            />
          </section>
          </div>
   );
  }
}

export default Providers;
