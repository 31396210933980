import React from "react";

export default function MapLegends(region, t, data) {
  console.log(`MAPLEGENDS: ${region} `);
  const legends = {
    us: MapLegendsUs,
    tw: MapLegendsTw,
    global: MapLegendsGlobal,
  };
  return legends[region](t, data);
  /* return region === "us" ? MapLegendsUs(t, data) : MapLegendsTw(t, data); */
}

function MapLegendsUs(t, data) {
  return (
    <div id="legends">
      {gear(t, data)}
      {push(t, data)}
      {scenariosUs(t, data)}
      {landslideUs(t, data)}
      {wildfire(t, data)}
      {liquefaction(t, data)}
      {tsunami(t, data)}
      {flood(t, data)}
      {faultsQuakes(t, data)}
    </div>
  );
}

function MapLegendsTw(t, data) {
  return (
    <div id="legends">
      {cwbiShaking(t, data)}
      {scenariosTw(t, data)}
      {landslideTw(t, data)}
      {liquefaction(t, data)}
      {tsunamiTw(t, data)}
      {faultsQuakes(t, data)}
      {palert(t, data)}
    </div>
  );
}

function MapLegendsGlobal(t, data) {
  return (
    <div id="legends">
      {gear(t, data)}
      {push(t, data)}
      {faultsQuakes(t, data)}
    </div>
  );
}

function liquefaction(t, data) {
  return (
    <>
      <div className="liquefaction-legend legend" style={{ display: "none" }}>
        <h2>{t("liquefaction_susceptibility")}</h2>
        <div className="bar">
          <span style={{ background: "hsl(270,75%,90%,0.6)" }} />
          <span style={{ background: "hsl(270,75%,75%,0.6)" }} />
          <span style={{ background: "hsl(270,75%,60%,0.6)" }} />
          <span style={{ background: "hsl(270,75%,45%,0.6)" }} />
          <span style={{ background: "hsl(270,75%,30%,0.6)" }} />
        </div>
        <div className="bar">
          <span>{t("very_low")}</span>
          <span>{t("moderate")}</span>
          <span>{t("very_high")}</span>
        </div>
      </div>
    </>
  );
}

function landslideTw(t, data) {
  return (
    <>
      <div className="landslide-tw-legend legend" style={{ display: "none" }}>
        <h2>{t("landslide_susceptibility")}</h2>
        <div>{t("chance_of_lanslide_in_your_lifetime")}</div>
        <div className="bar">
          <span
            style={{
              background: "linear-gradient(to right, #c840 0%, #c84 100%)",
            }}
          />
          <span
            style={{
              background: "linear-gradient(to right, #c84 0%, #630 100%)",
            }}
          />
        </div>
        <div className="bounds">
          <span>0.001%</span>
          <span>0.03%</span>
          <span>&gt; 2.5%</span>
        </div>
      </div>
    </>
  );
}

function scenariosUs(t, data) {
  return (
    <>
      <div className="scenarios-us-legend legend" style={{ display: "none" }}>
        <h2>{t("strongest_shaking_expected")}</h2>
        <div className="legend-data">
          Mag. {data.scenarios_us.mag} earthquake is possible on{" "}
          {data.scenarios_us.fault} ({data.scenarios_us.pga}g, from USGS)
        </div>
      </div>
    </>
  );
}

function landslideUs(t, data) {
  return (
    <>
      <div className="landslide-us-legend legend" style={{ display: "none" }}>
        <h2>Landslide Susceptibility</h2>
        <div className="bar">
          <span style={{ background: "#c846" }} />
          <span style={{ background: "#c84c" }} />
          <span style={{ background: "#b77700" }} />
          <span style={{ background: "#8e5500" }} />
          <span style={{ background: "#630" }} />
        </div>
        <div className="bar">
          <span>Very low</span>
          <span>Moderate</span>
          <span>Very high</span>
        </div>
      </div>
    </>
  );
}

function push(t, data) {
  return (
    <>
      <div className="push-mmi-100-legend legend" style={{ display: "none" }}>
        <h2>Strongest shaking likely in your lifetime: {data.push}</h2>
        {/* <div className="legend-data">{data.push}</div> */}
        <div className="bounds">
          <span>Weak</span>
          <span>Strong</span>
          <span>Violent</span>
        </div>
        <div className="bar">
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ff00 0%, #b5e8ffff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ffff 0%, #00c4e3ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #00c4e3ff 0%, #91cc9eff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #91cc9eff 0%, #ffff8fff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffff8fff 0%, #ffcc00ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffcc00ff 0%, #ff9900ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff9900ff 0%, #ff0000ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff0000ff 0%, #7f0000ff 100%)",
            }}
          />
        </div>
        <div className="bounds">
          <span>III</span>
          <span>IV</span>
          <span>V</span>
          <span>VI</span>
          <span>VII</span>
          <span>VIII</span>
          <span>IX</span>
        </div>
        <div>Modified Mercalli Intensity at 1% per yr</div>
        <div>Based on Temblor PUSH</div>
      </div>
    </>
  );
}

function palert(t, data) {
  return (
    <>
      <div className="palert-legend legend" style={{ display: "none" }}>
        <h2>{t("palert")}</h2>
        <p id="palert-event-info" />
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "center",
          }}
        >
          <span
            style={{
              width: "60px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              className="circle"
              style={{
                width: "16px",
                height: "16px",
                background: "#cc00ff",
                marginBottom: "4px",
              }}
            />
            {t("earthquakes")}
          </span>
          <span style={{ flexDirection: "column", alignItems: "center" }}>
            <span style={{ width: "120px", justifyContent: "space-between" }}>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#fff" }}
                />
                0
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#b5e8ff" }}
                />
                1
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#00c4e3" }}
                />
                2
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#91cc9e" }}
                />
                3
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#ffff8f" }}
                />
                4
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#fc0" }}
                />
                5
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#f90" }}
                />
                6
              </span>
              <span style={{ flexDirection: "column" }}>
                <span
                  className="circle"
                  style={{ width: "9px", height: "9px", background: "#f00" }}
                />
                7
              </span>
            </span>
            {t("Shaking intensities")}
          </span>
        </div>
      </div>
    </>
  );
}

function flood(t, data) {
  return (
    <>
      <div className="flood-legend legend" style={{ display: "none" }}>
        <h2>Chance of flooding in 10 years</h2>
        <div className="bar">
          <span style={{ background: "#a6d8e399" }} />
          <span style={{ background: "#43a5e399" }} />
        </div>
        <div className="bar">
          <span>More than 1 in 50</span>
          <span>More than 1 in 10</span>
        </div>
      </div>
    </>
  );
}

function tsunamiTw(t, data) {
  return (
    <>
      <div className="tsunami-tw-legend legend" style={{ display: "none" }}>
        <h2>{t("tsunami_hazard")}</h2>
        <div>{t("wave_height_for_extreme_event")}</div>
        <div className="bar">
          <span
            style={{
              background:
                "linear-gradient(to right, #00ffff00 0%, #0000ff77 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #0000ff77 0%, #6600ffaa 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #6600ffaa 0%, #8800eeff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #8800eeff 0%, #220044ff 100%)",
            }}
          />
        </div>
        <div className="bounds">
          <span>0</span>
          <span>0.03</span>
          <span>0.5</span>
          <span>2.5</span>
          <span>8&nbsp;m</span>
        </div>
      </div>
    </>
  );
}

function tsunami(t, data) {
  return (
    <>
      <div className="tsunami-legend legend" style={{ display: "none" }}>
        <div className="bar" style={{ marginTop: "4px" }}>
          <span style={{ background: "#0dd9" }} />
        </div>
        Tsunami hazard zone
      </div>
    </>
  );
}

function wildfire(t, data) {
  return (
    <>
      <div className="wildfire-legend legend" style={{ display: "none" }}>
        <h2>Wildfire Hazard</h2>
        <div className="bar">
          <span style={{ background: "hsl(60,100%,50%,0.5)" }} />
          <span style={{ background: "hsl(38,100%,50%,0.5)" }} />
          <span style={{ background: "hsl(0,100%,50%,0.5)" }} />
        </div>
        <div className="bar">
          <span>Moderate</span>
          <span>High</span>
          <span>Very high</span>
        </div>
        CAL FIRE, 2007 (No color outside of State Responsibility Areas)
      </div>
    </>
  );
}

function scenariosTw(t, data) {
  return (
    <>
      <div className="scenarios-legend legend" style={{ display: "none" }}>
        <h2>{t("strongest_shaking_expected")}</h2>
        <div className="legend-data">
          {t("risk.tw.possible_mag_from_scenario", {
            mag: data.tw_scenario.mag,
          })}{" "}
          {data.tw_scenario.fault} {t("risk.tw.cwb_intensity")}:
          {data.tw_scenario.scenario_cwbi} from E-DREaM
        </div>

        <div className="bounds">
          <span>{t("moderate")}</span>
          <span>{t("Very strong")}</span>
          <span>{t("Extreme")}</span>
        </div>
        <div className="bar">
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ff00 0%, #b5e8ffff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ffff 0%, #00c4e3ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #00c4e3ff 0%, #91cc9eff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #91cc9eff 0%, #ffff8fff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffff8fff 0%, #ffcc00ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffcc00ff 0%, #ff9900ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff9900ff 0%, #ff0000ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff0000ff 0%, #7f0000ff 100%)",
            }}
          />
        </div>
        <div className="bounds">
          <span>4</span>
          <span>5</span>
          <span>6</span>
          <span>7</span>
        </div>
        <div>{t("risk.tw.cwb_intensity")}</div>
      </div>
    </>
  );
}

function gear(t, data) {
  return (
    <>
      <div className="gear-legend legend" style={{ display: "block" }}>
        <h2>Earthquake Likelihood: M {data.gear}</h2>
        {/* <p>Global Earthquake Activity Rate model</p> */}
        {/* <div className="legend-data">M={data.gear} in your life time</div> */}
        <div className="bar">
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ff00 0%, #b5e8ffff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ffff 0%, #00c4e3ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #00c4e3ff 0%, #91cc9eff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #91cc9eff 0%, #ffff8fff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffff8fff 0%, #ffcc00ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffcc00ff 0%, #ff9900ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff9900ff 0%, #ff0000ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff0000ff 0%, #7f0000ff 100%)",
            }}
          />
        </div>
        <div className="bounds">
          <span>5</span>
          <span>6</span>
          <span>7</span>
          <span>8</span>
          <span>9</span>
        </div>
        <div>Mag. likely in your lifetime within 100 km</div>
      </div>
    </>
  );
}

function faultsQuakes(t, data) {
  return (
    <>
      <div
        className="faults-legend legend faultquakes"
        style={{ display: "none" }}
      >
        <div style={{ marginTop: "4px" }}>
          <span
            style={{
              height: "29px",
              width: "67px",
              borderBottom: "1px solid #f00",
            }}
          />
          <span
            style={{
              height: "27px",
              width: "67px",
              borderBottom: "3px solid #f00",
            }}
          />
          <span
            style={{
              height: "29px",
              width: "67px",
              borderBottom: "1px solid #f00",
              background: "linear-gradient(to top, #f003 0%, #f000 50%)",
            }}
          >
            {t("40_km_depth")}
          </span>
        </div>
        <div className="bar">
          <span>{t("minor_fault")}</span>
          <span>{t("major_fault")}</span>
          <span>{t("megathrust")}</span>
        </div>
      </div>
      <div
        className="quakes-merged-legend legend faultquakes"
        style={{ display: "none" }}
      >
        <h2>{t("earthquakes")}</h2>
        <div style={{ margin: "-4px 0" }}>
          <span
            style={{
              width: "90px",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "center",
            }}
          >
            <span style={{ flexDirection: "column" }}>
              <span className="circle" style={{ background: "#800" }} />1
            </span>
            <span style={{ flexDirection: "column" }}>
              <span className="circle" style={{ background: "#f00" }} />2
            </span>
            <span style={{ flexDirection: "column" }}>
              <span className="circle" style={{ background: "#fa0" }} />7
            </span>
            <span style={{ flexDirection: "column" }}>
              <span className="circle" style={{ background: "#ff0" }} />
              30
            </span>
          </span>
          <span
            style={{
              width: "90px",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "center",
            }}
          >
            <span style={{ flexDirection: "column", alignItems: "center" }}>
              <span
                className="circle"
                style={{ width: "7px", height: "7px" }}
              />
              <span id="quakes-merged-legend-0">5</span>
            </span>
            <span style={{ flexDirection: "column", alignItems: "center" }}>
              <span
                className="circle"
                style={{ width: "11px", height: "11px" }}
              />
              <span id="quakes-merged-legend-1">6</span>
            </span>
            <span style={{ flexDirection: "column", alignItems: "center" }}>
              <span
                className="circle"
                style={{ width: "15px", height: "15px" }}
              />
              <span id="quakes-merged-legend-2">7</span>
            </span>
            <span style={{ flexDirection: "column", alignItems: "center" }}>
              <span
                className="circle"
                style={{ width: "19px", height: "19px" }}
              />
              <span id="quakes-merged-legend-3">8</span>
            </span>
          </span>
        </div>
        <div style={{ margin: "0 13px" }}>
          <span>{t("age_in_days")}</span>
          <span>{t("magnitude")}&nbsp;</span>
        </div>
      </div>
    </>
  );
}

function cwbiShaking(t, data) {
  return (
    <>
      <div className="cwbi-legend legend" style={{ display: "block" }}>
        <h2>{t("strongest_shaking_in_your_life_time")}</h2>
        <div className="legend-data">
          {data.tw_shaking[0]} {data.tw_shaking[1]}
        </div>
        <div className="bounds">
          <span>{t("moderate")}</span>
          <span>{t("Very strong")}</span>
          <span>{t("Extreme")}</span>
        </div>
        <div className="bar">
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ff00 0%, #b5e8ffff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #b5e8ffff 0%, #00c4e3ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #00c4e3ff 0%, #91cc9eff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #91cc9eff 0%, #ffff8fff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffff8fff 0%, #ffcc00ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ffcc00ff 0%, #ff9900ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff9900ff 0%, #ff0000ff 100%)",
            }}
          />
          <span
            style={{
              background:
                "linear-gradient(to right, #ff0000ff 0%, #7f0000ff 100%)",
            }}
          />
        </div>
        <div className="bounds">
          <span>4</span>
          <span>5</span>
          <span>6</span>
          <span>7</span>
        </div>
        <div>
          {t("risk.tw.cwb_intensity")} {t("with 1% per year chance")}
        </div>
      </div>
    </>
  );
}

function MapLegendsAll(t, data) {
  return <div id="legends"></div>;
}
