import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import auth from './lib/auth';


class Logout extends Component {
  componentDidMount(){
    auth.removeToken(); 
  }

  render() {
    return (
      <div>
      <h1 className="loading-text">
        Logging out...
      </h1>
      <Redirect to="/login" />
      </div>
    );
  }
}

export default Logout;