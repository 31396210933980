import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";
import auth from './lib/auth';
import { Page,Icon, Toolbar } from 'react-onsenui';
import { apiUrl, webAppUrl } from './lib/config'; 
import { cleanArticlesHtml } from './lib/helpers';
import ReactGA from 'react-ga';

const blogApi = apiUrl + "/blogpost"

class BlogPost extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: props.match.params.id,
      post: {},
      loading: true
    }
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentDidMount(){
    this.fetchArticles();
  }

  fetchArticles(){
    let token;
    if (!(window.history.length > 2)){
      token = 'visitor';
    } else {
      token = auth.getToken();
    }
    
    this.setState({loading: true});
    fetch(`${blogApi}/${this.state.id}?token=${token}`)
        .then(response => response.json())
        .then(data => { 
          if (data.is_authorized && data.is_authorized === 'false'){
            auth.handleInvalidToken();
          }         
          this.setState({ post: data, loading: false}); 
        }     
      );
  }

  handleShare(){
    const url = `${webAppUrl}${this.props.match.url}`;
    const message = `Check Temblor's article`;
    window.plugins.socialsharing.share(message , null, null, url);
  }

  renderToolbar(){
    return (
      <Toolbar>
    <div className="left" onClick={()=> this.props.history.goBack()}>
     <Icon icon="ion-ios-arrow-left" size={24} />      
    </div>
    <div className="center" style={{fontSize: '14px', width: '100%',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
      {this.state.post.title}
    </div>
    {window.cordova &&
    <div className="right" onClick={() => this.handleShare()}>
        <Icon icon="ion-share"  size={24} />
    </div>
    }
  </Toolbar>
    )
  }

  effectiveHeight(){
    //return window.innerHeight-(48+47+5+44+25)
    return window.innerHeight-45;
  }

  render() {
    if (window.history.length > 2){
      if (!auth.isLoggedIn()) {
        return <Redirect to="/login" />;
      }
    }
    ReactGA.set({ page: this.props.match.url }); // Update the user's current page
    ReactGA.set({ title: this.state.post.title });
    ReactGA.pageview(this.props.match.url); // Record a pageview for the given page

    let content, image, date, title, url;
    if (this.state.post.content) content = cleanArticlesHtml(this.state.post.content)
    if (this.state.post.image) image = cleanArticlesHtml(this.state.post.image['onepress-medium'].source_url)
    if (this.state.post.date) date = new Date(this.state.post.date).toLocaleDateString("en-US");
    if (this.state.post.title) title =  this.state.post.title;
    url = `https://app.temblor.net/articles/${this.state.post.id}`;
    return (
      <Page>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${title}`} - Temblor.net</title>
          <link rel="canonical" href={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={`${title}`} />
          <meta property="og:description" content={`${title}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:site_name" content="Temblor.net" />
          <meta property="og:image" content={`${image}`} />
          <meta property="article:published_time" content={`${date}`} />
          <meta property="article:modified_time" content={`${date}`} />
          <meta property="og:image:secure_url" content={`${image}`} />
          <meta name="twitter:card" content={`${image}`} />
          <meta name="twitter:title" content={`${title}`} />
          <meta name="twitter:description" content={`${title}`} />
          <meta name="twitter:image" content={`${image}`} />
        </Helmet>
        {this.renderToolbar()}
        <div style={{marginTop: '45px'}}>
        <div style={{overflowY: 'scroll', overflowX: 'hidden', height:this.effectiveHeight()}}>
        {this.state.loading === true &&
        <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
          <Icon icon="ion-ios-refresh" size={30} spin={true} />
        </div>
        }
        <div className='center' style={{position: 'relative'}}>
          <img src={`${image}`} alt={this.state.post.title} width="100%" />
          <span style={{position: 'absolute',bottom: '8px',left: '0px',backgroundColor: '#000000',color: '#FFFFFF', fontSize: '18px',padding: '10px',opacity: '0.80', lineHeight: '1.25em'}}>{this.state.post.title}</span>
          </div>
          <div style={{margin: '10px', fontSize: '12px'}}>POSTED ON {date}</div>
          <div className="blogpost" style={{margin: '10px', fontSize: '14px'}} dangerouslySetInnerHTML={{__html: content}}></div> 
          </div>  
          </div>     
          </Page>
    );
  }
}

export default BlogPost;