import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import auth from "./lib/auth";
import { Page, Icon, Toolbar } from "react-onsenui";
import { apiUrl, webAppUrl } from "./lib/config";
import { cleanArticlesHtml } from "./lib/helpers";
import ReactGA from "react-ga";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      post: {},
      loading: true,
    };
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentDidMount() {}

  renderToolbar() {
    return (
      <Toolbar>
        <div className="left" onClick={() => this.props.history.goBack()}>
          <Icon icon="ion-ios-arrow-left" size={24} />
        </div>
        <div
          className="center"
          style={{
            fontSize: "14px",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          Terms of Use
        </div>
        {window.cordova && (
          <div className="right" onClick={() => this.handleShare()}>
            <Icon icon="ion-share" size={24} />
          </div>
        )}
      </Toolbar>
    );
  }

  effectiveHeight() {
    //return window.innerHeight-(48+47+5+44+25)
    return window.innerHeight - 45;
  }

  render() {
    ReactGA.set({ page: this.props.match.url }); // Update the user's current page
    ReactGA.set({ title: this.state.post.title });
    ReactGA.pageview(this.props.match.url); // Record a pageview for the given page

    return (
      <Page>
        {this.renderToolbar()}
        <div style={{ marginTop: "45px" }}>
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: this.effectiveHeight(),
            }}
          >
            <div
              className="blogpost"
              style={{ margin: "10px", fontSize: "14px" }}
            >
              <p>
                <span>
                  PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES
                  (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO
                  NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS
                  THE SERVICES IN ANY MANNER. <br />
                  <br />
                  Temblor Terms of Use
                  <br />
                  <br />
                  Effective date: [&ndash;] January 2019
                  <br />
                  <br />
                  Welcome to Temblor. Please read on to learn the rules and
                  restrictions that govern your use of our website(s), products,
                  services and applications (the &ldquo;Services&rdquo;). If you
                  have any questions, comments, or concerns regarding these
                  terms or the Services, please contact us at
                  info@temblorapp.com, tel 650-464-0285; Temblor, Inc., 119
                  Scenic Drive, Redwood City, CA 94062.
                  <br />
                  <br />
                  These Terms of Use (the &ldquo;Terms&rdquo;) are a binding
                  contract between you and Temblor, Inc. (&ldquo;Temblor,&rdquo;
                  &ldquo;we&rdquo; and &ldquo;us&rdquo;). You must agree to and
                  accept all of the Terms, or you don&rsquo;t have the right to
                  use the Services. Your using the Services in any way means
                  that you agree to all of these Terms, and these Terms will
                  remain in effect while you use the Services. These Terms
                  include the provisions in this document, as well as those in
                  the Privacy Policy and Temblor&rsquo;s info buttons, which
                  includes warranty disclaimers on our behalf and on behalf of
                  our data providers as well as a description of the limitations
                  (e.g., probabilities, data sources, estimations, etc.) of the
                  information we provide.
                  <br />
                  <br />
                  Will these Terms ever change?
                  <br />
                  <br />
                  We are constantly trying to improve our Services, so these
                  Terms may need to change along with the Services. We reserve
                  the right to change the Terms at any time, but if we do, we
                  will bring it to your attention by placing a notice on the
                  www.temblor.net website, by sending you an email, and/or by
                  some other means. <br />
                  <br />
                  If you don&rsquo;t agree with the new Terms, you are free to
                  reject them; unfortunately, that means you will no longer be
                  able to use the Services. If you use the Services in any way
                  after a change to the Terms is effective, that means you agree
                  to all of the changes. <br />
                  <br />
                  Except for changes by us as described here, no other amendment
                  or modification of these Terms will be effective unless in
                  writing and signed by both you and us. <br />
                  <br />
                  What about my privacy?
                  <br />
                  <br />
                  Temblor takes the privacy of its users very seriously. Please
                  review Temblor&rsquo;s current Privacy Policy to learn more.
                  <br />
                  <br />
                  The Children&rsquo;s Online Privacy Protection Act
                  (&ldquo;COPPA&rdquo;) requires that online service providers
                  obtain parental consent before they knowingly collect
                  personally identifiable information online from children who
                  are under 13. We do not knowingly collect or solicit
                  personally identifiable information from children under 13; if
                  you are a child under 13, please do not attempt to register
                  for the Services or send any personal information about
                  yourself to us. If we learn we have collected personal
                  information from a child under 13, we will delete that
                  information as quickly as possible. If you believe that a
                  child under 13 may have provided us personal information,
                  please contact us at info@temblorapp.com.
                  <br />
                  <br />
                  What are the basics of using Temblor&rsquo;s Services?
                  <br />
                  <br />
                  You may be required to sign up for an account, and select a
                  password and user name, or provide a mobile number to receive
                  an access code (&ldquo;Temblor User ID&rdquo;). You promise to
                  provide us with accurate, complete, and updated registration
                  information about yourself. You may not select as your Temblor
                  User ID a name that you don&rsquo;t have the right to use, or
                  another person&rsquo;s name with the intent to impersonate
                  that person. You may not transfer your account to anyone else
                  without our prior written permission.
                  <br />
                  <br />
                  You represent and warrant that you are of legal age to form a
                  binding contract (or if not, you&rsquo;ve received your
                  parent&rsquo;s or guardian&rsquo;s permission to use the
                  Services and gotten your parent or guardian to agree to these
                  Terms on your behalf). If you&rsquo;re agreeing to these Terms
                  on behalf of an organization or entity, you represent and
                  warrant that you are authorized to agree to these Terms on
                  that organization or entity&rsquo;s behalf and bind them to
                  these Terms (in which case, the references to
                  &ldquo;you&rdquo; and &ldquo;your&rdquo; in these Terms,
                  except for in this sentence, refer to that organization or
                  entity).
                  <br />
                  <br />
                  You will only use the Services for your own internal,
                  personal, non-commercial use, and not on behalf of or for the
                  benefit of any third party only in a manner that complies with
                  all laws that apply to you. If you wish to use the Services
                  for a commercial use, you must obtain our separate written
                  permission to do so. If your use of the Services is prohibited
                  by applicable laws, then you aren&rsquo;t authorized to use
                  the Services. We can&rsquo;t and won&rsquo;t be responsible
                  for your using the Services in a way that breaks the law.
                  <br />
                  <br />
                  You will not share your account or password with anyone, and
                  you must protect the security of your account and your
                  password. You&rsquo;re responsible for any activity associated
                  with your account.
                  <br />
                  <br />
                  Your use of the Services is subject to the following
                  additional restrictions:
                  <br />
                  <br />
                  You represent, warrant, and agree that you will not contribute
                  any Content or User Submission (each of those terms is defined
                  below) or otherwise use the Services or interact with the
                  Services in a manner that:
                  <br />
                  <br />
                  Infringes or violates the intellectual property rights or any
                  other rights of anyone else (including Temblor);
                  <br />
                  Violates any law or regulation, including any applicable
                  export control laws;
                  <br />
                  Is harmful, fraudulent, deceptive, threatening, harassing,
                  defamatory, obscene, or otherwise objectionable;
                  <br />
                  Jeopardizes the security of your Temblor account or anyone
                  else&rsquo;s (such as allowing someone else to log in to the
                  Services as you);
                  <br />
                  Attempts, in any manner, to obtain the password, account, or
                  other security information from any other user;
                  <br />
                  Violates the security of any computer network, or cracks any
                  passwords or security encryption codes;
                  <br />
                  Runs Maillist, Listserv, any form of auto-responder or
                  &ldquo;spam&rdquo; on the Services, or any processes that run
                  or are activated while you are not logged into the Services,
                  or that otherwise interfere with the proper working of the
                  Services (including by placing an unreasonable load on the
                  Services&rsquo; infrastructure);
                  <br />
                  &ldquo;Crawls,&rdquo; &ldquo;scrapes,&rdquo; or
                  &ldquo;spiders&rdquo; any page, data, or portion of or
                  relating to the Services or Content (through use of manual or
                  automated means);
                  <br />
                  Copies or stores any significant portion of the Content;
                  <br />
                  Decompiles, reverse engineers, or otherwise attempts to obtain
                  the source code or underlying ideas or information of or
                  relating to the Services.
                  <br />
                  <br />
                  <br />A violation of any of the foregoing is grounds for
                  termination of your right to use or access the Services.{" "}
                  <br />
                  <br />
                  What are my rights in Temblor&rsquo;s Services?
                  <br />
                  <br />
                  The materials displayed or performed or available on or
                  through the Services, including, but not limited to, text,
                  graphics, data, articles, photos, images, illustrations, User
                  Submissions, and so forth (all of the foregoing, the
                  &ldquo;Content&rdquo;) are protected by copyright and/or other
                  intellectual property laws. You promise to abide by all
                  copyright notices, trademark rules, attribution rules,
                  information, and restrictions contained in any Content you
                  access through the Services, and you won&rsquo;t use, copy,
                  reproduce, modify, translate, publish, broadcast, transmit,
                  distribute, perform, upload, display, license, sell or
                  otherwise exploit for any purpose any Content not owned by
                  you, (i) without the prior consent of the owner of that
                  Content or Temblor or (ii) in a way that violates someone
                  else&rsquo;s (including Temblor&rsquo;s) rights. <br />
                  <br />
                  You understand that Temblor owns the Services. You won&rsquo;t
                  modify, publish, transmit, participate in the transfer or sale
                  of, reproduce (except as expressly provided in this Section),
                  create derivative works based on, or otherwise exploit any of
                  the Services, without our explicit permission.
                  <br />
                  <br />
                  The Services may allow you to copy or download certain
                  Content; please remember that just because this functionality
                  exists, doesn&rsquo;t mean that all the restrictions above
                  don&rsquo;t apply &ndash; they do!
                  <br />
                  <br />
                  Do I have to grant any licenses to Temblor or to other users?
                  <br />
                  <br />
                  Anything you post, upload, share, store, or otherwise provide
                  through the Services is your &ldquo;User Submission.&rdquo;
                  Some User Submissions may be viewable by other users. In order
                  to display your User Submissions on the Services, and to allow
                  other users to enjoy them (where applicable), you grant us
                  certain rights in those User Submissions. Please note that all
                  of the following licenses are subject to our Privacy Policy to
                  the extent they relate to User Submissions that are also your
                  personally-identifiable information.
                  <br />
                  <br />
                  For all User Submissions, you hereby grant Temblor a license
                  to translate, modify (for technical purposes, for example
                  making sure your content is viewable on an iPhone as well as a
                  computer) and reproduce and otherwise act with respect to such
                  User Submissions, in each case to enable us to operate the
                  Services, as described in more detail below. This is a license
                  only &ndash; your ownership in User Submissions is not
                  affected.
                  <br />
                  <br />
                  If you store a User Submission in your own personal Temblor
                  account or input a temporary User Submission, in each case in
                  a manner that is not viewable by any other user except you (a
                  &ldquo;Personal User Submission&rdquo;), you grant Temblor the
                  license above, as well as a license to display, perform, and
                  distribute your Personal User Submission for the sole purpose
                  of making that Personal User Submission accessible to you and
                  providing the Services necessary to do so.
                  <br />
                  <br />
                  If you share a User Submission only in a manner that only
                  certain specified users can view (for example, a private
                  message to one or more other users) (a &ldquo;Limited Audience
                  User Submission&rdquo;), then you grant Temblor the licenses
                  above, as well as a license to display, perform, and
                  distribute your Limited Audience User Submission for the sole
                  purpose of making that Limited Audience User Submission
                  accessible to such other specified users, and providing the
                  Services necessary to do so. Also, you grant such other
                  specified users a license to access that Limited Audience User
                  Submission, and to use and exercise all rights in it, as
                  permitted by the functionality of the Services. <br />
                  <br />
                  If you share a User Submission publicly on the Services and/or
                  in a manner that more than just you or certain specified users
                  can view, or if you provide us (in a direct email or
                  otherwise) with any feedback, suggestions, improvements,
                  enhancements, and/or feature requests relating to the Services
                  (each of the foregoing, a &ldquo;Public User
                  Submission&rdquo;), then you grant Temblor the licenses above,
                  as well as a license to display, perform, and distribute your
                  Public User Submission for the purpose of making that Public
                  User Submission accessible to all Temblor users and providing
                  the Services necessary to do so, as well as all other rights
                  necessary to use and exercise all rights in that Public User
                  Submission in connection with the Services and/or otherwise in
                  connection with Temblor&rsquo;s business for any purpose.
                  Also, you grant all other users of the Services a license to
                  access that Public User Submission, and to use and exercise
                  all rights in it, as permitted by the functionality of the
                  Services.
                  <br />
                  <br />
                  You agree that the licenses you grant are royalty-free,
                  perpetual, sublicenseable, irrevocable, and worldwide,
                  provided that when you delete your Temblor account, we will
                  stop displaying your User Submissions (other than Public User
                  Submissions, which may remain fully available) to other users
                  (if applicable), but you understand and agree that it may not
                  be possible to completely delete that content from
                  Temblor&rsquo;s records, and that your User Submissions may
                  remain viewable elsewhere to the extent that they were copied
                  or stored by other users. <br />
                  <br />
                  Finally, you understand and agree that Temblor, in performing
                  the required technical steps to provide the Services to our
                  users (including you), may need to make changes to your User
                  Submissions to conform and adapt those User Submissions to the
                  technical requirements of connection networks, devices,
                  services, or media, and the foregoing licenses include the
                  rights to do so.
                  <br />
                  <br />
                  What if I see something on the Services that infringes my
                  copyright?
                  <br />
                  <br />
                  You may have heard of the Digital Millennium Copyright Act
                  (the &ldquo;DMCA&rdquo;), as it relates to online service
                  providers, like Temblor, being asked to remove material that
                  allegedly violates someone&rsquo;s copyright. &nbsp;We respect
                  others&rsquo; intellectual property rights, and we reserve the
                  right to delete or disable Content alleged to be infringing,
                  and to terminate the accounts of repeat alleged infringers; to
                  review our complete Copyright Dispute Policy and learn how to
                  report potentially infringing content, click &lt;here&gt;.
                  &nbsp;To learn more about the DMCA, click &lt;here&gt;.
                  <span>&nbsp; </span>
                  <br />
                  <br />
                  <br />
                  <br />
                  Who is responsible for what I see and do on the Services?
                  <br />
                  <br />
                  Any information or content publicly posted or privately
                  transmitted through the Services is the sole responsibility of
                  the person from whom such content originated, and you access
                  all such information and content at your own risk, and we
                  aren&rsquo;t liable for any errors or omissions in that
                  information or content or for any damages or loss you might
                  suffer in connection with it. We cannot control and have no
                  duty to take any action regarding how you may interpret and
                  use the Content or what actions you may take as a result of
                  having been exposed to the Content, and you hereby release us
                  from all liability for you having acquired or not acquired
                  Content through the Services. We can&rsquo;t guarantee the
                  identity of any users with whom you interact in using the
                  Services and are not responsible for which users gain access
                  to the Services. <br />
                  <br />
                  You are responsible for all Content you contribute, in any
                  manner, to the Services, and you represent and warrant you
                  have all rights necessary to do so, in the manner in which you
                  contribute it. You will keep all your registration information
                  accurate and current. You are responsible for all your
                  activity in connection with the Services.
                  <br />
                  <br />
                  The Services may contain links or connections to third party
                  websites or services that are not owned or controlled by
                  Temblor. When you access third party websites or use third
                  party services, you accept that there are risks in doing so,
                  and that Temblor is not responsible for such risks. We
                  encourage you to be aware when you leave the Services and to
                  read the terms and conditions and privacy policy of each third
                  party website or service that you visit or utilize. <br />
                  <br />
                  Temblor has no control over, and assumes no responsibility
                  for, the content, accuracy, privacy policies, or practices of
                  or opinions expressed in any third party websites or by any
                  third party that you interact with through the Services. In
                  addition, Temblor will not and cannot monitor, verify, censor
                  or edit the content of any third party site or service. By
                  using the Services, you release and hold us harmless from any
                  and all liability arising from your use of any third party
                  website or service. <br />
                  <br />
                  Your interactions with organizations and/or individuals found
                  on or through the Services, including payment and delivery of
                  goods or services, and any other terms, conditions, warranties
                  or representations associated with such dealings, are solely
                  between you and such organizations and/or individuals. You
                  should make whatever investigation you feel necessary or
                  appropriate before proceeding with any online or offline
                  transaction with any of these third parties. You agree that
                  Temblor shall not be responsible or liable for any loss or
                  damage of any sort incurred as the result of any such
                  dealings. <br />
                  <br />
                  If there is a dispute between participants on this site, or
                  between users and any third party, you agree that Temblor is
                  under no obligation to become involved. In the event that you
                  have a dispute with one or more other users, you release
                  Temblor, its officers, employees, agents, and successors from
                  claims, demands, and damages of every kind or nature, known or
                  unknown, suspected or unsuspected, disclosed or undisclosed,
                  arising out of or in any way related to such disputes and/or
                  our Services. If you are a California resident, you shall and
                  hereby do waive California Civil Code Section 1542, which
                  says: "A general release does not extend to claims which the
                  creditor does not know or suspect to exist in his or her favor
                  at the time of executing the release, which, if known by him
                  or her must have materially affected his or her settlement
                  with the debtor."
                  <br />
                  <br />
                  Will Temblor ever change the Services?
                  <br />
                  <br />
                  We&rsquo;re always trying to improve the Services, so they may
                  change over time. We may suspend or discontinue any part of
                  the Services, or we may introduce new features or impose
                  limits on certain features or restrict access to parts or all
                  of the Services. We&rsquo;ll try to give you notice when we
                  make a material change to the Services that would adversely
                  affect you, but this isn&rsquo;t always practical. Similarly,
                  we reserve the right to remove any Content from the Services
                  at any time, for any reason (including, but not limited to, if
                  someone alleges you contributed that Content in violation of
                  these Terms), in our sole discretion, and without notice.{" "}
                  <br />
                  <br />
                  Do Temblor&rsquo;s Services cost anything?
                  <br />
                </span>
              </p>
              <p>
                <span>
                  The Temblor Services are currently free&nbsp;to the public for
                  a limited number of locations, but we reserve the right to
                  charge for certain or all Services in the
                  future,&nbsp;and&nbsp;to sell results for locations to
                  commercial users.&nbsp;
                </span>
                <span>
                  We will notify you before any Services you are then using
                  begin carrying a fee, and if you wish to continue using such
                  Services, you must pay all applicable fees for such Services.
                  <br />
                  <br />
                  What if I want to stop using Temblor&rsquo;s Services? <br />
                  <br />
                  You&rsquo;re free to do that at any time, by contacting us at
                  info@temblorapp.com; please refer to our Privacy Policy , as
                  well as the licenses above, to understand how we treat
                  information you provide to us after you have stopped using our
                  Services. <br />
                  <br />
                  Temblor is also free to terminate (or suspend access to) your
                  use of the Services or your account, for any reason in our
                  discretion, including your breach of these Terms. Temblor has
                  the sole right to decide whether you are in violation of any
                  of the restrictions set forth in these Terms.
                  <br />
                  <br />
                  Account termination may result in destruction of any Content
                  associated with your account, so keep that in mind before you
                  decide to terminate your account.
                  <br />
                  <br />
                  Provisions that, by their nature, should survive termination
                  of these Terms shall survive termination. By way of example,
                  all of the following will survive termination: any obligation
                  you have to pay us or indemnify us, any limitations on our
                  liability, any terms regarding ownership or intellectual
                  property rights, and terms regarding disputes between us.
                  <br />
                  <br />I use the Temblor App available via the Apple App Store
                  &ndash; should I know anything about that?
                  <br />
                  <br />
                  These Terms apply to your use of all the Services, including
                  the iOS applications available via the Apple, Inc.
                  (&ldquo;Apple&rdquo;) App Store (the
                  &ldquo;Application&rdquo;), but the following additional terms
                  also apply to the Application:
                  <br />
                  <br />
                  Both you and Temblor acknowledge that the Terms are concluded
                  between you and Temblor only, and not with Apple, and that
                  Apple is not responsible for the Application or the Content;
                  <br />
                  The Application is licensed to you on a limited,
                  non-exclusive, non-transferrable, non-sublicensable basis.
                  <span>&nbsp; </span>Unless we agree in writing otherwise, the
                  Application is licensed to yousolely to be used in connection
                  with the Services for your private, personal, non-commercial
                  use, subject to all the terms and conditions of these Terms as
                  they are applicable to the Services;
                  <br />
                  You will only use the Application in connection with an Apple
                  device that you own or control;
                  <br />
                  You acknowledge and agree that Apple has no obligation
                  whatsoever to furnish any maintenance and support services
                  with respect to the Application;
                  <br />
                  In the event of any failure of the Application to conform to
                  any applicable warranty, including those implied by law, you
                  may notify Apple of such failure; upon notification,
                  Apple&rsquo;s sole warranty obligation to you will be to
                  refund to you the purchase price, if any, of the Application;
                  <br />
                  You acknowledge and agree that Temblor, and not Apple, is
                  responsible for addressing any claims you or any third party
                  may have in relation to the Application;
                  <br />
                  You acknowledge and agree that, in the event of any third
                  party claim that the Application or your possession and use of
                  the Application infringes that third party&rsquo;s
                  intellectual property rights, Temblor, and not Apple, will be
                  responsible for the investigation, defense, settlement and
                  discharge of any such infringement claim;
                  <br />
                  You represent and warrant that you are not located in a
                  country subject to a U.S. Government embargo, or that has been
                  designated by the U.S. Government as a &ldquo;terrorist
                  supporting&rdquo; country, and that you are not listed on any
                  U.S. Government list of prohibited or restricted parties;
                  <br />
                  Both you and Temblor acknowledge and agree that, in your use
                  of the Application, you will comply with any applicable third
                  party terms of agreement which may affect or be affected by
                  such use; and
                  <br />
                  Both you and Temblor acknowledge and agree that Apple and
                  Apple&rsquo;s subsidiaries are third party beneficiaries of
                  these Terms, and that upon your acceptance of these Terms,
                  Apple will have the right (and will be deemed to have accepted
                  the right) to enforce these Terms against you as the third
                  party beneficiary hereof.
                  <br />
                  What else do I need to know?
                  <br />
                  <br />
                  Limitations of our Probabilistic Information. As described
                  further in Temblor&rsquo;s info-button text, we are using
                  publicly available data and modeling methods to make an
                  approximate estimate of probabilistic damage based on limited
                  information about a home. <br />
                  <br />
                  Warranty Disclaimer. Neither Temblor nor its licensors or
                  suppliers makes any representations or warranties concerning
                  any content contained in or accessed through the Services, and
                  we will not be responsible or liable for the accuracy,
                  copyright compliance, legality, or decency of material
                  contained in or accessed through the Services. We (and our
                  licensors and suppliers) make no representations or warranties
                  regarding suggestions or recommendations of services or
                  products offered or purchased through the Services. Products
                  and services purchased or offered (whether or not following
                  such recommendations and suggestions) through the Services are
                  provided &ldquo;AS IS&rdquo; and without any warranty of any
                  kind from Temblor or others (unless, with respect to such
                  others only, provided expressly and unambiguously in writing
                  by a designated third party for a specific product). YOUR USE
                  OF THE SERVICES IS AT YOUR OWN RISK.<span>&nbsp; </span>THE
                  SERVICES AND CONTENT ARE PROVIDED BY TEMBLOR (AND ITS
                  LICENSORS AND SUPPLIERS) ON AN &ldquo;AS-IS&rdquo; BASIS,
                  WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                  INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                  NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
                  UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
                  LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
                  ABOVE LIMITATIONS MAY NOT APPLY TO YOU. <br />
                  <br />
                  Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY
                  APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL
                  THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT
                  LIABILITY, OR OTHERWISE) SHALL TEMBLOR (OR ITS LICENSORS OR
                  SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY
                  INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
                  KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL,
                  WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR
                  MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF
                  THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO
                  TEMBLOR IN CONNECTION WITH THE SERVICES IN THE TWELVE (12)
                  MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (III) ANY
                  MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW
                  THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE
                  LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU. <br />
                  <br />
                  Indemnity. To the fullest extent allowed by applicable law,
                  you agree to indemnify and hold Temblor, its affiliates,
                  officers, agents, employees, and partners harmless from and
                  against any and all claims, liabilities, damages (actual and
                  consequential), losses and expenses (including
                  attorneys&rsquo; fees) arising from or in any way related to
                  any third party claims relating to (a) your use of the
                  Services (including any actions taken by a third party using
                  your account), and (b) your violation of these Terms. In the
                  event of such a claim, suit, or action (&ldquo;Claim&rdquo;),
                  we will attempt to provide notice of the Claim to the contact
                  information we have for your account (provided that failure to
                  deliver such notice shall not eliminate or reduce your
                  indemnification obligations hereunder).
                  <br />
                  <br />
                  Assignment. You may not assign, delegate or transfer these
                  Terms or your rights or obligations hereunder, or your
                  Services account, in any way (by operation of law or
                  otherwise) without Temblor&rsquo;s prior written consent. We
                  may transfer, assign, or delegate these Terms and our rights
                  and obligations without consent.
                  <br />
                  <br />
                  Choice of Law; Arbitration. These Terms are governed by and
                  will be construed under the laws of the State of California,
                  without regard to the conflicts of laws provisions thereof.
                  Any dispute arising from or relating to the subject matter of
                  these Terms shall be finally settled in San Francisco County,
                  California, in English, in accordance with the Streamlined
                  Arbitration Rules and Procedures of Judicial Arbitration and
                  Mediation Services, Inc. (&ldquo;JAMS&rdquo;) then in effect,
                  by one commercial arbitrator with substantial experience in
                  resolving intellectual property and commercial contract
                  disputes, who shall be selected from the appropriate list of
                  JAMS arbitrators in accordance with such Rules. Judgment upon
                  the award rendered by such arbitrator may be entered in any
                  court of competent jurisdiction. Notwithstanding the foregoing
                  obligation to arbitrate disputes, each party shall have the
                  right to pursue injunctive or other equitable relief at any
                  time, from any court of competent jurisdiction. For all
                  purposes of this Agreement, the parties consent to exclusive
                  jurisdiction and venue in the state or federal courts located
                  in, respectively, San Francisco County, California, or the
                  Northern District of California.
                  <br />
                  <br />
                  Miscellaneous. You will be responsible for paying,
                  withholding, filing, and reporting all taxes, duties, and
                  other governmental assessments associated with your activity
                  in connection with the Services, provided that Temblor may, in
                  its sole discretion, do any of the foregoing on your behalf or
                  for itself as it sees fit. The failure of either you or us to
                  exercise, in any way, any right herein shall not be deemed a
                  waiver of any further rights hereunder. If any provision of
                  these Terms is found to be unenforceable or invalid, that
                  provision will be limited or eliminated, to the minimum extent
                  necessary, so that these Terms shall otherwise remain in full
                  force and effect and enforceable. You and Temblor agree that
                  these Terms are the complete and exclusive statement of the
                  mutual understanding between you and Temblor, and that it
                  supersedes and cancels all previous written and oral
                  agreements, communications and other understandings relating
                  to the subject matter of these Terms. You hereby acknowledge
                  and agree that you are not an employee, agent, partner, or
                  joint venture of Temblor, and you do not have any authority of
                  any kind to bind Temblor in any respect whatsoever. Except as
                  expressly set forth in the section above regarding the Apple
                  Application, you and Temblor agree there are no third party
                  beneficiaries intended under these Terms.
                </span>
              </p>{" "}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default Faq;
