import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Page, Row, Col, Button, ProgressCircular } from "react-onsenui";
import Map from "./Map";
import DummyAddressBox from "./DummyAddressBox";
import auth from "./lib/auth";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import NavBar from "./NavBar";
/* import { apiUrl } from "./lib/config"; */

const apiUrl = "https://api-eq.temblor.net";

class Earthquakes extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.fetchEarthquake = this.fetchEarthquake.bind(this);
    this.state = { loading: true, data: {} };
  }

  fetchEarthquake(id) {
    this.setState({ loading: true });
    /* const id = this.props.match.params.id; */
    const token = auth.getToken();
    const url = `${apiUrl}/earthquakes/${id}?token=${token}`;
    fetch(url)
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.is_authorized && data.is_authorized === "false") {
          auth.handleInvalidToken();
        }
        /* console.log(data.geometry.coordinates); */
        this.setState({
          loading: false,
          data: data,
          lon: data.geometry.coordinates[0],
          lat: data.geometry.coordinates[1],
        });
      });
  }

  componentDidMount() {
    this.fetchEarthquake(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.fetchEarthquake(props.match.params.id);
    }
  }

  renderToolbar() {
    return (
      <div>
        <NavBar />
      </div>
    );
  }

  mapHeight() {
    /* return window.innerHeight - (48 + 48 + 249); */
    return window.innerHeight - (44 + 44);
  }

  render() {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/login" />;
    }

    if (this.state.loading === false) {
      return (
        <Page renderToolbar={this.renderToolbar}>
          <section>
            <DummyAddressBox
              placeholder="Earthquake"
              handleShare={this.handleShare}
              goLink="/risk"
            />
            <div
              style={{
                position: "relative",
                height: "47px",
                backgroundColor: "#FFFFFF",
                margin: "45px 0 0 0",
              }}
            >
              <div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: this.mapHeight(),
                  }}
                >
                  <Map
                    screen="earthquakes"
                    eqData={this.state.data}
                    height={this.mapHeight()}
                    lat={this.state.lat}
                    lon={this.state.lon}
                    hasMarker={false}
                    zoom={10}
                    dataLoading={this.props.loading}
                    redirect={this.props.redirect}
                  />
                </div>
              </div>
            </div>
          </section>
        </Page>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <ProgressCircular size="30px" indeterminate />
        </div>
      );
    }
  }
}

export default withTranslation("translations")(Earthquakes);
