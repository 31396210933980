import React from 'react';
import { Redirect } from 'react-router-dom';
  
  const auth = {};

  auth.setToken = function(token){
    localStorage.setItem('token', JSON.stringify(token));
  }

  auth.getToken = function(){
    return JSON.parse(localStorage.getItem('token')) || false;
  }

  auth.removeToken = function(){
    localStorage.removeItem('token');
  }

  auth.isLoggedIn = function(){
    // console.log(this.getToken());
    // console.log(this.getToken() !== false);
    return this.getToken() !== false;
  }

  auth.handleInvalidToken = function(){
    auth.removeToken();
  }

  auth.isApiResponseAuthed = function (data){
    if (data.is_authorized && data.is_authorized === 'false'){
      auth.handleInvalidToken();
    }        
  }

export default auth;