import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Toolbar } from 'react-onsenui'; 

function DummyAddressBox(props){
    const shareHtml = <div className="right" onClick={() => {console.log('DummyAddressBox -handleShare');props.handleShare()}}>
      <Icon icon="ion-share" size={24} />
    </div>;
    const share =( window.plugins ? shareHtml : '');

    return (
      <Link to={props.goLink}>
      <Toolbar> 
        <div className="left">
        <Icon icon="ion-ios-search" size={18} />
      </div>            
      <div className="center" style={{fontSize: '14px', fontWeight: 'normal', width: '100%',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>      
        {props.placeholder}
        </div>   
       {share}
    </Toolbar>
    </Link>
      )
}

export default DummyAddressBox;