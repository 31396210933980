import React from "react";
import i18n from "i18next";

export const setLanguage = () => {
  const lang = navigator.language;
  const langInStorage = localStorage.getItem("language");
  if (langInStorage !== null) {
    i18n.changeLanguage(langInStorage);
  } else {
    if (lang.includes("zh")) {
      i18n.changeLanguage("cn");
      localStorage.setItem("language", "cn");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
  }
};
