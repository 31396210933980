export const encodeLocation = function (location) {
  //location = {lat, lon, address, slug}
  //location [lat,lon]
  return window.btoa(
    unescape(encodeURIComponent(JSON.stringify([location.lat, location.lon])))
  );
};
export const decodeLocation = function (encoded) {
  const loc = JSON.parse(decodeURIComponent(escape(window.atob(encoded))));
  return { lat: loc[0], lon: loc[1] };
};
export const unslugify = function (str) {
  return str.replace(/-/g, " ");
};
export const formatMoney = function (x, style = "currency") {
  return x.toLocaleString("en-US");
};
export const round1000 = function (x) {
  return (x / 1000).toFixed(0) * 1000;
};
export const timeDifference = function (time) {
  var previous = new Date(time * 1000);
  const current = new Date();
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const fromDates = (str) => {
  const dates = {
    "24hours": {
      text: "24 hours",
      ms: 86400000,
    },
    "7days": {
      text: "7 days",
      ms: 604800000,
    },
    "30days": {
      text: "30 days",
      ms: 2592000000,
    },
  };

  let date = dates[str];
  const now = Date.now();
  date["start_ms"] = new Date(now - date.ms).toISOString().slice(0, 24);
  date["end_ms"] = new Date(now + 86400000).toISOString().slice(0, 24);
  return date;
};

export const eqStartTime = (str) => {
  const dates = {
    "24hours": {
      text: "24 hours",
      ms: 0,
    },
    "7days": {
      text: "7 days",
      ms: 604800000,
    },
    "30days": {
      text: "30 days",
      ms: 2592000000,
    },
  };

  return dates.ms;
};

export const capitalLetter = function (str) {
  str = str.split(" ");
  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }
  return str.join(" ");
};

export const stringToKey = function (str) {
  // input "Sanyi-ha fault"
  // output "sanyihafault"
  let newString = str.replace(/[^A-Z0-9]+/gi, "");
  newString = newString.toLowerCase();
  return newString;
};

export const closestNumber = function (num, arr) {
  let diffIndex = 0;
  let diff = 1000000000;
  arr.forEach((element, index) => {
    const abs = Math.abs(element - num);
    if (abs < diff) {
      diff = abs;
      diffIndex = index;
    }
  });
  return arr[diffIndex];
};

export const cleanArticlesHtml = function (html) {
  html = html.replace(/(http:\/\/52.24.98.51)/gm, "https://static.temblor.net");
  html = html.replace(/style="width:(.*?)"/gm, "");
  html = html.replace(/(width=")\d+("\W+height=")\d+/gm, "");
  return html;
};
