export const toRomanNumeral = function (number) {
  const data = {
    0: "I",
    1: "I",
    2: "II",
    3: "III",
    4: "IV",
    5: "V",
    6: "VI",
    7: "VII",
  };

  return data[number.toFixed(0)];
};

export const displayCwbi = function (cwbi, roman = false) {
  let sign = "";
  if (
    (Math.floor(cwbi) === 5 || Math.floor(cwbi) === 6) &&
    parseFloat((Math.abs(cwbi) - Math.floor(cwbi)).toFixed(1)) > 0.5
  ) {
    sign = "+";
  }
  if (roman === false) return Math.floor(cwbi) + sign;
  return toRomanNumeral(Math.floor(cwbi)) + sign;
};
