import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Search from './Search';
import auth from './lib/auth';

class RiskSearch extends Component {
  
  render() {
    // if (!auth.isLoggedIn()) {
    //   return <Redirect to="/login" />;
    // }
    
    return (
      <Search searchType='risk' />

    );
  }
}

export default RiskSearch;
