import React, { Component } from "react";
import NavBar from "./NavBar";
import { Link, Redirect } from "react-router-dom";
import {
  List,
  ListHeader,
  ListItem,
  SearchInput,
  Page,
  Row,
  Col,
  Icon,
  ProgressCircular,
} from "react-onsenui";
import slugify from "slugify";
import { getAllAddresses } from "./lib/user";
import auth from "./lib/auth";
import { encodeLocation } from "./lib/helpers";
import { withTranslation } from "react-i18next";

const mapboxAccessToken =
  "pk.eyJ1IjoidGVtYmxvciIsImEiOiI5MjdjOTMxNTJiZmFlZmU1ZGI0ZjAwNTZlNjEyOWEwNyJ9.a1_DS6D2ipZAP1AS2OyAHQ";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_results: [],
      findMyLocationResults: [],
      showFindMyLocation: true,
      showCancel: false,
      current_location: {},
      redirect_url: "",
    };
    this.getLatLonFromAddress = this.getLatLonFromAddress.bind(this);
    this.getAddressFromLatlon = this.getAddressFromLatlon.bind(this);
    this.getCurrentLocation = this.getCurrentLocation.bind(this);
    this.renderSearchedAddressRow = this.renderSearchedAddressRow.bind(this);
    this.redirectMyLocation = this.redirectMyLocation.bind(this);
  }

  componentDidMount() {
    this.setState({ saved_addresses: getAllAddresses() });
  }

  renderToolbar() {
    return (
      <div>
        <NavBar />
      </div>
    );
  }

  redirectMyLocation(lat, lon) {
    const id = encodeLocation({
      lat: lat,
      lon: lon,
    });
    const url = `/risk/your-location/${id}`;
    this.setState({ redirect_url: url });
  }

  getCurrentLocation() {
    this.setState({ showFindMyLocation: false });
    var options = {
      enableHighAccuracy: true,
      timeout: 20 * 1000,
      maximumAge: 10,
    };
    const that = this;
    function error(err) {
      var errorMessages = {
        1: "Permission denied",
        2: "Position unavailable",
        3: "Request timeout",
      };
      alert(
        "Oops: " +
          errorMessages[err.code] +
          ". We can't detect your current location. Please make sure you give permission to Temblor to find your location."
      );
      that.setState({ showFindMyLocation: true });
    }
    error.bind(this);
    navigator.geolocation.getCurrentPosition(
      (position) =>
        // TODO: Instead go directly to location on risk
        /* this.getAddressFromLatlon(
         *     position.coords.latitude,
         *     position.coords.longitude
         * ), */
        /* () => {
		 * const id = encodeLocation({
		 *   lat: position.coords.latitude,
		 *   lon: position.coords.longitude,
		 * });
		 * const url = `/risk/your-location/${id}`;
		 * window.location.href = url;
	}, */
        this.redirectMyLocation(
          position.coords.latitude,
          position.coords.longitude
        ),
      error,
      options
    );
  }

  getAddressFromLatlon(lat, lon) {
    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        lon +
        "," +
        lat +
        ".json?access_token=" +
        mapboxAccessToken,
      true
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        var data = JSON.parse(xhr.responseText);
        if (data.features[0]) {
          let results = [];
          if (data.features) {
            results = data.features.map((item) => {
              const isAddress = item.place_type.find((i) => i === "address");
              return {
                lat: item.geometry.coordinates[1],
                lon: item.geometry.coordinates[0],
                address: item.place_name,
                is_address: isAddress,
              };
            });
            this.setState({
              findMyLocationResults: results,
              showFindMyLocation: true,
            });
          }
        } else {
          alert("No address");
        }
      }
    }.bind(this);
    xhr.send();
  }

  getLatLonFromAddress(e) {
    let address = e.target.value;
    fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        encodeURIComponent(address) +
        ".json?&access_token=" +
        mapboxAccessToken
    )
      .then((response) => response.json())
      .then((data) => {
        let results = [];
        if (data.features) {
          results = data.features.map((item) => {
            const isAddress = item.place_type.find((i) => i === "address");
            return {
              lat: item.geometry.coordinates[1],
              lon: item.geometry.coordinates[0],
              address: item.place_name,
              is_address: isAddress,
            };
          });
        }
        this.setState({ search_results: results });
      });
  }

  renderSearchedAddressRow(row) {
    const linkTo =
      this.props.searchType === "risk"
        ? `/risk/${slugify(row.address)}/${encodeLocation({
            lat: row.lat,
            lon: row.lon,
          })}`
        : `/earthquakes/${row.lat},${row.lon}`;
    return (
      <ListItem key={row.lat + "," + row.lon}>
        <Link to={linkTo}>{row.address}</Link>
      </ListItem>
    );
  }

  render() {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirect_url !== "") {
      let url = this.state.redirect_url;
      this.setState({ redirect_url: "" });
      return <Redirect to={url} />;
    }
    let findLoc;
    if (this.state.showFindMyLocation) {
      findLoc = (
        <ListItem
          style={{ fontWeight: "strong" }}
          onClick={this.getCurrentLocation}
        >
          <div className="left">
            <Icon icon="ion-ios-location" size={30} />
          </div>{" "}
          <div className="center">
            {this.props.t("Find my location")}{" "}
            {this.state.current_location.address}
          </div>
        </ListItem>
      );
    } else {
      findLoc = (
        <ListItem style={{ color: "#CCCCCC" }}>
          <div className="left">
            <ProgressCircular indeterminate />
          </div>{" "}
          <div className="center">{this.props.t("Find my location")}</div>
        </ListItem>
      );
    }

    let previousLocations;
    if (
      this.props.searchType === "risk" &&
      this.state.saved_addresses !== null
    ) {
      previousLocations = (
        <div>
          <ListHeader>Previous Locations</ListHeader>
          <div className="searchlist">
            <List
              dataSource={this.state.saved_addresses}
              renderRow={(row) => (
                <ListItem key={row.id}>
                  <Link to={`/risk/${row.location.slug}/${row.id}`}>
                    {row.location.address}{" "}
                    <div style={{ fontSize: "10px", color: "#a8a8a8" }}>
                      LAT: {row.location.lat.toFixed(2)} LON:{" "}
                      {row.location.lon.toFixed(2)}
                    </div>
                  </Link>
                </ListItem>
              )}
            />
          </div>
        </div>
      );
    }
    return (
      <Page renderToolbar={this.renderToolbar}>
        <section style={{ textAlign: "center" }}>
          <Row style={{ padding: "10px", backgroundColor: "#FFFFFF" }}>
            <Col>
              <SearchInput
                placeholder={this.props.t("Search Address")}
                style={{ width: "100%" }}
                onChange={this.getLatLonFromAddress}
              />
            </Col>
          </Row>
          <List
            dataSource={this.state.search_results}
            renderRow={this.renderSearchedAddressRow}
          />
          <List>{findLoc}</List>
          <List
            dataSource={this.state.findMyLocationResults}
            renderRow={this.renderSearchedAddressRow}
          />

          {previousLocations}
        </section>
      </Page>
    );
  }
}

export default withTranslation("translations")(Search);
