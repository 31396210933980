import { apiUrl } from './config';
import auth from './auth';

export const setBuildingDetail = function(id, data){
  addDataToAddress(id, 'building_detail', data);
}

export const initAddress = (id) => {
  let addresses = getAllAddresses();
  if (addresses === null) 
    localStorage.setItem('addresses', '[]'); 
    addresses = getAllAddresses();

  if (addresses.findIndex((i) => i.id === id) === -1) addresses.unshift({id});
  localStorage.setItem('addresses', JSON.stringify(addresses));
} 

export const getAllAddresses = function(){
  return JSON.parse(localStorage.getItem('addresses'));
}

export const setAddressLocation = function (id, data){
  // data: {lat, lon, slug, address}
  addDataToAddress(id, 'location', data);
}

export const getBuildingDetail = function(id){
  return getDataFromAddress(id, 'building_detail');
}

export const getDataFromAddress = (id, key=false) => {
  const addresses = getAllAddresses();
  const index = addresses.findIndex((i) => i.id === id);
  if (!addresses[index].hasOwnProperty(key)) return false;
  return (key ? addresses[index][key] : addresses[index]);
}

export const addDataToAddress = (id, key, data) => {
  // address with a key must exists
  let addresses = getAllAddresses();
  const index = addresses.findIndex((i) => i.id === id);
  addresses[index][key] = data;
  localStorage.setItem('addresses', JSON.stringify(addresses));
  //postAddressToServer(id);
}

export const postAddressToServer = (id) => {
  let data = getDataFromAddress(id);
  if (data){
    const token = auth.getToken();
    data['token'] = token;
    fetch(apiUrl + '/user/save_address', {
      body: JSON.stringify(data), // must match 'Content-Type' header
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, same-origin, *omit
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json'
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // *client, no-referrer
    });
  }
}

export const saveAddress = (data) => {
    if (data){
    const token = auth.getToken();
    data['token'] = token;
    fetch(apiUrl + '/user/addresses', {
      body: JSON.stringify(data), // must match 'Content-Type' header
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, same-origin, *omit
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json'
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // *client, no-referrer
    });
  }
}

// const deleteAddress = (id, key) => {
//   let addresses = getAllAddresses();
//   const index = addresses.findIndex((i) => i.id === id);
//   delete addresses[index];
//   localStorage.setItem('addresses', JSON.stringify(addresses));
// }