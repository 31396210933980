import React, { Component } from 'react';
import { Modal, List, ListItem, Icon } from 'react-onsenui';
import { getBuildingDetail, setBuildingDetail } from './lib/user';
import { formatMoney, closestNumber } from './lib/helpers';
import { apiUrl } from './lib/config';
import auth from './lib/auth';

const defaultBuildingDetails = {
  "home-type": "singlewood",
  insurance_deductible: "0",
  retrofit: 'no',
  rebuildcostpersqft: "300",
  sqrft: "2000",
  yearbuilt: "1950-1975",
  years: "30",
  "replacement-cost": "600000"
}

const costpersqftArray = [100, 125,150, 175, 200, 250, 275, 300, 325, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500];
const sqrftArray = [1000, 1250, 1500, 1600, 1800, 2000, 2250, 2500, 2750, 3000, 3500, 4000, 5000, 5500, 6000, 6500, 7000, 7500];

class BuildingDetailForm extends Component {
  constructor(props){
    console.log('constructor');
    super(props);
    
    let building_details_local = getBuildingDetail(this.props.id);
    const isOpen = (building_details_local === false ? true : false);
    const showCloseButton = (building_details_local === false ? false : true);
    this.state = {building_details: defaultBuildingDetails, isOpen, showCloseButton};
    // If there is no saved building details check for zillow then use default one
    // building_details = (building_details === false ? defaultBuildingDetails : building_details);
    if (building_details_local === false){
      this.fetchDefaults();
    } else {
      this.state.building_details = building_details_local;
    }

    const insurance_deductible = (building_details_local === false || building_details_local.hasOwnProperty('insurance_deductible') === false ? 0 : building_details_local['insurance_deductible']);
    const retrofit = (building_details_local === false || building_details_local.hasOwnProperty('retrofit') === false ? 'no' : building_details_local['retrofit']);
    this.props.handleInsuranceRetrofitState(insurance_deductible, retrofit);

    // this.state = state;
    //this.state = {isOpen: false, showCloseButton: false}
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderCloseButton = this.renderCloseButton.bind(this);
  }

  componentDidMount(){
    console.log('componentDidMount()');
    // let building_details_local = getBuildingDetail(this.props.id);
    // const isOpen = (building_details_local === false ? true : false);
    // const showCloseButton = (building_details_local === false ? false : true);
    // // If there is no saved building details check for zillow then use default one
    // // building_details = (building_details === false ? defaultBuildingDetails : building_details);
    // if (building_details_local === false){
    //   this.fetchDefaults();
    // } else {
    //   this.setState({building_details: building_details_local})
    // }
    //
    // const insurance_deductible = (building_details_local === false || building_details_local.hasOwnProperty('insurance_deductible') === false ? 0 : building_details_local['insurance_deductible']);
    // const retrofit = (building_details === false || building_details.hasOwnProperty('retrofit') === false ? 'no' : building_details['retrofit']);
    // this.props.handleInsuranceRetrofitState(insurance_deductible, retrofit);
  }

  componentDidUpdate(prevProps){
    // console.log("BuildingDetailForm componentDidUpdate");
    // console.log(this.state.isOpen);
    // this.setState({isOpen: false})
  }

  fetchDefaults(){
    const token = auth.getToken();
    const url = `${apiUrl}/buildingdefaults/${this.props.address}?token=${token}` 
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
          if (data['result'] !== 'empty'){
          data['result']['rebuildcostpersqft'] = closestNumber(data['result']['rebuildcostpersqft'], costpersqftArray);
          data['result']['sqrft'] = closestNumber(data['result']['sqrft'], sqrftArray);
          this.setState({building_details: data['result']}, () => {
            console.log('fetchDefaults() this.state.building_details');
            console.log(this.state.building_details);
          }
          
          );   
        } else {
          this.setState({building_details: defaultBuildingDetails});
        }
      }) 
  }

  handleChange(event) {
    console.log(event.target.id);
    const id = event.target.id;
    let building_details = this.state.building_details;
    building_details[id] = event.target.value;
    this.setState({building_details}, () => {
      if (id == 'rebuildcostpersqft' || id == 'sqrft'){
        building_details['replacement-cost'] = building_details['rebuildcostpersqft'] * building_details['sqrft'];
        this.setState({building_details});
      }
    });
  }

  handleSubmit(event){
    event.preventDefault();
    //console.log(this.refs);
    let formData = {};
    for (let name in this.refs) {
        formData[name] = this.refs[name].value
    }
    setBuildingDetail(this.props.id, formData);
    if (formData['replacement-cost'] === '') formData['replacement-cost'] = formData['rebuildcostpersqft']*formData['sqrft'];
    this.setState({building_details: formData});
    delete formData['rebuildcostpersqft'];
    console.log(formData);
    this.props.handleInsuranceRetrofitState(formData['insurance_deductible'], formData['retrofit']);
    this.props.updateRiskData(formData);
    this.setState({showCloseButton: false, isOpen: false}, () => console.log(this.state));
  }  
  
  renderCloseButton(){
    if (this.state.showCloseButton)
    return (
      <div onClick={() => this.setState({isOpen: false})} style={{position: 'absolute', top: '10px', right: '10px', color: 'black'}}>
      <Icon icon='ion-close' size={30} />
      </div>
    )     
  }

  homeType(type){
    const types = {'singlewood': 'Single home (wood)', 'multiwood': 'Condo or Apartment (wood)'}
    return types[type];
  }

  render () {
      return (
      <span>
        <div style={{fontSize: '12px', color: '#969292', textAlign: 'center'}} onClick={() => this.setState({isOpen: true})}>
        <span><i>{this.homeType(this.state.building_details['home-type'])} / {this.state.building_details['yearbuilt']}  </i></span>
        <span className="link" style={{marginTop: '4px', marginLeft: '10px', fontWeight: 'bold'}}>Edit building info</span>
        </div>
      <Modal
        isOpen={this.state.isOpen}
        style={{backgroundColor: 'rgba(255, 255, 255, 1)'}}
        animation='lift'
        >
        <section style={{margin: '16px'}}> 
          {this.renderCloseButton()}
          <form onSubmit={this.handleSubmit} >
          <List>
          <ListItem> 
          <label>Year built</label>
          {/* <select  id="yearbuilt" ref="yearbuilt" defaultValue={this.state.building_details['yearbuilt']}> */}
      <select  id="yearbuilt" ref="yearbuilt" value={this.state.building_details['yearbuilt']} onChange={this.handleChange}>
        <option value="before-1950">Before 1950</option>
        <option value="1950-1975">Between 1950 and 1975</option>
        <option value="after-1975">After 1975</option>
      </select>
      </ListItem> 
            <ListItem> 
            <label>Building Type</label>      
          <select  id="home-type" ref="home-type" value={this.state.building_details['home-type']} onChange={this.handleChange}>
            <option value="singlewood">Single family home (wood)</option>
            <option value="multiwood">Condo or Apartment (wood)</option>
          </select>
          </ListItem>  
          <ListItem>  
          <label>Building Size</label>
          <select  onChange={this.handleRebuildingCost} id="sqrft" ref="sqrft" value={this.state.building_details['sqrft']} onChange={this.handleChange}>
            <option value="1000">1000</option>
            <option value="1250">1250</option>
            <option value="1500">1500</option>
            <option value="1600">1600</option>
            <option value="1800">1800</option>
            <option value="2000">2000</option>
            <option value="2250">2250</option>
            <option value="2500">2500</option>
            <option value="2750">2750</option>
            <option value="3000">3000</option>
            <option value="3500">3500</option>
            <option value="4000">4000</option>
            <option value="5000">5000</option>
            <option value="5500">5500</option>
            <option value="6000">6000</option>
            <option value="6500">6500</option>
            <option value="7000">7000</option>
            <option value="7500">7500</option>
        </select> sq ft
        </ListItem> 
        <ListItem> 
            <label>Number of Stories</label>      
          <select  id="number_of_stories" ref="number_of_stories" value={this.state.building_details['number_of_stories']} onChange={this.handleChange}>
            <option value="1">1</option>
            <option value="2-3">2 - 3</option>
            <option value="4-7">4 - 7</option>
            <option value="7+">more than 7</option>
          </select>
          </ListItem>  
        <ListItem>
        <label>Rebuilding Cost/sqrft</label>
        $<select onChange={this.handleRebuildingCost} id="rebuildcostpersqft" ref="rebuildcostpersqft" value={this.state.building_details['rebuildcostpersqft']} onChange={this.handleChange}>
          <option value="100">100</option>
          <option value="125">125</option>
          <option value="150">150</option>
          <option value="175">175</option>
          <option value="200">200</option>
          <option value="225">225</option>
          <option value="250">250</option>
          <option value="275">275</option>
          <option value="300">300</option>
          <option value="325">325</option>
          <option value="350">350</option>
          <option value="400">400</option>
          <option value="450">450</option>
          <option value="500">500</option>
          <option value="600">600</option>
          <option value="700">700</option>
          <option value="800">800</option>
          <option value="900">900</option>
          <option value="1000">1000</option>
          <option value="1250">1250</option>
          <option value="1500">1500</option>
      </select>
      </ListItem> 
      <ListItem>
      <label>Rebuilding Cost</label>
      $<input type="text" id="replacement-cost" ref="replacement-cost"  value={this.state.building_details['replacement-cost']} onChange={this.handleChange} />
      <span>You can enter your value</span>
      </ListItem>  
          {/* <ListItem>  
          <label>Estimate risk for</label>
        <select  id="years" ref="years" defaultValue={this.state.building_details['years']}>
            <option value="30">30</option>
            <option value="15">15</option>
        </select> years
        </ListItem>   */}
      <ListItem>  
        <label>Earthquake Insurance</label>
        <select  id="insurance_deductible" ref="insurance_deductible" value={this.state.building_details['insurance_deductible']} onChange={this.handleChange}>
          <option value="0">No coverage</option>
          <option value="0.25">25% deductible</option>
          <option value="0.20">20% deductible</option>
          <option value="0.15">15% deductible</option>
          <option value="0.10">10% deductible</option>
          <option value="0.05">5% deductible</option>
    </select>
        </ListItem> 
        <ListItem>  
        <label>Retrofit</label>
        <select  id="retrofit" ref="retrofit" value={this.state.building_details['retrofit']} onChange={this.handleChange}>
          <option value="no">No retrofit</option>
          <option value="yes">Bolted and braced</option>
    </select>
        </ListItem>   
      </List>
      <button style={{marginTop: '10px'}} className="btn-block">SHOW MY RISK</button>
      </form>                           
        </section>
      </Modal>
      </span>
  )
};
}


export default BuildingDetailForm;