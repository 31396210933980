import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import auth from "./lib/auth";
import { Page, Icon, Toolbar } from "react-onsenui";
import { apiUrl, webAppUrl } from "./lib/config";
import { cleanArticlesHtml } from "./lib/helpers";
import ReactGA from "react-ga";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      post: {},
      loading: true,
    };
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentDidMount() {}

  renderToolbar() {
    return (
      <Toolbar>
        <div className="left" onClick={() => this.props.history.goBack()}>
          <Icon icon="ion-ios-arrow-left" size={24} />
        </div>
        <div
          className="center"
          style={{
            fontSize: "14px",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          FAQ
        </div>
        {window.cordova && (
          <div className="right" onClick={() => this.handleShare()}>
            <Icon icon="ion-share" size={24} />
          </div>
        )}
      </Toolbar>
    );
  }

  effectiveHeight() {
    //return window.innerHeight-(48+47+5+44+25)
    return window.innerHeight - 45;
  }

  render() {
    ReactGA.set({ page: this.props.match.url }); // Update the user's current page
    ReactGA.set({ title: this.state.post.title });
    ReactGA.pageview(this.props.match.url); // Record a pageview for the given page

    return (
      <Page>
        {this.renderToolbar()}
        <div style={{ marginTop: "45px" }}>
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: this.effectiveHeight(),
            }}
          >
            <div
              className="blogpost"
              style={{ margin: "10px", fontSize: "14px" }}
            >
              <p>
                <strong>
                  What is earthquake magnitude? How is magnitude different from
                  shaking intensity?
                </strong>
              </p>
              <p>
                An earthquake’s magnitude (M) is a measure of its size. An
                earthquake’s intensity is a measure of how strongly it shakes
                the ground at a given location and depends on many factors. An
                earthquake has only one magnitude, but its intensity depends on
                the location at which it is measured.
              </p>

              <p>
                <strong>
                  Why do I see both a map of shaking intensity and a map of
                  earthquake magnitude?{" "}
                </strong>
              </p>
              <p>Simply put: magnitude matters, but shaking matters more.</p>
              <p>
                A magnitude-6.0 earthquake can destroy one building and barely
                shake another across town. Factors such as the type of rock on
                which the building sits, the direction in which the earthquake
                propagated, and distance from the epicenter all affect how
                strongly a location shakes in an earthquake.
              </p>

              <p>
                <strong>
                  How does Temblor estimate the “magnitude likely in your
                  lifetime”?
                </strong>
              </p>
              <p>
                The “magnitude likely in your lifetime” is the earthquake you
                need to be prepared for, since it is more likely than not to
                occur in your lifetime. An earthquake of this magnitude has a 1%
                chance of occurring in any given year, within 60 miles (100
                kilometers) of your location. If you lived to be 85 years old,
                there would be a 57% chance of this occurring in your lifetime.
              </p>
              <p>
                In the U.S., if you live in the 1% per year (aka “100 year”)
                floodplain, you are required to buy flood insurance. We are
                talking about the same amount of risk for earthquakes.
              </p>
              <p>
                We derive this quake from the Global Earthquake Activity Rate
                model by Bird et al. (Bull. Seismol. Soc. Amer., 2015), which
                has been independently tested by the international Collaboratory
                for the Study of Earthquake Predictability (CSEP), where it
                surpassed its competitors (Strader et al., Seismol. Res. Letts.,
                2018).
              </p>
              <p>
                <strong>
                  How does Temblor estimate the “strongest shaking expected” at
                  your location?
                </strong>
              </p>
              <p>
                We consider all 850 U.S. Geological Survey (USGS) scenario
                earthquakes on major U.S. faults and find the scenario quake
                that would most strongly shake your location. We report that
                shaking in terms of peak ground acceleration (in %g), the fault
                on which the rupture occurred, and the magnitude of that
                rupture.
              </p>
              <p>
                <strong>
                  How does Temblor estimate the “strongest shaking likely in
                  your lifetime” at your location?
                </strong>
              </p>
              <p>
                We transform the Global Earthquake Activity Rate (GEAR) model
                into a shaking model that we call Probabilistic Uniform Seismic
                Hazard (PUSH). PUSH is globally consistent and corrected for
                soil amplification. It was trained on the USGS shaking model.
              </p>
              <p>Read more about PUSH.</p>
              <p>
                Outside of Taiwan, we report this shaking as both Modified
                Mercalli Intensity (MMI at 1% per year) numerals and descriptive
                text with standardized definitions of damage (USGS).
              </p>

              <p>
                <strong> What can I do with this information?</strong>
              </p>
              <p>
                Becaused damaging earthquakes are rare, it’s easy to dismiss
                them. But being unprepared when one strikes can have disastrous
                consequences. Whether you are buying a new home or just found
                out that you live in earthquake country, learning about your
                risk is a first step towards protecting yourself, your family,
                and your property. See our list of recommended providers to
                connect with earthquake insurance agents, retrofit contractors,
                and structural product manufacturers, and take action today.
              </p>
              <p>
                <strong>
                  {" "}
                  What is liquefaction (data available in some U.S. states)?
                </strong>
              </p>
              <p>
                Liquefaction occurs when an earthquake shakes loose,
                water-saturated sediment, causing the ground to lose its
                strength. When this happens, buildings can be severely damaged
                or even collapse. Within the U.S., we obtain liquefaction
                susceptibility data from state geological surveys. In Taiwan,
                this data comes from the Liquefaction Susceptibility Inquiry
                System by the Central Geological Survey, MOEA.
              </p>
              <p>
                <strong>
                  {" "}
                  Will Temblor let me know when there is an earthquake nearby?
                </strong>
              </p>
              <p>
                Yes. Temblor will send you a notification when an earthquake
                strikes near your location. This is NOT an early earthquake
                alert and is not intended to inform you of impending shaking.
                Various agencies around the world distribute early earthquake
                warnings to earthquake-prone regions. We encourage you to seek
                out your local agency to receive such alerts.
              </p>
              <p>
                <strong>
                  Where does Temblor get its landslide susceptibility data
                  (California, Oregon, and Taiwan)?
                </strong>
              </p>
              <p>Taiwan — Chyi-Tyi Lee, Engineering Geology, 2014</p>
              <p>
                Oregon — Oregon Department of Geology and Mineral Industries
              </p>
              <p>California — California Geological Survey</p>

              <p>
                <strong>
                  Where does Temblor get its flood susceptibility data (some
                  U.S. states)?
                </strong>
              </p>
              <p>
                Federal Emergency Management Agency
                <p>
                  <p>
                    <strong>
                      Where does Temblor get its tsunami data (U.S. and Taiwan)?
                    </strong>
                  </p>
                  <p>U.S. — Various state agencies</p>
                  <p>
                    Taiwan — Tso-Ren Wu, Journal of Earthquake and Tsunami, 2012
                  </p>
                  <p></p>
                  <p>
                    <strong>
                      Where does Temblor get its map of wildfire hazard
                      (California)?
                    </strong>
                  </p>
                  California Department of Forestry and Fire Protection
                </p>
                <p>
                  <strong>Why do the data in Taiwan look different?</strong>
                </p>
                We have partnered with the Earthquake-Disaster and Risk
                Evaluation and Management (E-DREaM) Center to incorporate hazard
                models from the Central Weather Bureau (CWB) of Taiwan.
              </p>
              <p>E-DREaM-Temblor collaboration:</p>
              <p>
                Silicon Valley-based Temblor, Inc. and National Central
                University-based E-DREaM collaborated on the Taiwan portion of
                the Temblor mobile risk app, with funding from the Higher
                Education Sprout Project of the Ministry of Education. In
                Taiwan, the app incorporates the latest probabilistic and
                scenario seismic hazard assessment from the Taiwan Earthquake
                Model, and data from the Central Weather Bureau and Palert
                nationwide seismic networks, and maps the compounding effects of
                liquefaction, landslides, and tsunamis. These features are
                available and understandable to everyone.
              </p>
              <p>
                <strong>Temblor, Inc.</strong> is an innovative catastrophe
                modeling company specializing in seismic hazard and risk
                assessment. Founded in 2014 by former USGS scientists Ross Stein
                and Volkan Sevilgen, Temblor’s mission is to raise awareness of
                seismic risk, and to inspire people to reduce it. We serve the
                insurance, reinsurance and insurance-linked security
                communities, and mortgage lenders. We also provide the public
                our free global mobile risk app and Temblor Earthquake News,
                scientifically sound articles that report about breaking
                earthquake news and discoveries.
                https://temblor.net/about-temblor/{" "}
              </p>
              <p>
                <strong>
                  Earthquake Disaster & Risk Evaluation and Management Center
                  (E-DREaM)
                </strong>{" "}
                brings together experts and scholars in the disaster chains,
                including the Department of Earth Sciences, the Institute of
                Applied Geology, the Department of Atmospheric Sciences, the
                Institute of Hydrology and Oceanography and the Department of
                Civil Engineering of the National Central University, to
                integrate and analyze the risk factors of earthquakes, tsunami,
                landslides, landslide dam, soil liquefaction and extreme weather
                systems, developing into a specialized agency linking academics
                with insurance, technology and engineering industries. Based on
                the long-term earthquake research of NCU’s College of Earth
                Sciences, the group develops disaster risk assessment and
                management strategies with the industry.
              </p>
              <p>
                <strong>References</strong>
              </p>
              <p>
                Bird, P., Jackson, D. D., Kagan, Y. Y., Kreemer, C., & Stein, R.
                S. (2015). GEAR1: A Global Earthquake Activity Rate Model
                Constructed from Geodetic Strain Rates and Smoothed
                SeismicityGEAR1: A Global Earthquake Activity Rate Model
                Constructed from Geodetic Strain Rates and Smoothed Seismicity.
                Bulletin of the Seismological Society of America, 105(5),
                2538-2554.
              </p>
              <p>
                Lee, C. T. (2014). Statistical seismic landslide hazard
                analysis: An example from Taiwan. Engineering geology, 182,
                201-212.
              </p>
              <p>
                Strader, A., Werner, M., Bayona, J., Maechling, P., Silva, F.,
                Liukis, M., & Schorlemmer, D. (2018). Prospective evaluation of
                global earthquake forecast models: 2 yrs of observations provide
                preliminary support for merging smoothed seismicity with
                geodetic strain rates. Seismological Research Letters, 89(4),
                1262-1271.
              </p>
              <p>
                Wu, T. R. (2012). Deterministic study on the potential large
                tsunami hazard in Taiwan. Journal of Earthquake and Tsunami,
                6(03), 1250034.
              </p>
              <p>https://www.conservation.ca.gov/cgs</p>
              <p>https://www.fema.gov/</p>
              <p>https://www.fire.ca.gov/</p>
              <p>https://www.liquid.net.tw/cgs/public/</p>
              <p>https://www.oregongeology.org/</p>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default Faq;
