import React from "react";

export default function mapStyle(t, region, screen) {
  let gearVis = "visible";
  if (region === "tw" || screen === "earthquakes") gearVis = "none";

  let cwbiVis = "visible";
  if (screen === "earthquakes") cwbiVis = "none";

  return {
    version: 8,
    sprite: "mapbox://sprites/mapbox/light-v10",
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    name: "Temblor App Map",
    sources: {
      "mapbox://mapbox.satellite": {
        url: "mapbox://mapbox.satellite",
        type: "raster",
        tileSize: 256,
      },
      composite: { type: "vector", url: "mapbox://mapbox.mapbox-streets-v8" },
      "terrain-light": {
        type: "raster-dem",
        url: "mapbox://mapbox.terrain-rgb",
      },
      "terrain-dem": {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
      },
      gear: { type: "raster", url: "mapbox://temblor.gear" },
      cwbi: { type: "raster", url: "mapbox://temblor.cwbi" },
      "push-mmi-100": { type: "raster", url: "mapbox://temblor.push-mmi" },
      "scenarios-cwbi": {
        type: "raster",
        url: "mapbox://temblor.scenarios-tw-cwbi",
      },
      "landslide-us": { type: "raster", url: "mapbox://temblor.landslide-us" },
      "landslide-tw": { type: "raster", url: "mapbox://temblor.landslide-tw" },
      wildfire: { type: "vector", url: "mapbox://temblor.wildfire" },
      liquefaction: { type: "vector", url: "mapbox://temblor.liquefaction" },
      tsunami: { type: "vector", url: "mapbox://temblor.tsunami" },
      "tsunami-tw": { type: "raster", url: "mapbox://temblor.tsunami-tw-v1" },
      flood: { type: "vector", url: "mapbox://temblor.flood" },
      slab2: { type: "raster", url: "mapbox://temblor.slab2" },
      faults: { type: "vector", url: "mapbox://temblor.faults-v1" },
      "quakes-merged": { type: "geojson", data: null },
      "palert-events": { type: "geojson", data: null },
      "palert-stations": { type: "geojson", data: null },
    },
    layers: [
      {
        id: "terrain-background",
        type: "background",
        paint: { "background-color": "#eee" },
      },
      { id: "terrain-hillshade", type: "hillshade", source: "terrain-light" },
      {
        id: "coastline",
        type: "line",
        source: "composite",
        "source-layer": "water",
        layout: { "line-cap": "round", "line-join": "round" },
        paint: { "line-color": "#888", "line-width": 1 },
      },
      {
        id: "waterway",
        type: "line",
        source: "composite",
        "source-layer": "waterway",
        minzoom: 8,
        layout: {
          "line-cap": ["step", ["zoom"], "butt", 11, "round"],
          "line-join": "round",
        },
        paint: {
          "line-color": "hsl(187, 9%, 81%)",
          "line-width": [
            "interpolate",
            ["exponential", 1.3],
            ["zoom"],
            9,
            ["match", ["get", "class"], ["canal", "river"], 0.1, 0],
            20,
            ["match", ["get", "class"], ["canal", "river"], 8, 3],
          ],
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 8, 0, 8.5, 1],
        },
      },
      {
        id: "land-structure-polygon",
        type: "fill",
        source: "composite",
        "source-layer": "structure",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["geometry-type"], "Polygon"],
          ["==", ["get", "class"], "land"],
        ],
        layout: {},
        paint: { "fill-color": "hsl(156, 20%, 95%)" },
      },
      {
        id: "land-structure-line",
        type: "line",
        source: "composite",
        "source-layer": "structure",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["geometry-type"], "LineString"],
          ["==", ["get", "class"], "land"],
        ],
        layout: { "line-cap": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.99],
            ["zoom"],
            14,
            0.75,
            20,
            40,
          ],
          "line-color": "hsl(156, 20%, 95%)",
        },
      },
      {
        id: "aeroway-polygon",
        type: "fill",
        source: "composite",
        "source-layer": "aeroway",
        minzoom: 11,
        filter: [
          "all",
          ["==", ["geometry-type"], "Polygon"],
          [
            "match",
            ["get", "type"],
            ["runway", "taxiway", "helipad"],
            true,
            false,
          ],
        ],
        layout: {},
        paint: {
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 11, 0, 11.5, 1],
          "fill-color": "hsl(0, 0%, 97%)",
        },
      },
      {
        id: "aeroway-line",
        type: "line",
        source: "composite",
        "source-layer": "aeroway",
        minzoom: 9,
        filter: ["==", ["geometry-type"], "LineString"],
        layout: {},
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            9,
            ["match", ["get", "type"], "runway", 1, "taxiway", 0.5, 0.5],
            18,
            ["match", ["get", "type"], "runway", 80, "taxiway", 20, 20],
          ],
          "line-color": "hsl(0, 0%, 97%)",
        },
      },
      {
        id: "building-outline",
        type: "line",
        source: "composite",
        "source-layer": "building",
        minzoom: 15,
        filter: [
          "all",
          ["!=", ["get", "type"], "building:part"],
          ["==", ["get", "underground"], "false"],
        ],
        layout: {},
        paint: {
          "line-color": "hsl(55, 3%, 87%)",
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            15,
            0.75,
            20,
            3,
          ],
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 15, 0, 16, 1],
        },
      },
      {
        id: "building",
        type: "fill",
        source: "composite",
        "source-layer": "building",
        minzoom: 15,
        filter: [
          "all",
          ["!=", ["get", "type"], "building:part"],
          ["==", ["get", "underground"], "false"],
        ],
        layout: {},
        paint: {
          "fill-outline-color": "hsl(55, 3%, 87%)",
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 15, 0, 16, 1],
          "fill-color": "hsl(55, 5%, 91%)",
        },
      },
      {
        id: "tunnel-street-minor-low",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "track", "primary_link"],
              true,
              false,
            ],
            14,
            [
              "match",
              ["get", "class"],
              [
                "street",
                "street_limited",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service",
              ],
              true,
              false,
            ],
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              2,
              "track",
              1,
              0.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              18,
              12,
            ],
          ],
          "line-color": "hsl(185, 7%, 88%)",
          "line-opacity": ["step", ["zoom"], 1, 14, 0],
        },
      },
      {
        id: "tunnel-street-minor-case",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "track", "primary_link"],
              true,
              false,
            ],
            14,
            [
              "match",
              ["get", "class"],
              [
                "street",
                "street_limited",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service",
              ],
              true,
              false,
            ],
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-color": "hsl(185, 12%, 89%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              2,
              "track",
              1,
              0.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              18,
              12,
            ],
          ],
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
          "line-dasharray": [3, 3],
        },
      },
      {
        id: "tunnel-primary-secondary-tertiary-case",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "match",
            ["get", "class"],
            ["primary", "secondary", "tertiary"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            [
              "match",
              ["get", "class"],
              "primary",
              1,
              ["secondary", "tertiary"],
              0.75,
              0.75,
            ],
            18,
            2,
          ],
          "line-color": "hsl(185, 12%, 89%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            [
              "match",
              ["get", "class"],
              "primary",
              0.75,
              ["secondary", "tertiary"],
              0.1,
              0.1,
            ],
            18,
            [
              "match",
              ["get", "class"],
              "primary",
              32,
              ["secondary", "tertiary"],
              26,
              26,
            ],
          ],
          "line-dasharray": [3, 3],
        },
      },
      {
        id: "tunnel-major-link-case",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-color": "hsl(185, 12%, 89%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-dasharray": [3, 3],
        },
      },
      {
        id: "tunnel-motorway-trunk-case",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            1,
            18,
            2,
          ],
          "line-color": "hsl(185, 12%, 89%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-dasharray": [3, 3],
        },
      },
      {
        id: "tunnel-construction",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          ["==", ["get", "class"], "construction"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: {},
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(187, 7%, 88%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [0.4, 0.8]],
            15,
            ["literal", [0.3, 0.6]],
            16,
            ["literal", [0.2, 0.3]],
            17,
            ["literal", [0.2, 0.25]],
            18,
            ["literal", [0.15, 0.15]],
          ],
        },
      },
      {
        id: "tunnel-path",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          ["==", ["get", "class"], "path"],
          ["!=", ["get", "type"], "steps"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            15,
            1,
            18,
            4,
          ],
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [1, 0.5]],
          ],
          "line-color": "hsl(0, 0%, 85%)",
        },
      },
      {
        id: "tunnel-steps",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          ["==", ["get", "class"], "steps"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            15,
            1,
            16,
            1.6,
            18,
            6,
          ],
          "line-color": "hsl(0, 0%, 85%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [0.3, 0.3]],
          ],
        },
      },
      {
        id: "tunnel-major-link",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(187, 7%, 88%)",
        },
      },
      {
        id: "tunnel-pedestrian",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          ["==", ["get", "class"], "pedestrian"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            0.5,
            18,
            12,
          ],
          "line-color": "hsl(187, 7%, 88%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.5, 0.4]],
            16,
            ["literal", [1, 0.2]],
          ],
        },
      },
      {
        id: "tunnel-street-minor",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "track", "primary_link"],
              true,
              false,
            ],
            14,
            [
              "match",
              ["get", "class"],
              [
                "street",
                "street_limited",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service",
              ],
              true,
              false,
            ],
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              2,
              "track",
              1,
              0.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              18,
              12,
            ],
          ],
          "line-color": "hsl(187, 7%, 88%)",
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "tunnel-primary-secondary-tertiary",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          [
            "match",
            ["get", "class"],
            ["primary", "secondary", "tertiary"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            [
              "match",
              ["get", "class"],
              "primary",
              0.75,
              ["secondary", "tertiary"],
              0.1,
              0.1,
            ],
            18,
            [
              "match",
              ["get", "class"],
              "primary",
              32,
              ["secondary", "tertiary"],
              26,
              26,
            ],
          ],
          "line-color": "hsl(187, 7%, 88%)",
        },
      },
      {
        id: "tunnel-motorway-trunk",
        type: "line",
        metadata: { "mapbox:group": "1444855769305.6016" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "tunnel"],
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-color": "hsl(187, 7%, 88%)",
        },
      },
      {
        id: "road-pedestrian-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 12,
        filter: [
          "all",
          ["==", ["get", "class"], "pedestrian"],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            2,
            18,
            14.5,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "road-minor-low",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          [
            "step",
            ["zoom"],
            ["==", ["get", "class"], "track"],
            14,
            [
              "match",
              ["get", "class"],
              ["track", "secondary_link", "tertiary_link", "service"],
              true,
              false,
            ],
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            ["match", ["get", "class"], "track", 1, 0.5],
            18,
            12,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-opacity": ["step", ["zoom"], 1, 14, 0],
        },
      },
      {
        id: "road-street-low",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["street", "street_limited", "primary_link"],
            true,
            false,
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-opacity": ["step", ["zoom"], 1, 14, 0],
        },
      },
      {
        id: "road-minor-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          [
            "step",
            ["zoom"],
            ["==", ["get", "class"], "track"],
            14,
            [
              "match",
              ["get", "class"],
              ["track", "secondary_link", "tertiary_link", "service"],
              true,
              false,
            ],
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            ["match", ["get", "class"], "track", 1, 0.5],
            18,
            12,
          ],
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "road-street-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["street", "street_limited", "primary_link"],
            true,
            false,
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "road-secondary-tertiary-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["match", ["get", "class"], ["secondary", "tertiary"], true, false],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            0.75,
            18,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.1,
            18,
            26,
          ],
        },
      },
      {
        id: "road-primary-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", ["get", "class"], "primary"],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            1,
            18,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
        },
      },
      {
        id: "road-major-link-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 10,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-opacity": ["step", ["zoom"], 0, 11, 1],
        },
      },
      {
        id: "road-motorway-trunk-case",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            1,
            18,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-opacity": [
            "step",
            ["zoom"],
            ["match", ["get", "class"], "motorway", 1, 0],
            6,
            1,
          ],
        },
      },
      {
        id: "road-construction",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["==", ["get", "class"], "construction"],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: {},
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [0.4, 0.8]],
            15,
            ["literal", [0.3, 0.6]],
            16,
            ["literal", [0.2, 0.3]],
            17,
            ["literal", [0.2, 0.25]],
            18,
            ["literal", [0.15, 0.15]],
          ],
        },
      },
      {
        id: "road-path",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 12,
        filter: [
          "all",
          ["==", ["get", "class"], "path"],
          [
            "step",
            ["zoom"],
            [
              "!",
              [
                "match",
                ["get", "type"],
                ["steps", "sidewalk", "crossing"],
                true,
                false,
              ],
            ],
            16,
            ["!=", ["get", "type"], "steps"],
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            13,
            0.5,
            14,
            1,
            15,
            1,
            18,
            4,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [1, 0.5]],
          ],
        },
      },
      {
        id: "road-steps",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["==", ["get", "type"], "steps"],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            15,
            1,
            16,
            1.6,
            18,
            6,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [0.3, 0.3]],
          ],
        },
      },
      {
        id: "road-major-link",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 10,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "road-pedestrian",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 12,
        filter: [
          "all",
          ["==", ["get", "class"], "pedestrian"],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            0.5,
            18,
            12,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.5, 0.4]],
            16,
            ["literal", [1, 0.2]],
          ],
        },
      },
      {
        id: "road-minor",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          [
            "step",
            ["zoom"],
            ["==", ["get", "class"], "track"],
            14,
            [
              "match",
              ["get", "class"],
              ["track", "secondary_link", "tertiary_link", "service"],
              true,
              false,
            ],
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            ["match", ["get", "class"], "track", 1, 0.5],
            18,
            12,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "road-street",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["street", "street_limited", "primary_link"],
            true,
            false,
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "road-secondary-tertiary",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["match", ["get", "class"], ["secondary", "tertiary"], true, false],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.1,
            18,
            26,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "road-primary",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", ["get", "class"], "primary"],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "road-motorway-trunk",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "road-rail",
        type: "line",
        metadata: { "mapbox:group": "1444855786460.0557" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["major_rail", "minor_rail"],
            true,
            false,
          ],
          ["match", ["get", "structure"], ["none", "ford"], true, false],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            0.5,
            20,
            1,
          ],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-pedestrian-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          ["==", ["get", "class"], "pedestrian"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            2,
            18,
            14.5,
          ],
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-street-minor-low",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "track", "primary_link"],
              true,
              false,
            ],
            14,
            [
              "match",
              ["get", "class"],
              [
                "street",
                "street_limited",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service",
              ],
              true,
              false,
            ],
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              2,
              "track",
              1,
              0.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              18,
              12,
            ],
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-opacity": ["step", ["zoom"], 1, 14, 0],
        },
      },
      {
        id: "bridge-street-minor-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "track", "primary_link"],
              true,
              false,
            ],
            14,
            [
              "match",
              ["get", "class"],
              [
                "street",
                "street_limited",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service",
              ],
              true,
              false,
            ],
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              2,
              "track",
              1,
              0.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              18,
              12,
            ],
          ],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-primary-secondary-tertiary-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "match",
            ["get", "class"],
            ["primary", "secondary", "tertiary"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            [
              "match",
              ["get", "class"],
              "primary",
              1,
              ["secondary", "tertiary"],
              0.75,
              0.75,
            ],
            18,
            2,
          ],
          "line-opacity": ["step", ["zoom"], 0, 10, 1],
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            [
              "match",
              ["get", "class"],
              "primary",
              0.75,
              ["secondary", "tertiary"],
              0.1,
              0.1,
            ],
            18,
            [
              "match",
              ["get", "class"],
              "primary",
              32,
              ["secondary", "tertiary"],
              26,
              26,
            ],
          ],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-major-link-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["<=", ["get", "layer"], 1],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-motorway-trunk-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["<=", ["get", "layer"], 1],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            1,
            18,
            2,
          ],
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-construction",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          ["==", ["get", "class"], "construction"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: {},
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            2,
            18,
            18,
          ],
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [0.4, 0.8]],
            15,
            ["literal", [0.3, 0.6]],
            16,
            ["literal", [0.2, 0.3]],
            17,
            ["literal", [0.2, 0.25]],
            18,
            ["literal", [0.15, 0.15]],
          ],
          "line-color": "hsl(156, 0%, 100%)",
        },
      },
      {
        id: "bridge-path",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          ["==", ["get", "class"], "path"],
          ["!=", ["get", "type"], "steps"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            15,
            1,
            18,
            4,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [1, 0.5]],
          ],
        },
      },
      {
        id: "bridge-steps",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["==", ["get", "type"], "steps"],
          ["==", ["get", "structure"], "bridge"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            15,
            1,
            16,
            1.6,
            18,
            6,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [0.3, 0.3]],
          ],
        },
      },
      {
        id: "bridge-major-link",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["<=", ["get", "layer"], 1],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "bridge-pedestrian",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          ["==", ["get", "class"], "pedestrian"],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            0.5,
            18,
            12,
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [1, 0]],
            15,
            ["literal", [1.5, 0.4]],
            16,
            ["literal", [1, 0.2]],
          ],
        },
      },
      {
        id: "bridge-street-minor",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "track", "primary_link"],
              true,
              false,
            ],
            14,
            [
              "match",
              ["get", "class"],
              [
                "street",
                "street_limited",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service",
              ],
              true,
              false,
            ],
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              2,
              "track",
              1,
              0.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["street", "street_limited", "primary_link"],
              18,
              12,
            ],
          ],
          "line-color": "hsl(0, 0%, 100%)",
          "line-opacity": ["step", ["zoom"], 0, 14, 1],
        },
      },
      {
        id: "bridge-primary-secondary-tertiary",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "match",
            ["get", "class"],
            ["primary", "secondary", "tertiary"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            [
              "match",
              ["get", "class"],
              "primary",
              0.75,
              ["secondary", "tertiary"],
              0.1,
              0.1,
            ],
            18,
            [
              "match",
              ["get", "class"],
              "primary",
              32,
              ["secondary", "tertiary"],
              26,
              26,
            ],
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "bridge-motorway-trunk",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["<=", ["get", "layer"], 1],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "bridge-rail",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [
            "match",
            ["get", "class"],
            ["major_rail", "minor_rail"],
            true,
            false,
          ],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            14,
            0.5,
            20,
            1,
          ],
          "line-color": "hsl(156, 12%, 92%)",
        },
      },
      {
        id: "bridge-major-link-2-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [">=", ["get", "layer"], 2],
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.75,
            20,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
        },
      },
      {
        id: "bridge-motorway-trunk-2-case",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [">=", ["get", "layer"], 2],
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            10,
            1,
            18,
            2,
          ],
          "line-color": "hsl(156, 12%, 92%)",
          "line-gap-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
        },
      },
      {
        id: "bridge-major-link-2",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [">=", ["get", "layer"], 2],
          [
            "match",
            ["get", "class"],
            ["motorway_link", "trunk_link"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "bridge-motorway-trunk-2",
        type: "line",
        metadata: { "mapbox:group": "1444855799204.86" },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", ["get", "structure"], "bridge"],
          [">=", ["get", "layer"], 2],
          ["match", ["get", "class"], ["motorway", "trunk"], true, false],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: { "line-cap": "round", "line-join": "round" },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            5,
            0.75,
            18,
            32,
          ],
          "line-color": "hsl(0, 0%, 100%)",
        },
      },
      {
        id: "admin-1-boundary-bg",
        type: "line",
        metadata: { "mapbox:group": "1444934295202.7542" },
        source: "composite",
        "source-layer": "admin",
        filter: [
          "all",
          ["==", ["get", "admin_level"], 1],
          ["==", ["get", "maritime"], "false"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
        ],
        layout: { "line-join": "bevel" },
        paint: {
          "line-blur": ["interpolate", ["linear"], ["zoom"], 3, 0, 8, 3],
          "line-width": ["interpolate", ["linear"], ["zoom"], 7, 3.75, 12, 5.5],
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 0.75],
          "line-dasharray": [1, 0],
          "line-translate": [0, 0],
          "line-color": "hsl(0, 0%, 84%)",
        },
      },
      {
        id: "admin-0-boundary-bg",
        type: "line",
        metadata: { "mapbox:group": "1444934295202.7542" },
        source: "composite",
        "source-layer": "admin",
        minzoom: 1,
        filter: [
          "all",
          ["==", ["get", "admin_level"], 0],
          ["==", ["get", "maritime"], "false"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
        ],
        layout: {},
        paint: {
          "line-width": ["interpolate", ["linear"], ["zoom"], 3, 3.5, 10, 8],
          "line-color": "hsl(0, 0%, 84%)",
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 3, 0, 4, 0.5],
          "line-translate": [0, 0],
          "line-blur": ["interpolate", ["linear"], ["zoom"], 3, 0, 10, 2],
        },
      },
      {
        id: "admin-1-boundary",
        type: "line",
        metadata: { "mapbox:group": "1444934295202.7542" },
        source: "composite",
        "source-layer": "admin",
        filter: [
          "all",
          ["==", ["get", "admin_level"], 1],
          ["==", ["get", "maritime"], "false"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
        ],
        layout: { "line-join": "round", "line-cap": "round" },
        paint: {
          "line-dasharray": [
            "step",
            ["zoom"],
            ["literal", [2, 0]],
            7,
            ["literal", [2, 2, 6, 2]],
          ],
          "line-width": ["interpolate", ["linear"], ["zoom"], 7, 0.75, 12, 1.5],
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 2, 0, 3, 1],
          "line-color": [
            "interpolate",
            ["linear"],
            ["zoom"],
            3,
            "hsl(0, 0%, 80%)",
            7,
            "hsl(0, 0%, 70%)",
          ],
        },
      },
      {
        id: "admin-0-boundary",
        type: "line",
        metadata: { "mapbox:group": "1444934295202.7542" },
        source: "composite",
        "source-layer": "admin",
        minzoom: 1,
        filter: [
          "all",
          ["==", ["get", "admin_level"], 0],
          ["==", ["get", "disputed"], "false"],
          ["==", ["get", "maritime"], "false"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
        ],
        layout: { "line-join": "round", "line-cap": "round" },
        paint: {
          "line-color": "hsl(0, 0%, 62%)",
          "line-width": ["interpolate", ["linear"], ["zoom"], 3, 0.5, 10, 2],
        },
      },
      {
        id: "admin-0-boundary-disputed",
        type: "line",
        metadata: { "mapbox:group": "1444934295202.7542" },
        source: "composite",
        "source-layer": "admin",
        minzoom: 1,
        filter: [
          "all",
          ["==", ["get", "disputed"], "true"],
          ["==", ["get", "admin_level"], 0],
          ["==", ["get", "maritime"], "false"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
        ],
        layout: { "line-join": "round" },
        paint: {
          "line-dasharray": [1.5, 1.5],
          "line-color": "hsl(0, 0%, 62%)",
          "line-width": ["interpolate", ["linear"], ["zoom"], 3, 0.5, 10, 2],
        },
      },
      {
        id: "background-s",
        type: "background",
        layout: { visibility: "none" },
        paint: { "background-color": "hsl(222, 56%, 4%)" },
      },
      {
        id: "satellite-s",
        type: "raster",
        source: "mapbox://mapbox.satellite",
        layout: { visibility: "none" },
        paint: {},
      },
      {
        id: "gear",
        type: "raster",
        source: "gear",
        paint: { "raster-opacity": 0.8 },
        layout: { visibility: gearVis },
      },
      {
        id: "cwbi",
        type: "raster",
        source: "cwbi",
        paint: { "raster-opacity": 0.8 },
        layout: { visibility: cwbiVis },
      },
      {
        id: "push-mmi-100",
        type: "raster",
        source: "push-mmi-100",
        paint: { "raster-opacity": 0.8 },
        layout: { visibility: "none" },
      },
      {
        id: "scenarios-cwbi",
        type: "raster",
        source: "scenarios-cwbi",
        paint: { "raster-opacity": 0.8 },
        layout: { visibility: "none" },
      },
      {
        id: "water",
        type: "fill",
        source: "composite",
        "source-layer": "water",
        paint: { "fill-color": "#fff" },
      },
      {
        id: "landslide-us",
        type: "raster",
        source: "landslide-us",
        paint: { "raster-opacity": 0.6 },
        layout: { visibility: "none" },
      },
      {
        id: "landslide-tw",
        type: "raster",
        source: "landslide-tw",
        layout: { visibility: "none" },
      },
      {
        id: "wildfire",
        type: "fill",
        source: "wildfire",
        "source-layer": "wildfire",
        paint: {
          "fill-antialias": false,
          "fill-opacity": 0.5,
          "fill-color": [
            "match",
            ["get", "code"],
            1,
            "hsl(60,100%,50%)",
            2,
            "hsl(38,100%,50%)",
            3,
            "hsl(0,100%,50%)",
            "#0f0",
          ],
        },
        layout: { visibility: "none" },
      },
      {
        id: "liquefaction",
        type: "fill",
        source: "liquefaction",
        "source-layer": "liquefaction",
        paint: {
          "fill-antialias": false,
          "fill-opacity": 0.6,
          "fill-color": [
            "match",
            ["get", "hazard"],
            1,
            "hsl(270,75%,90%)",
            2,
            "hsl(270,75%,75%)",
            3,
            "hsl(270,75%,60%)",
            4,
            "hsl(270,75%,45%)",
            5,
            "hsl(270,75%,30%)",
            "#00ff00",
          ],
        },
        layout: { visibility: "none" },
      },
      {
        id: "tsunami",
        type: "fill",
        source: "tsunami",
        "source-layer": "tsunami",
        paint: {
          "fill-opacity": 0.6,
          "fill-antialias": false,
          "fill-color": "#0dd",
        },
        layout: { visibility: "none" },
      },
      {
        id: "tsunami-tw",
        type: "raster",
        source: "tsunami-tw",
        layout: { visibility: "none" },
      },
      {
        id: "flood",
        type: "fill",
        source: "flood",
        "source-layer": "flood",
        paint: {
          "fill-antialias": false,
          "fill-opacity": 0.6,
          "fill-color": [
            "match",
            ["get", "zone"],
            ["X", "B"],
            "#a6d8e3",
            "#43a5e3",
          ],
        },
        layout: { visibility: "none" },
      },
      {
        id: "slab2",
        type: "raster",
        source: "slab2",
        paint: { "raster-opacity": 0.2 },
        layout: { visibility: "none" },
      },
      {
        id: "fault_zones",
        type: "fill",
        source: "faults",
        "source-layer": "fault_zones",
        paint: { "fill-opacity": 0.15, "fill-color": "#f00" },
      },
      {
        id: "fault_traces_target",
        type: "line",
        source: "faults",
        "source-layer": "fault_traces",
        filter: ["!", ["has", "exclude"]],
        paint: {
          "line-color": "#000",
          "line-width": 12,
          "line-opacity": 0,
        },
      },
      {
        id: "fault_traces",
        type: "line",
        source: "faults",
        "source-layer": "fault_traces",
        filter: ["!", ["has", "exclude"]],
        paint: {
          "line-color": "#f00",
          "line-width": [
            "match",
            ["get", "sliprate"],
            1,
            0.5,
            2,
            0.8,
            3,
            1,
            4,
            1,
            5,
            2,
            1,
          ],
        },
      },
      {
        id: "quakes-merged",
        type: "circle",
        source: "quakes-merged",
        paint: {
          "circle-pitch-alignment": "map",
          "circle-opacity": 0.7,
          "circle-stroke-width": 0.5,
          "circle-stroke-color": "#000",
          "circle-radius": [
            "step",
            ["zoom"],
            ["*", 2, ["-", ["get", "mag"], 3]],
            2,
            ["*", 2, ["-", ["get", "mag"], 2]],
            4,
            ["*", 2, ["-", ["get", "mag"], 1]],
            6,
            ["*", 2, ["get", "mag"]],
            8,
            ["*", 2, ["+", ["get", "mag"], 1]],
          ],
          "circle-color": "#ff0",
        },
      },
      {
        id: "palert-stations",
        type: "circle",
        source: "palert-stations",
        paint: {
          "circle-pitch-alignment": "map",
          "circle-stroke-width": 0.5,
          "circle-opacity": 0.7,
          "circle-radius": 5,
          "circle-color": [
            "interpolate",
            ["linear"],
            ["to-number", ["get", "cwbi"]],
            0,
            "#ffffff",
            1,
            "#b5e8ff",
            2,
            "#00c4e3",
            3,
            "#91cc9e",
            4,
            "#ffff8f",
            5,
            "#ffcc00",
            6,
            "#ff9900",
            7,
            "#ff0000",
            8,
            "#7f0000",
          ],
        },
        layout: { visibility: "none" },
      },
      {
        id: "palert-events",
        type: "circle",
        source: "palert-events",
        paint: {
          "circle-pitch-alignment": "map",
          "circle-stroke-width": 0.5,
          "circle-opacity": 0.7,
          "circle-radius": 8,
          "circle-color": " #cc00ff",
        },
        layout: { visibility: "none" },
      },
      {
        id: "road-label",
        type: "symbol",
        source: "composite",
        "source-layer": "road",
        minzoom: 10,
        filter: [
          "step",
          ["zoom"],
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary", "tertiary"],
            true,
            false,
          ],
          12,
          [
            "match",
            ["get", "class"],
            [
              "motorway",
              "trunk",
              "primary",
              "secondary",
              "tertiary",
              "pedestrian",
              "street",
              "street_limited",
            ],
            true,
            false,
          ],
          15,
          ["match", ["get", "class"], ["ferry", "golf", "path"], false, true],
        ],
        layout: {
          "text-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            [
              "match",
              ["get", "class"],
              ["motorway", "trunk", "primary", "secondary", "tertiary"],
              10,
              [
                "motorway_link",
                "trunk_link",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "pedestrian",
                "street",
                "street_limited",
              ],
              9,
              6.5,
            ],
            18,
            [
              "match",
              ["get", "class"],
              ["motorway", "trunk", "primary", "secondary", "tertiary"],
              16,
              [
                "motorway_link",
                "trunk_link",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "pedestrian",
                "street",
                "street_limited",
              ],
              14,
              13,
            ],
          ],
          "text-max-angle": 30,
          "text-font": ["DIN Offc Pro Regular", "Arial Unicode MS Regular"],
          "symbol-placement": "line",
          "text-padding": 1,
          "text-rotation-alignment": "map",
          "text-pitch-alignment": "viewport",
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-letter-spacing": 0.01,
        },
        paint: {
          "text-color": "hsl(0, 0%, 42%)",
          "text-halo-color": [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "hsla(0, 0%, 100%, 0.75)",
            "hsl(0, 0%, 100%)",
          ],
          "text-halo-width": 1,
          "text-halo-blur": 1,
        },
      },
      {
        id: "waterway-label",
        type: "symbol",
        source: "composite",
        "source-layer": "natural_label",
        minzoom: 13,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["canal", "river", "stream"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: {
          "text-font": ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
          "text-max-angle": 30,
          "symbol-spacing": [
            "interpolate",
            ["linear", 1],
            ["zoom"],
            15,
            250,
            17,
            400,
          ],
          "text-size": ["interpolate", ["linear"], ["zoom"], 13, 12, 18, 16],
          "symbol-placement": "line",
          "text-pitch-alignment": "viewport",
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        },
        paint: { "text-color": "hsl(187, 7%, 51%)" },
      },
      {
        id: "natural-line-label",
        type: "symbol",
        source: "composite",
        "source-layer": "natural_label",
        minzoom: 4,
        filter: [
          "all",
          ["match", ["get", "class"], ["glacier", "landform"], true, false],
          ["==", ["geometry-type"], "LineString"],
          ["<=", ["get", "filterrank"], 1],
        ],
        layout: {
          "text-size": [
            "step",
            ["zoom"],
            ["step", ["get", "sizerank"], 18, 5, 12],
            17,
            ["step", ["get", "sizerank"], 18, 13, 12],
          ],
          "text-max-angle": 30,
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Regular"],
          "symbol-placement": "line-center",
          "text-pitch-alignment": "viewport",
        },
        paint: {
          "text-halo-width": 0.5,
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-halo-blur": 0.5,
          "text-color": "hsl(0, 0%, 42%)",
        },
      },
      {
        id: "natural-point-label",
        type: "symbol",
        source: "composite",
        "source-layer": "natural_label",
        minzoom: 4,
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["dock", "glacier", "landform", "water_feature", "wetland"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "Point"],
          ["<=", ["get", "filterrank"], 1],
        ],
        layout: {
          "text-size": [
            "step",
            ["zoom"],
            ["step", ["get", "sizerank"], 18, 5, 12],
            17,
            ["step", ["get", "sizerank"], 18, 13, 12],
          ],
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Regular"],
          "text-offset": ["literal", [0, 0]],
        },
        paint: {
          "text-color": "hsl(0, 0%, 42%)",
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-halo-width": 0.5,
          "text-halo-blur": 0.5,
        },
      },
      {
        id: "water-line-label",
        type: "symbol",
        source: "composite",
        "source-layer": "natural_label",
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["bay", "ocean", "reservoir", "sea", "water"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "LineString"],
        ],
        layout: {
          "text-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7,
            ["step", ["get", "sizerank"], 24, 6, 18, 12, 12],
            10,
            ["step", ["get", "sizerank"], 18, 9, 12],
            18,
            ["step", ["get", "sizerank"], 18, 9, 16],
          ],
          "text-max-angle": 30,
          "text-letter-spacing": [
            "match",
            ["get", "class"],
            "ocean",
            0.25,
            ["sea", "bay"],
            0.15,
            0,
          ],
          "text-font": ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
          "symbol-placement": "line-center",
          "text-pitch-alignment": "viewport",
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        },
        paint: { "text-color": "hsl(187, 7%, 51%)" },
      },
      {
        id: "water-point-label",
        type: "symbol",
        source: "composite",
        "source-layer": "natural_label",
        filter: [
          "all",
          [
            "match",
            ["get", "class"],
            ["bay", "ocean", "reservoir", "sea", "water"],
            true,
            false,
          ],
          ["==", ["geometry-type"], "Point"],
        ],
        layout: {
          "text-line-height": 1.3,
          "text-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7,
            ["step", ["get", "sizerank"], 24, 6, 18, 12, 12],
            10,
            ["step", ["get", "sizerank"], 18, 9, 12],
          ],
          "text-font": ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-letter-spacing": [
            "match",
            ["get", "class"],
            "ocean",
            0.25,
            ["bay", "sea"],
            0.15,
            0.01,
          ],
          "text-max-width": [
            "match",
            ["get", "class"],
            "ocean",
            4,
            "sea",
            5,
            ["bay", "water"],
            7,
            10,
          ],
        },
        paint: { "text-color": "hsl(187, 7%, 51%)" },
      },
      {
        id: "poi-label",
        type: "symbol",
        source: "composite",
        "source-layer": "poi_label",
        minzoom: 6,
        filter: ["<=", ["get", "filterrank"], 1],
        layout: {
          "text-size": [
            "step",
            ["zoom"],
            ["step", ["get", "sizerank"], 18, 5, 12],
            17,
            ["step", ["get", "sizerank"], 18, 13, 12],
          ],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Regular"],
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        },
        paint: {
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-halo-width": 0.5,
          "text-halo-blur": 0.5,
          "text-color": [
            "step",
            ["zoom"],
            [
              "step",
              ["get", "sizerank"],
              "hsl(0, 0%, 66%)",
              5,
              "hsl(230, 0%, 56%)",
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              "hsl(0, 0%, 66%)",
              13,
              "hsl(0, 0%, 56%)",
            ],
          ],
        },
      },
      {
        id: "airport-label",
        type: "symbol",
        source: "composite",
        "source-layer": "airport_label",
        minzoom: 8,
        layout: {
          "text-line-height": 1.1,
          "text-size": ["step", ["get", "sizerank"], 18, 9, 12],
          "icon-image": [
            "step",
            ["get", "sizerank"],
            ["concat", ["get", "maki"], "-15"],
            9,
            ["concat", ["get", "maki"], "-11"],
          ],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Regular"],
          "text-offset": [0, 0.75],
          "text-rotation-alignment": "viewport",
          "text-anchor": "top",
          "text-field": [
            "step",
            ["get", "sizerank"],
            ["coalesce", ["get", "name_en"], ["get", "name"]],
            15,
            ["get", "ref"],
          ],
          "text-letter-spacing": 0.01,
          "text-max-width": 9,
        },
        paint: {
          "text-color": "hsl(0, 0%, 42%)",
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-halo-width": 1,
        },
      },
      {
        id: "settlement-subdivision-label",
        type: "symbol",
        source: "composite",
        "source-layer": "place_label",
        minzoom: 10,
        maxzoom: 15,
        filter: [
          "all",
          ["==", ["get", "class"], "settlement_subdivision"],
          ["<=", ["get", "filterrank"], 4],
        ],
        layout: {
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-transform": "uppercase",
          "text-font": ["DIN Offc Pro Regular", "Arial Unicode MS Regular"],
          "text-letter-spacing": [
            "match",
            ["get", "type"],
            "suburb",
            0.15,
            ["quarter", "neighborhood"],
            0.1,
            0.1,
          ],
          "text-max-width": 7,
          "text-padding": 3,
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.5, 0, 1, 1],
            ["zoom"],
            11,
            [
              "match",
              ["get", "type"],
              "suburb",
              11,
              ["quarter", "neighborhood"],
              10.5,
              10.5,
            ],
            15,
            [
              "match",
              ["get", "type"],
              "suburb",
              17,
              ["quarter", "neighborhood"],
              16,
              16,
            ],
          ],
        },
        paint: {
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-halo-width": 1,
          "text-color": "hsl(0, 0%, 62%)",
          "text-halo-blur": 0.5,
        },
      },
      {
        id: "settlement-label",
        type: "symbol",
        source: "composite",
        "source-layer": "place_label",
        maxzoom: 15,
        filter: [
          "all",
          ["<=", ["get", "filterrank"], 3],
          ["==", ["get", "class"], "settlement"],
          [
            "step",
            ["zoom"],
            true,
            13,
            [">=", ["get", "symbolrank"], 11],
            14,
            [">=", ["get", "symbolrank"], 13],
          ],
        ],
        layout: {
          "text-line-height": 1.1,
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.2, 0, 0.9, 1],
            ["zoom"],
            3,
            [
              "step",
              ["get", "symbolrank"],
              12,
              9,
              11,
              10,
              10.5,
              12,
              9.5,
              14,
              8.5,
              16,
              6.5,
              17,
              4,
            ],
            15,
            [
              "step",
              ["get", "symbolrank"],
              28,
              9,
              26,
              10,
              23,
              11,
              21,
              12,
              20,
              13,
              19,
              15,
              17,
            ],
          ],
          "icon-image": [
            "case",
            ["==", ["get", "capital"], 2],
            "border-dot-13",
            ["step", ["get", "symbolrank"], "dot-11", 9, "dot-10", 11, "dot-9"],
          ],
          "text-font": [
            "step",
            ["zoom"],
            ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]],
            8,
            [
              "step",
              ["get", "symbolrank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              11,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]],
            ],
            10,
            [
              "step",
              ["get", "symbolrank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              12,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]],
            ],
            11,
            [
              "step",
              ["get", "symbolrank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              13,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]],
            ],
            12,
            [
              "step",
              ["get", "symbolrank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              15,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]],
            ],
            13,
            ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
          ],
          "text-justify": [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "text_anchor"],
              ["bottom", "top"],
              "center",
              ["left", "bottom-left", "top-left"],
              "left",
              ["right", "bottom-right", "top-right"],
              "right",
              "center",
            ],
            8,
            "center",
          ],
          "text-offset": [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "capital"],
              2,
              [
                "match",
                ["get", "text_anchor"],
                "bottom",
                ["literal", [0, -0.3]],
                "bottom-left",
                ["literal", [0.3, -0.1]],
                "left",
                ["literal", [0.45, 0.1]],
                "top-left",
                ["literal", [0.3, 0.1]],
                "top",
                ["literal", [0, 0.3]],
                "top-right",
                ["literal", [-0.3, 0.1]],
                "right",
                ["literal", [-0.45, 0]],
                "bottom-right",
                ["literal", [-0.3, -0.1]],
                ["literal", [0, -0.3]],
              ],
              [
                "match",
                ["get", "text_anchor"],
                "bottom",
                ["literal", [0, -0.25]],
                "bottom-left",
                ["literal", [0.2, -0.05]],
                "left",
                ["literal", [0.4, 0.05]],
                "top-left",
                ["literal", [0.2, 0.05]],
                "top",
                ["literal", [0, 0.25]],
                "top-right",
                ["literal", [-0.2, 0.05]],
                "right",
                ["literal", [-0.4, 0.05]],
                "bottom-right",
                ["literal", [-0.2, -0.05]],
                ["literal", [0, -0.25]],
              ],
            ],
            8,
            ["literal", [0, 0]],
          ],
          "text-anchor": [
            "step",
            ["zoom"],
            ["get", "text_anchor"],
            8,
            "center",
          ],
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-max-width": 7,
        },
        paint: {
          "text-color": [
            "step",
            ["get", "symbolrank"],
            "hsl(0, 0%, 42%)",
            11,
            "hsl(0, 0%, 55%)",
            16,
            "hsl(0, 0%, 62%)",
          ],
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-halo-width": 1,
          "icon-opacity": ["step", ["zoom"], 1, 8, 0],
          "text-halo-blur": 1,
        },
      },
      {
        id: "state-label",
        type: "symbol",
        source: "composite",
        "source-layer": "place_label",
        minzoom: 3,
        maxzoom: 9,
        filter: ["==", ["get", "class"], "state"],
        layout: {
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.85, 0.7, 0.65, 1],
            ["zoom"],
            4,
            ["step", ["get", "symbolrank"], 10, 6, 9.5, 7, 9],
            9,
            ["step", ["get", "symbolrank"], 24, 6, 18, 7, 14],
          ],
          "text-transform": "uppercase",
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-field": [
            "step",
            ["zoom"],
            [
              "step",
              ["get", "symbolrank"],
              ["coalesce", ["get", "name_en"], ["get", "name"]],
              5,
              [
                "coalesce",
                ["get", "abbr"],
                ["get", "name_en"],
                ["get", "name"],
              ],
            ],
            5,
            ["coalesce", ["get", "name_en"], ["get", "name"]],
          ],
          "text-letter-spacing": 0.15,
          "text-max-width": 6,
        },
        paint: {
          "text-halo-width": 1,
          "text-halo-color": "hsl(0, 0%, 100%)",
          "text-color": "hsl(0, 0%, 66%)",
        },
      },
      {
        id: "country-label",
        type: "symbol",
        source: "composite",
        "source-layer": "place_label",
        minzoom: 1,
        maxzoom: 10,
        filter: ["==", ["get", "class"], "country"],
        layout: {
          "text-line-height": 1.1,
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.2, 0, 0.7, 1],
            ["zoom"],
            1,
            ["step", ["get", "symbolrank"], 11, 4, 9, 5, 8],
            9,
            ["step", ["get", "symbolrank"], 28, 4, 22, 5, 21],
          ],
          "icon-image": "dot-11",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Regular"],
          "text-justify": [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "text_anchor"],
              ["bottom", "top"],
              "center",
              ["left", "bottom-left", "top-left"],
              "left",
              ["right", "bottom-right", "top-right"],
              "right",
              "center",
            ],
            7,
            "center",
          ],
          "text-offset": [
            "step",
            ["zoom"],
            [
              "match",
              ["get", "text_anchor"],
              "bottom",
              ["literal", [0, -0.25]],
              "bottom-left",
              ["literal", [0.2, -0.05]],
              "left",
              ["literal", [0.4, 0.05]],
              "top-left",
              ["literal", [0.2, 0.05]],
              "top",
              ["literal", [0, 0.25]],
              "top-right",
              ["literal", [-0.2, 0.05]],
              "right",
              ["literal", [-0.4, 0.05]],
              "bottom-right",
              ["literal", [-0.2, -0.05]],
              ["literal", [0, -0.25]],
            ],
            7,
            ["literal", [0, 0]],
          ],
          "text-anchor": [
            "step",
            ["zoom"],
            ["coalesce", ["get", "text_anchor"], "center"],
            7,
            "center",
          ],
          "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
          "text-max-width": 6,
        },
        paint: {
          "icon-opacity": [
            "step",
            ["zoom"],
            ["case", ["has", "text_anchor"], 1, 0],
            7,
            0,
          ],
          "text-color": "hsl(0, 0%, 42%)",
          "text-halo-color": [
            "interpolate",
            ["linear"],
            ["zoom"],
            2,
            "rgba(255,255,255,0.75)",
            3,
            "hsl(0, 0%, 100%)",
          ],
          "text-halo-width": 1.25,
        },
      },
    ],
    terrain: { source: "terrain-dem-3d", exaggeration: 2 },
    metadata: {
      controls: {
        inputs: [
          [
            { id: "map-terrain-light-masked", name: "basemap", checked: true },
            t("Terrain Map"),
          ],
          [{ id: "map-satellite-base", name: "basemap" }, t("Satellite Map")],
          [
            { id: "gear", name: "hazard", checked: true },
            "Earthquake  Likelihood",
          ],
          [
            { id: "cwbi", name: "hazard", checked: true },
            t("Shaking Forecast"),
          ],
          [{ id: "push-mmi-100", name: "hazard" }, "Shaking Forecast"],
          [{ id: "scenarios", name: "hazard" }, t("Scenario Shaking")],
          [{ id: "scenarios-us", name: "hazard" }, t("Scenario Shaking")],
          [
            { id: "landslide-us", name: "hazard" },
            t("Landslide Susceptibility"),
          ],
          [
            { id: "landslide-tw", name: "hazard" },
            t("Landslide Susceptibility"),
          ],
          [{ id: "wildfire", name: "hazard" }, "Wildfire Hazard"],
          [
            { id: "liquefaction", name: "hazard" },
            t("Liquefaction Susceptibility"),
          ],
          [{ id: "tsunami", name: "hazard" }, t("Tsunami Hazard")],
          [{ id: "tsunami-tw", name: "hazard" }, t("Tsunami Hazard")],
          [{ id: "flood", name: "hazard" }, "Flood Susceptibility"],
          [{ id: "faults", checked: true }, t("Faults")],
          [
            { id: "quakes-merged", name: "quakes", checked: true },
            t("Earthquakes"),
          ],
          [{ id: "palert", name: "hazard" }, t("Earthquake Alert")],
        ],
        layerIds: {
          "map-terrain-light-masked": [
            "terrain-background",
            "terrain-hillshade",
            "coastline",
            "waterway",
            "land-structure-polygon",
            "land-structure-line",
            "aeroway-polygon",
            "aeroway-line",
            "building-outline",
            "building",
            "tunnel-street-minor-low",
            "tunnel-street-minor-case",
            "tunnel-primary-secondary-tertiary-case",
            "tunnel-major-link-case",
            "tunnel-motorway-trunk-case",
            "tunnel-construction",
            "tunnel-path",
            "tunnel-steps",
            "tunnel-major-link",
            "tunnel-pedestrian",
            "tunnel-street-minor",
            "tunnel-primary-secondary-tertiary",
            "tunnel-motorway-trunk",
            "road-pedestrian-case",
            "road-minor-low",
            "road-street-low",
            "road-minor-case",
            "road-street-case",
            "road-secondary-tertiary-case",
            "road-primary-case",
            "road-major-link-case",
            "road-motorway-trunk-case",
            "road-construction",
            "road-path",
            "road-steps",
            "road-major-link",
            "road-pedestrian",
            "road-minor",
            "road-street",
            "road-secondary-tertiary",
            "road-primary",
            "road-motorway-trunk",
            "road-rail",
            "bridge-pedestrian-case",
            "bridge-street-minor-low",
            "bridge-street-minor-case",
            "bridge-primary-secondary-tertiary-case",
            "bridge-major-link-case",
            "bridge-motorway-trunk-case",
            "bridge-construction",
            "bridge-path",
            "bridge-steps",
            "bridge-major-link",
            "bridge-pedestrian",
            "bridge-street-minor",
            "bridge-primary-secondary-tertiary",
            "bridge-motorway-trunk",
            "bridge-rail",
            "bridge-major-link-2-case",
            "bridge-motorway-trunk-2-case",
            "bridge-major-link-2",
            "bridge-motorway-trunk-2",
            "admin-1-boundary-bg",
            "admin-0-boundary-bg",
            "admin-1-boundary",
            "admin-0-boundary",
            "admin-0-boundary-disputed",
            "water",
            "road-label",
            "waterway-label",
            "natural-line-label",
            "natural-point-label",
            "water-line-label",
            "water-point-label",
            "poi-label",
            "airport-label",
            "settlement-subdivision-label",
            "settlement-label",
            "state-label",
            "country-label",
          ],
          "map-satellite-base": ["background-s", "satellite-s"],
          gear: ["gear"],
          cwbi: ["cwbi"],
          "push-mmi-100": ["push-mmi-100"],
          scenarios: ["scenarios-cwbi"],
          "scenarios-us": [],
          "landslide-us": ["landslide-us"],
          "landslide-tw": ["landslide-tw"],
          wildfire: ["wildfire"],
          liquefaction: ["liquefaction"],
          tsunami: ["tsunami"],
          "tsunami-tw": ["tsunami-tw"],
          flood: ["flood"],
          faults: [
            "slab2",
            "fault_zones",
            "fault_traces_target",
            "fault_traces",
          ],
          "quakes-merged": ["quakes-merged", "quakes-merged"],
          palert: ["palert-stations", "palert-events"],
        },
      },
    },
  };
}
