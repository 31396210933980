import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, ProgressCircular } from "react-onsenui";
import Map from "./Map";
import BuildingDetailForm from "./BuildingDetailForm";
import {
  formatMoney,
  round1000,
  capitalLetter,
  stringToKey,
} from "./lib/helpers";
import { apiUrl } from "./lib/config";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

const safety_codes = { "before-1950": 0, "1950-1975": 1, "after-1975": 2 };

class RiskData extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    const eqData = {
      type: "FeatureCollection",
      metadata: {
        title: "Earthquakes",
        status: 200,
        count: 0,
      },
      features: [],
    };

    // EQ RELATED STUFF DUPLICATE OF IN EARTHQUAKES.js
    const min_mag = 1;
    const max_mag = 9;
    const min_lon = -180;
    const max_lon = 180;
    const min_lat = -90;
    const max_lat = 90;
    const now = Date.now();
    const date1 = new Date(now - 2592000000).toISOString().slice(0, 10);
    const date2 = new Date(now + 86400000).toISOString().slice(0, 10);
    const eqParams = {
      min_mag,
      max_mag,
      start_date: date1,
      end_date: date2,
      min_lat,
      max_lat,
      min_lon,
      max_lon,
    };
    this.updateEqData = this.updateEqData.bind(this);
    this.fetchEarthquakes = this.fetchEarthquakes.bind(this);
    // EQ STUFF ENDS
    this.state = {
      insurance_deductible: "",
      benefitRetrofit: 1,
      benefitInsurance: 0,
      eqParams,
      eqLoading: true,
      eqData,
      isScoreInfoModalOpen: false,
    };
    this.handleInsuranceRetrofitState = this.handleInsuranceRetrofitState.bind(
      this
    );
    this.scoreInfoModalToggle = this.scoreInfoModalToggle.bind(this);
    this.tw_describeCwbi = this.tw_describeCwbi.bind(this);
    this.tw_translateFaultName = this.tw_translateFaultName.bind(this);
  }

  componentDidMount() {}

  scoreInfoModalToggle(bool) {
    this.setState({ isScoreInfoModalOpen: bool });
  }

  calculateRetrofitBenefit() {
    const code = safety_codes[this.props.risk_data.yearbuilt];
    let retrofitBenefit, damage_current, damage_retrofit;
    if (code === 2) {
      retrofitBenefit = 0;
    } else {
      damage_current = this.props.risk_data.scenario_risk[code][
        "most_probable_damage"
      ];
      damage_retrofit = this.props.risk_data.scenario_risk[code + 1][
        "most_probable_damage"
      ];
      retrofitBenefit = damage_current - damage_retrofit;
    }
    return retrofitBenefit;
  }

  mapHeight() {
    /* return window.innerHeight - (48 + 48 + 249); */
    return window.innerHeight - (44 + 44);
  }

  getPush(data) {
    const mmi = [
      "I. Not felt",
      "II. Weak",
      "III. Weak",
      "IV. Light",
      "V. Moderate",
      "VI. Strong",
      "VII. Very strong",
      "VIII. Severe",
      "IX. Violent",
      "X. Extreme",
    ];
    return data.push_mmi && mmi[Math.floor(data.push_mmi["100yr"]) - 1];
  }

  outOfPocketCost() {
    //if no insurance deductible = 100
    let deductible = this.benefitInsuranceLabel(this.state.benefitInsurance)[
      "value"
    ];
    if (deductible === 0) deductible = 100;
    const cost = this.props.risk_data.replacement_cost * deductible;
    return Math.min(cost, this.repairCost());
  }

  repairCost() {
    const risk = this.props.risk_data.scenario_risk[
      safety_codes[this.props.risk_data.yearbuilt]
    ];
    const retrofitBenefit =
      this.state.benefitRetrofit === 1 ? this.calculateRetrofitBenefit() : 0;
    return risk.most_probable_damage - retrofitBenefit;
  }

  benefitRetrofitLabel(i) {
    const labels = [
      { value: "", text: "without retrofit" },
      { value: "yes", text: "with retrofit" },
    ];
    return labels[i];
  }

  benefitInsuranceLabel(i) {
    const labels = [
      { value: 0, text: "w/out insurance" },
      { value: 0.05, text: "w/insurance (5% deduct.)" },
      { value: 0.1, text: "w/insurance (10% deduct.)" },
      { value: 0.15, text: "w/insurance (15% deduct.)" },
      { value: 0.2, text: "w/insurance (20% deduct.)" },
      { value: 0.25, text: "w/insurance (25% deduct.)" },
    ];
    return labels[i];
  }

  handleBenefitRetrofitToggle() {
    //this.props.updateRiskData() params???
    const benefitRetrofit = this.state.benefitRetrofit === 1 ? 0 : 1;
    this.setState({ benefitRetrofit });
    let params = this.props.buildingParams;
    params["retrofit"] = this.benefitRetrofitLabel(this.state.benefitRetrofit)[
      "value"
    ];
    params["insurance_deductible"] = this.benefitInsuranceLabel(
      this.state.benefitInsurance
    )["value"];
  }

  handleInsuranceRetrofitState(insurance, retrofit) {
    const insuranceMatch = {
      "0": 0,
      "0.05": 1,
      "0.10": 2,
      "0.15": 3,
      "0.20": 4,
      "0.25": 5,
    };
    const retrofitMatch = { no: 0, yes: 1 };
    /* console.log(
     *   `${insurance}/${insuranceMatch[insurance]}  ${retrofit}/${retrofitMatch[retrofit]}`
     * ); */
    this.setState({
      benefitRetrofit: retrofitMatch[retrofit],
      benefitInsurance: insuranceMatch[insurance],
    });
  }

  getScore() {
    //return Math.random(10);
    const score = this.props.risk_data.risk[
      safety_codes[this.props.risk_data.yearbuilt]
    ].score;
    return score;
  }

  handleBenefitInsuranceToggle() {
    //this.props.updateRiskData() params???
    const benefitInsurance =
      this.state.benefitInsurance === 5 ? 0 : this.state.benefitInsurance + 1;
    this.setState({ benefitInsurance });
    let params = this.props.buildingParams;
    params["retrofit"] = this.benefitRetrofitLabel(this.state.benefitRetrofit)[
      "value"
    ];
    params["insurance_deductible"] = this.benefitInsuranceLabel(
      this.state.benefitInsurance
    )["value"];
    //this.props.updateRiskData(params)
  }

  // EQ FETCH functions are duplicate of in Earthquakes.js MER]GE them
  updateEqData(params) {
    // console.log('updateEqData FIRED');
    let eqParamsCopy = Object.assign({}, this.state.eqParams);
    Object.keys(params).forEach((key) => (eqParamsCopy[key] = params[key]));
    this.setState({ eqParams: eqParamsCopy, eqLoading: true }, () =>
      this.fetchEarthquakes()
    );
  }

  fetchEarthquakes() {
    this.setState({ eqData: {}, eqLoading: true });
    const {
      start_date,
      end_date,
      min_mag,
      max_mag,
      min_lat,
      max_lat,
      min_lon,
      max_lon,
    } = this.state.eqParams;
    fetch(
      `${apiUrl}/earthquakes?start_date=${start_date}&end_date=${end_date}&min_lat=${min_lat}&max_lat=${max_lat}&min_lon=${min_lon}&max_lon=${max_lon}&min_mag=${min_mag}&max_mag=${max_mag}`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        this.setState({ eqData: data, eqLoading: false });
        //if (this.state.eqData.metadata.count === 0) this.showToast("Move the map or zoom out to see earthquakes");
      });
  }

  tw_describeCwbi(cwbi) {
    const damageArr = [
      ["Micro", ""],
      ["Very minor", ""],
      ["Minor", "Hanging lamps vibrate slightly"],
      ["Light", "Buildings shake, hanging objects shake visibly"],
      ["Moderate", "Buildings rock noticably; may cause slight damage"],
      ["Strong", "Walls crack; some chimneys and large archways topple over"],
      ["Very strong", "Damage to some buildings, doors and windows bend"],
      [
        "Great",
        "Severe damage or collapse to some buildings, underground lines break",
      ],
    ];

    const damageArrCn = [
      ["無感", ""],
      ["微震", ""],
      ["輕震", "吊燈輕微震動"],
      ["弱震", "建築物晃動，懸掛的物體明顯晃動"],
      ["中震", "建築物明顯晃動,可能造成輕微損壞"],
      ["強震", "牆壁開裂,一些煙囪與大型拱門傾覆"],
      ["烈震", "損壞某些建築物，門窗彎曲"],
      ["劇震", "某些建築物遭受嚴重破壞或崩塌，地下管線斷裂。"],
    ];
    if (parseInt(cwbi) > 7) return ["Too High", "CWBI is larger than 7"];
    /* console.log(damageArr[cwbi.toFixed(0)]); */
    return i18n.languages[0] == "cn"
      ? damageArrCn[cwbi.toFixed(0)]
      : damageArr[cwbi.toFixed(0)];
  }

  tw_cwbiRoman(number) {
    const data = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
    };

    return data[number.toFixed(0)];
  }

  tw_displayCwbi(cwbi) {
    let sign = "";
    if (
      (Math.floor(cwbi) === 5 || Math.floor(cwbi) === 6) &&
      parseFloat((Math.abs(cwbi) - Math.floor(cwbi)).toFixed(1)) > 0.5
    ) {
      sign = "+";
    }

    return Math.floor(cwbi) + sign;
  }

  tw_translateFaultName(faults) {
    const that = this;
    const names = faults.split(",");
    let result = [];
    names.forEach(function (item) {
      result.push(that.props.t("risk.tw.fault_names." + stringToKey(item)));
    });
    return result.join(", ");
  }

  render() {
    let map,
      gear,
      score,
      mag,
      fault,
      fault_cn,
      pga,
      safety,
      recovery,
      repairCost,
      outOfPocketCost;
    let legendData = {}; // will be displayed in map legends

    // Taiwan related variables
    let cwbi, scenario_cwbi, push_cwbi, damage;
    mag = fault = fault_cn = gear = pga = safety = recovery = repairCost = outOfPocketCost =
      "...";

    if (this.props.loading === false) {
      ReactGA.set({ page: this.props.url }); // Update the user's current page
      ReactGA.set({ title: `Risk - ${this.props.address}` });
      ReactGA.pageview(this.props.url); // Record a pageview for the given page
    }

    if (this.props.loading === false) {
      if (this.props.risk_data.region === "tw") {
        // LOCATION IS IN TAIWAN
        /* console.log(`LANGUAGE: ${i18n.languages[0]}`); */
        mag = this.props.risk_data.scenario.mag.toFixed(1);
        fault = this.props.risk_data.scenario.fault;
        pga = this.props.risk_data.scenario.pga;
        gear = this.props.risk_data.gear.toFixed(1);
        legendData["gear"] = gear;
        cwbi = this.props.risk_data.cwbi;
        push_cwbi = this.props.risk_data.push_cwbi;
        scenario_cwbi = this.tw_displayCwbi(this.props.risk_data.scenario_cwbi);
        damage = this.tw_describeCwbi(this.props.risk_data.scenario_cwbi);
        legendData["tw_shaking"] = damage;
        /* console.log(`FAULT-KEY: ${stringToKey(fault)}`); */
        console.log("raw fault name: " + fault);
        fault = this.tw_translateFaultName(fault);
        console.log("translated fault name: " + fault);
        legendData["tw_scenario"] = { fault, mag, scenario_cwbi };
        /* if (fault_cn.startsWith("risk.tw.fault_names.") === false) {
         *   fault = i18n.languages[0] === "cn" ? fault_cn : fault;
         * } */

        // const safety_codes = {'before-1950': 0, '1950-1975': 1, 'after-1975': 2};
        // let safety_code_index = (this.state.benefitRetrofit === 1 ? 2: safety_codes[this.props.risk_data.yearbuilt]);
        // //let risk = this.props.risk_data.scenario_risk[safety_codes[this.props.risk_data.yearbuilt]];
        // let risk = this.props.risk_data.scenario_risk[safety_code_index];
        // score = this.props.risk_data.risk[safety_codes[this.props.risk_data.yearbuilt]].score;
        // ({ safety, recovery } = risk);
        // repairCost = formatMoney(round1000(this.repairCost()));
        // outOfPocketCost = formatMoney(round1000(this.outOfPocketCost()));
        map = (
          <Map
            screen="risk"
            height={this.mapHeight()}
            region={this.props.risk_data.region}
            lat={this.props.lat}
            lon={this.props.lon}
            hasMarker={true}
            legendData={legendData}
            zoom={7}
            dataLoading={this.props.loading}
            hazardData={this.props.hazardData}
            fetchEq={true}
            updateEqData={this.updateEqData}
            eqData={this.state.eqData}
            eqParams={this.state.eqParams}
            eqLoading={this.state.eqLoading}
            redirect={this.props.redirect}
            scoreInfoModalToggle={this.scoreInfoModalToggle}
          />
        );
        return (
          <div>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: this.mapHeight(),
              }}
            >
              {map}
            </div>
            {/* <div
		style={{
                backgroundColor: "#FFFFFF",
                margin: "0",
                padding: "10px",
		}}
		>
		<div style={{ textAlign: "left" }}>
                <h3>{this.props.t("strongest_shaking_in_your_life_time")}</h3>
                <div>
                <strong>{damage[0]} </strong> {damage[1]}
                </div>
		</div>
		<h3>{this.props.t("risk.tw.scenario_forecast_title")}</h3>
		<div style={{ textAlign: "left" }}>
                <strong>
                {this.props.t("risk.tw.possible_mag_from_scenario", {
                mag: mag,
                })}
                </strong>{" "}
                <span style={{ fontSize: "12px" }}>
                {fault} {this.props.t("risk.tw.cwb_intensity")}:{" "}
                {scenario_cwbi} per E-DREaM
                </span>
		</div>
		</div> */}
          </div>
        );
      }

      if (
        this.props.risk_data.scenario &&
        this.props.risk_data.scenario_risk[
          safety_codes[this.props.risk_data.yearbuilt]
        ]["most_probable_damage"] > 1000
      ) {
        // THE LOCATION IS IN US
        mag = this.props.risk_data.scenario.mag.toFixed(1);
        fault = capitalLetter(this.props.risk_data.scenario.fault);
        /* pga = this.props.risk_data.scenario.pga.toFixed(0) / 100; */
        pga = this.props.risk_data.scenario.pga;
        legendData["scenarios_us"] = { mag, fault, pga };
        gear = this.props.risk_data.gear.toFixed(1);
        legendData["gear"] = gear;
        legendData["push"] = this.getPush(this.props.risk_data);
        const safety_codes = {
          "before-1950": 0,
          "1950-1975": 1,
          "after-1975": 2,
        };
        let safety_code_index =
          this.state.benefitRetrofit === 1
            ? 2
            : safety_codes[this.props.risk_data.yearbuilt];
        //let risk = this.props.risk_data.scenario_risk[safety_codes[this.props.risk_data.yearbuilt]];
        /* let risk = this.props.risk_data.scenario_risk[safety_code_index]; */
        /* score = this.props.risk_data.risk[
         *   safety_codes[this.props.risk_data.yearbuilt]
         * ].score;
         * ({ safety, recovery } = risk);
         * repairCost = formatMoney(round1000(this.repairCost()));
         * outOfPocketCost = formatMoney(round1000(this.outOfPocketCost())); */
        map = (
          <Map
            screen="risk"
            height={this.mapHeight()}
            region="us"
            lat={this.props.lat}
            lon={this.props.lon}
            hasMarker={true}
            legendData={legendData}
            zoom={7}
            dataLoading={this.props.loading}
            hazardData={this.props.hazardData}
            fetchEq={true}
            updateEqData={this.updateEqData}
            eqData={this.state.eqData}
            eqParams={this.state.eqParams}
            eqLoading={this.state.eqLoading}
            redirect={this.props.redirect}
            scoreInfoModalToggle={this.scoreInfoModalToggle}
          />
        );
      } else {
        // LOCATION IS OUT OF US
        let score;
        gear = this.props.risk_data.gear.toFixed(1);
        legendData["gear"] = gear;
        legendData["push"] = this.getPush(this.props.risk_data);
        if (this.props.risk_data.risk) {
          score = this.props.risk_data.risk[
            safety_codes[this.props.risk_data.yearbuilt]
          ].score;
        } else {
          score = this.props.risk_data.gear_risk[0].score;
        }
        return (
          <div>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: this.mapHeight(),
              }}
            >
              <Map
                screen="risk"
                height={this.mapHeight()}
                region="global"
                lat={this.props.lat}
                lon={this.props.lon}
                hasMarker={true}
                eqScore={score}
                scenarioMag={mag}
                zoom={7}
                legendData={legendData}
                dataLoading={this.props.loading}
                hazardData={this.props.hazardData}
                fetchEq={true}
                updateEqData={this.updateEqData}
                eqData={this.state.eqData}
                eqParams={this.state.eqParams}
                eqLoading={this.state.eqLoading}
                redirect={this.props.redirect}
                scoreInfoModalToggle={this.scoreInfoModalToggle}
              />
              ;
            </div>
            {/* <div>
		<span style={{fontSize: '16px', color: '#656060'}}>Earthquake Score:</span> <span style={{fontSize: '24px', color: '#d02929'}}>{score}</span><span className="link">/100 <ScoreInfo /></span> 
		<h3>Earthquake Forecast</h3>
		<strong>M={this.props.risk_data.gear.toFixed(1)} </strong> In your
		life time{" "}
		<span className="grey-text" style={{ fontSize: "12px" }}>
		(Temblor GEAR model)
		</span>
		</div> */}
          </div>
        );
      }

      return (
        <div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: this.mapHeight(),
            }}
          >
            {map}
          </div>

          {/* <div
              style={{ backgroundColor: "#FFFFFF", margin: "0", padding: "10px" }}
              >
              <div style={{ fontSize: "14px" }}>
	      <strong>Earthquake Forecast</strong>
	      </div>
              <div>
              <h3>Earthquake Forecast</h3>
              <div style={{ textAlign: "left" }}>
              <strong>M={gear} </strong> In your life time{" "}
              <span className="grey-text" style={{ fontSize: "12px" }}>
              (Temblor GEAR model)
              </span>
              </div>
              <div style={{ textAlign: "left" }}>
              <strong>M={mag}</strong> Possible{" "}
              <span className="grey-text" style={{ fontSize: "12px" }}>
              ({fault}, {pga}g, per USGS)
              </span>
              </div>
              </div> */}

          {/* <BuildingDetailForm
			    id={this.props.id}
			    address={this.props.address}
			    updateRiskData={this.props.updateRiskData}
			    handleInsuranceRetrofitState={this.handleInsuranceRetrofitState}
			    /> */}
          {/* <div className="" style={{ fontSize: "12px", textAlign: "center" }}>
			    {safety}, and {recovery} repair time
			    </div> */}
          {/* <Row
			    style={{
			    height: "100%",
			    padding: "0px",
			    margin: "0px 0px 10px 0px",
			    }}
			    >
			    <Col
			    style={{ textAlign: "center", padding: "0px 2px" }}
			    onClick={() => this.handleBenefitRetrofitToggle()}
			    >
			    <div className="risk-info-header">Likely Repair Cost</div>
			    <div
			    className="risk-info"
			    style={{ color: "#000000", fontSize: "20px", height: "21px" }}
			    >
			    ${repairCost}
			    </div>
			    <div className="btn-medium" style={{ marginTop: "5px" }}>
			    {
			    this.benefitRetrofitLabel(this.state.benefitRetrofit)[
			    "text"
			    ]
			    }
			    </div>
			    </Col>
			    <Col
			    style={{ textAlign: "center", padding: "0px 2px" }}
			    onClick={() => this.handleBenefitInsuranceToggle()}
			    >
			    <div className="risk-info-header">Out-of-Pocket Cost</div>
			    <div
			    className="risk-info"
			    style={{ color: "#000000", fontSize: "20px", height: "21px" }}
			    >
			    ${outOfPocketCost}
			    </div>
			    <div className="btn-medium" style={{ marginTop: "5px" }}>
			    {
			    this.benefitInsuranceLabel(this.state.benefitInsurance)[
			    "text"
			    ]
			    }
			    </div>
			    </Col>
			    </Row> */}
          {/* <div>
		  <h5>Possible Damage</h5>
		  <div className="" style={{ fontSize: "12px", textAlign: "left" }}>
                  {safety}, and {recovery} repair time
		  </div>
		  <div style={{ marginTop: "10px" }}>
                  <Link to={`${this.props.url}/providers`}>
                  <Button modifier="large" style={{ fontSize: "14px" }}>
                  Find insurance, retrofit, engineer, consultant
                  </Button>
                 <div className="btn-medium-inv">
		  Find insurance/retrofit/engineer/consultant
		  </div> 
                  </Link>
		  </div>
              </div>
              </div> */}
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <ProgressCircular size="30px" indeterminate />
        </div>
      );
    }
  }
}
export default withTranslation("translations")(RiskData);
