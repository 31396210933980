import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Input, Col, Row, Checkbox } from "react-onsenui";
import PhoneInput from "react-phone-number-input";
import isEmail from "validator/lib/isEmail";
import isInt from "validator/lib/isInt";
import auth from "./lib/auth";
import "react-phone-number-input/rrui.css";
import "react-phone-number-input/style.css";
import { apiUrl } from "./lib/config";
import { withTranslation } from "react-i18next";

const url = apiUrl + "/auth";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "login",
      token: "",
      phone: "",
      email: "",
      firstname: "",
      lastname: "",
      info: [],
      access_code: "",
      loginError: "",
      verifyError: "",
      emailError: "",
      infoError: "",
    };
    //this.loginHandle(e)
    this.loginSubmit = this.loginSubmit.bind(this);
    this.postData = this.postData.bind(this);
    this.emailSubmit = this.emailSubmit.bind(this);
    this.infoOnChange = this.infoOnChange.bind(this);
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      body: JSON.stringify(data), // must match 'Content-Type' header
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "omit", // include, same-origin, *omit
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/json",
      },
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // *client, no-referrer
    }).then((response) => response.json()); // parses response to JSON
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.state.email === "") {
      this.setState({
        loginError: "Enter your email address to passwordless login",
      });
    } else {
      this.setState({ error: "" });
      this.postData(`${url}/login`, { email: this.state.email })
        .then((data) => {
          if (data.result === "error") {
            this.setState({ loginError: data.message });
          } else {
            this.setState({
              screen: "verify",
              verifyError: "",
              access_code: "",
            });
          }
        })
        .catch((error) => console.error(error));
    }
  }
  verifySubmit(e) {
    e.preventDefault();
    if (
      this.state.access_code === "" ||
      isInt(this.state.access_code) === false
    ) {
      this.setState({
        verifyError: "Enter 4 digits access code we've sent to your email",
      });
    } else {
      this.setState({ error: "" });
      this.postData(`${url}/verify`, {
        email: this.state.email,
        access_code: this.state.access_code,
      })
        .then((data) => {
          if (data.result === "error") {
            this.setState({ verifyError: data.message });
          } else {
            const screen = "success";
            this.setState({
              token: data.token,
              screen,
              emailError: "",
              email: "",
            });
          }
        }) // JSON from `response.json()` call
        .catch((error) => console.error(error));
    }
  }

  verifyOnChange(e) {
    const input = e.target.value;
    this.setState({ access_code: input });
  }

  emailSubmit(e) {
    e.preventDefault();
    //const input = e.target.value
    //console.log(this.state.email);
    if (isEmail(this.state.email)) {
      this.setState({
        screen: "add_info",
        infoError: "",
        firstname: "",
        lastname: "",
        info: [],
      });
    } else {
      this.setState({ emailError: "Email is invalid" });
    }
  }

  infoOnChange(e) {
    let info = this.state.info;
    if (e.target.checked === true) {
      info.push(e.target.id);
    } else {
      const index = info.indexOf(e.target.id);
      info.splice(index, 1);
    }
    this.setState({ info });
    // {event => { this.setState({checked: event.target.checked})}}
  }

  infoSubmit(e) {
    e.preventDefault();
    this.setState({ addInfoError: "" });
    if (this.state.firstname === "" || this.state.lastname === "") {
      this.setState({ nameError: "Firstname/Lastname can't be blank" });
    } else {
      const token = this.state.token;
      this.postData(`${url}/update_user`, {
        email: this.state.email,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        info: { role: this.state.info },
        token,
      })
        .then((data) => {
          if (data.result === "error") {
            this.setState({ addInfoError: data.message });
          } else {
            //console.log('info submit SUCCESS')
            this.setState({ screen: "success" });
          }
        })
        .catch((error) => console.error(error));
    }
  }

  render() {
    if (this.state.screen === "login") {
      return (
        <div style={{}}>
          <div
            style={{
              position: "absolute",
              margin: "10px",
              width: "90%",
              top: "50%",
              left: "48%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <form onSubmit={(e) => this.loginSubmit(e)}>
              <div style={{ margin: "10px 0", padding: "0px" }}>
                <Input
                  placeholder="Enter your email address"
                  modifier="underbar"
                  style={{ width: "100%" }}
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ emailError: "", email: e.target.value })
                  }
                />
              </div>
              <div style={{ color: "RED" }}>{this.state.emailError}</div>
              <div>
                {/* {this.props.t("login.enter_your_phone")}
			      (example: 555 555 5555)
			      <br /> */}
                {this.props.t("login.will_send_verification")}
              </div>
              <div style={{ color: "RED" }}>{this.state.loginError}</div>
              <div
                className="grey-text"
                style={{ margin: "10px 0", padding: "0px" }}
              >
                {this.props.t("login.accepting_terms")}{" "}
                <Link to="/terms-of-use">Terms of Use</Link>
                <br />
                {this.props.t("login.contact_us")}{" "}
                <a href="mailto:help@temblor.net">help@temblor.net</a>
              </div>
              <div style={{ margin: "10px 0", padding: "0px" }}>
                <button type="submit" className="btn-block">
                  Send me access code
                  {/* {this.props.t("Text me the activation code")} >: */}
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    } else if (this.state.screen === "verify") {
      return (
        <div
          style={{
            position: "absolute",
            margin: "10px",
            width: "90%",
            top: "50%",
            left: "48%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <form onSubmit={(e) => this.verifySubmit(e)}>
            <div
              className="login-input"
              style={{ margin: "10px 0", padding: "0px" }}
            >
              <Input
                placeholder="XXXX"
                modifier="underbar"
                style={{ width: "100%" }}
                value={this.state.access_code}
                onChange={(e) => this.verifyOnChange(e)}
              />
            </div>
            <div
              className="grey-text"
              style={{ margin: "10px 0", padding: "0px" }}
            >
              {this.props.t("Enter the access code we've sent to your phone")}{" "}
              <br />
              {this.props.t("Do you need help? Contact us")}{" "}
              <a href="mailto:help@temblor.net">help@temblor.net</a>
            </div>
            <div
              className="link"
              onClick={() => this.setState({ screen: "login" })}
            >
              {this.props.t("Didn't receive 4-digit code?")}
            </div>
            <div style={{ margin: "10px 0", padding: "0px" }}>
              <button type="submit" className="btn-block">
                NEXT >
              </button>
            </div>
          </form>
          <div style={{ color: "RED" }}>{this.state.verifyError}</div>
        </div>
      );
    } else if (this.state.screen === "emailinput") {
      return (
        <div
          style={{
            position: "absolute",
            margin: "10px",
            width: "90%",
            top: "50%",
            left: "48%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <form onSubmit={(e) => this.emailSubmit(e)}>
            <div style={{ margin: "10px 0", padding: "0px" }}>
              <Input
                placeholder="Enter your email address"
                modifier="underbar"
                style={{ width: "100%" }}
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ emailError: "", email: e.target.value })
                }
              />
            </div>
            <div style={{ color: "RED" }}>{this.state.emailError}</div>
            <div style={{ margin: "10px 0", padding: "0px" }}>
              <button type="submit" className="btn-block">
                NEXT >
              </button>
            </div>
          </form>
        </div>
      );
    } else if (this.state.screen === "add_info") {
      return (
        <div
          style={{
            position: "absolute",
            margin: "10px",
            width: "90%",
            top: "50%",
            left: "48%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <form onSubmit={(e) => this.infoSubmit(e)}>
            <Row>
              <Col>
                <Input
                  placeholder="First Name"
                  modifier="underbar"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    this.setState({ nameError: "", firstname: e.target.value })
                  }
                />
                <Input
                  placeholder="Last Name"
                  modifier="underbar"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    this.setState({ nameError: "", lastname: e.target.value })
                  }
                />
                <div style={{ color: "RED" }}>{this.state.nameError}</div>
              </Col>
            </Row>
            <div style={{ margin: "10px 0", fontSize: "1.2em" }}>I am</div>
            <Col>
              <div>
                <Checkbox
                  inputId="homebuyer"
                  modifier="noborder"
                  onChange={(e) => this.infoOnChange(e)}
                >
                  seeking to buy a new home
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  inputId="homeowner"
                  modifier="noborder"
                  onChange={(e) => this.infoOnChange(e)}
                >
                  a homeowner
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  inputId="renter"
                  modifier="noborder"
                  onChange={(e) => this.infoOnChange(e)}
                >
                  a renter
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  inputId="realestateagent"
                  modifier="noborder"
                  onChange={(e) => this.infoOnChange(e)}
                >
                  a real estate agent
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  inputId="insurancebroker"
                  modifier="noborder"
                  onChange={(e) => this.infoOnChange(e)}
                >
                  an insurance broker
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  inputId="constructionengineer"
                  modifier="noborder"
                  onChange={(e) => this.infoOnChange(e)}
                >
                  a construction engineer
                </Checkbox>
              </div>
            </Col>
            <div style={{ color: "RED" }}>{this.state.addInfoError}</div>
            <div style={{ margin: "10px 0", padding: "0px" }}>
              <button type="submit" className="btn-block">
                NEXT >
              </button>
            </div>
          </form>
        </div>
      );
    } else if (this.state.screen === "success") {
      auth.setToken(this.state.token);
      return <Redirect to="/" />;
    }
  }
}

export default withTranslation("translations")(Login);
