import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import mapboxgl from "mapbox-gl";
/* import { default as MapboxLanguage } from "@mapbox/mapbox-gl-language"; */
/* import { default as style } from "./MapStyle"; */
/* import { default as mapStyle_no_hazard } from "./MapStyle_no_hazard";
 * import { default as MapLegendTw } from "./MapLegend_tw";
 * import { default as MapLegendUs } from "./MapLegend_us"; */
import { default as MapLogoTemblor } from "./MapLogoTemblor";
import { default as MapLogoTw } from "./MapLogoTw";

import { default as MapLegends } from "./MapLegends";
import { default as mapStyle } from "./MapStyle";

import slugify from "slugify";
import { encodeLocation } from "./lib/helpers";
/* import "./MapStyle.css"; */
import "mapbox-gl/dist/mapbox-gl.css";
/* import "./mapbox.css"; */
import "./Map.css";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import auth from "./lib/auth";
import { apiUrl, mapboxAccessToken } from "./lib/config";
import { Card, Select } from "react-onsenui";
import { displayCwbi } from "./lib/taiwan.js";

const clickEvent = window.cordova ? "touchstart" : "click";

class Map extends Component {
  constructor(props) {
    super(props);
    /* const zoom = props.zoom || 0;
     * this.state = {
     *   zoom: zoom,
     *   lon: this.props.lon,
     *   lat: this.props.lat,
     *   eqData: props.eqData,
     *   eqParams: props.eqParams,
     * }; */
    /* this.renderEqFilter = this.renderEqFilter.bind(this);
     * this.eqUrl = this.eqUrl.bind(this);
     * this.quakesMerged = this.quakesMerged.bind(this); */
  }

  selectStyle() {
    let style = mapStyle(this.props.t, this.props.region, this.props.screen);
    const region = this.props.region;
    const screen = this.props.screen;
    const inputs = style.metadata.controls.inputs;

    /*
	   0 terrain ALL
	   1 satellite ALL
	   2 gear US GLOBAL
	   3 cwbi TW
	   4 push-mmi-100 US
	   5 scenarios TW
	   6 scenarios-us US
	   7 landslide-us US
	   8 landslide-tw TW
	   9 wildfire US
	   10 liquefaction US TW
	   11 tsunami US
	   12 tsunami-tw TW
	   13 flood US
	   14 faults ALL
	   15 Quakes ALL
	   16 Palert TW
	   Total 17 
	 */

    let inputs_per_region = [];
    if (region === "tw") {
      inputs_per_region = [
        inputs[0],
        inputs[1],
        inputs[3],
        inputs[5],
        inputs[8],
        inputs[10],
        inputs[12],
        inputs[14],
        inputs[15],
        inputs[16],
      ];
    } else if (region === "us") {
      inputs_per_region = [
        inputs[0],
        inputs[1],
        inputs[2],
        inputs[4],
        inputs[6],
        inputs[7],
        inputs[9],
        inputs[10],
        inputs[11],
        inputs[13],
        inputs[14],
        inputs[15],
      ];
    } else if (region === "global") {
      inputs_per_region = [
        inputs[0],
        inputs[1],
        inputs[2],
        inputs[4],
        inputs[14],
        inputs[15],
      ];
    }

    if (screen === "earthquakes") {
      inputs_per_region = [];
    }

    style.metadata.controls.inputs = inputs_per_region;
    return style;
  }

  renderLogo(region) {
    if (region === "tw") return MapLogoTw();
    return MapLogoTemblor();
  }

  renderLegends(region, t) {
    return MapLegends(region, t, this.props.legendData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.screen === "earthquakes") {
      if (
        this.props.eqData.features[0].properties.id !==
        prevProps.eqData.features[0].properties.id
      ) {
        // Do smth
        this.forceUpdate();
      }
    }
  }

  componentDidMount() {
    let style = this.selectStyle();

    var env = {
      temblorApi: apiUrl,
      temblorToken: auth.getToken(),
    };

    // DOM utils - geoff@elygeo.net

    function createSVG(tag, ...nodes) {
      const elem = document.createElementNS("http://www.w3.org/2000/svg", tag);
      nodes.forEach((node) => {
        if (Array.isArray(node)) {
          elem.appendChild(createSVG(...node));
        } else if (typeof node === "string") {
          elem.appendChild(document.createTextNode(node));
        } else if ("tagName" in node) {
          elem.appendChild(node);
        } else {
          Object.keys(node).forEach((key) => {
            elem.setAttribute(key, node[key]);
          });
        }
      });
      return elem;
    }

    function create(tag, ...nodes) {
      if (tag === "svg") {
        return createSVG(tag, ...nodes);
      }
      const elem = document.createElement(tag);
      nodes.forEach((node) => {
        if (Array.isArray(node)) {
          elem.appendChild(create(...node));
        } else if (typeof Buffer !== "undefined" && Buffer.isBuffer(node)) {
          elem.innerHTML = node.toString();
        } else if (typeof node === "string") {
          elem.appendChild(document.createTextNode(node));
        } else if ("tagName" in node) {
          elem.appendChild(node);
        } else {
          Object.keys(node).forEach((key) => {
            elem.setAttribute(key, node[key]);
          });
        }
      });
      return elem;
    }

    // Layer control for Mapbox GL - geoff@elygeo.net

    const layersButtonSpec = [
      "button",
      {
        class: "mapboxgl-ctrl-icon mapboxgl-ctrl-layers",
        type: "button",
        title: "Layers",
        "aria-label": "Layers",
      },
      [
        "svg",
        {
          viewBox: "0 0 20 20",
          fill: "#333",
          stroke: "#fff",
          "stroke-width": "1",
          "stroke-linejoin": "round",
        },
        ["path", { d: "m10 9 -7 3.5 7 3.5 7 -3.5z" }],
        ["path", { d: "m10 6.5 -7 3.5 7 3.5 7 -3.5z" }],
        ["path", { d: "m10 4 -7 3.5 7 3.5 7 -3.5z" }],
      ],
    ];

    const legendsButtonSpec = [
      "button",
      {
        class: "mapboxgl-ctrl-icon mapboxgl-ctrl-legends",
        type: "button",
        title: "Legends",
        "aria-label": "Legends",
      },
      [
        "svg",
        {
          viewBox: "0 0 20 20",
          fill: "none",
          stroke: "#333",
          "stroke-width": "1.25",
        },
        ["circle", { cx: "10", cy: "10", r: "5.5" }],
        ["path", { d: "m10 7v1m0 1v4" }],
      ],
    ];

    const inputPrevious = {};

    function setVisibility(layerIds, map, evt) {
      const input = evt.target;
      const visibility = input.checked ? "visible" : "none";
      layerIds[input.id].forEach((id) => {
        if (id === "palert-events") {
          console.log(
            map.getSource("palert-events")._data.features[0].geometry
              .coordinates
          );
          map.setLayoutProperty("quakes-merged", "visibility", "none");
          document.getElementById("quakes-merged").checked = false;
          map.flyTo({
            center: map.getSource("palert-events")._data.features[0].geometry
              .coordinates,
          });
        }
        map.setLayoutProperty(id, "visibility", visibility);
      });
      Array.from(document.getElementsByClassName(input.id + "-legend")).forEach(
        (legend) => {
          legend.style.display = input.checked ? "flex" : "none";
        }
      );
      if (input.name) {
        if (input.name in inputPrevious) {
          const input0 = inputPrevious[input.name];
          if (input0.id !== input.id) {
            input0.checked = false;
            layerIds[input0.id].forEach((id) =>
              map.setLayoutProperty(id, "visibility", "none")
            );
            Array.from(
              document.getElementsByClassName(input0.id + "-legend")
            ).forEach((legend) => {
              legend.style.display = "none";
            });
          }
        }
        inputPrevious[input.name] = input;
      }
    }

    class LayerControl {
      constructor(options) {
        this._options = options;
      }

      onAdd(map) {
        const legends = document.getElementById("legends");
        const faultsQuakesLegends = document.getElementsByClassName(
          "faultquakes"
        );
        const { inputs, layerIds } = this._options;
        const layersButton = create(...layersButtonSpec);
        const legendsButton = create(...legendsButtonSpec);
        const listener = setVisibility.bind(null, layerIds, map);
        const panel = create(
          "div",
          { class: "layer-control", style: "display: none;" },
          [
            "ul",
            ...inputs.map(([attr, text]) => {
              const input = create("input", { type: "checkbox", ...attr });
              input.addEventListener("click", listener);
              if (attr.name && attr.checked) {
                inputPrevious[attr.name] = input;
              }
              return ["li", ["label", input, ["span", text]]];
            }),
          ]
        );
        document.getElementById("map").appendChild(panel);
        this._container = create(
          "div",
          { class: "mapboxgl-ctrl mapboxgl-ctrl-group" },
          layersButton,
          legendsButton
        );
        map.on(clickEvent, () => {
          panel.style.display = "none";
        });
        layersButton.addEventListener("click", () => {
          panel.style.display =
            panel.style.display === "none" ? "block" : "none";
        });
        legendsButton.addEventListener("click", () => {
          if (map.getLayer("fault_zones").visibility === "visible") {
            faultsQuakesLegends[0].style.display =
              faultsQuakesLegends[0].style.display === "none"
                ? "block"
                : "none";
          }
          if (map.getLayer("quakes-merged").visibility === "visible") {
            faultsQuakesLegends[1].style.display =
              faultsQuakesLegends[1].style.display === "none"
                ? "block"
                : "none";
          }
        });
        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
      }
    }

    // Clickable map element

    /* global mapboxgl */

    function clickable(map, layerId, formatter) {
      const canvas = map.getCanvasContainer();
      const popup = new mapboxgl.Popup({ closeButton: false });
      const listener = (evt) => {
        const props = evt.features[0].properties;
        popup.setLngLat(evt.lngLat).setHTML(formatter(props)).addTo(map);
      };
      map.on(clickEvent, () => {
        popup.remove();
      });
      map.on(clickEvent, layerId, listener);
      /* map.off("touchstart", layerId, listener); */
      map.on("mouseenter", layerId, () => {
        canvas.style.cursor = "pointer";
      });
      map.on("mouseleave", layerId, () => {
        canvas.style.cursor = "default";
      });
    }

    // Temblor Merged Calalog

    function eqColor() {
      const now = Date.now();
      return [
        "interpolate",
        ["linear"],
        ["-", now / 86400000, ["/", ["get", "time"], 86400]],
        1,
        "#800",
        2,
        "#f00",
        7,
        "#f80",
        30,
        "#ff0",
        60,
        "#fff",
      ];
    }
    function eqUrl(map, endpoint, token) {
      const zoom = map.getZoom();
      const minmag = Math.min(Math.max(Math.ceil(5 - 0.5 * zoom), 1), 5);
      const bounds = map.getBounds();
      const minlon = bounds.getWest();
      const maxlon = bounds.getEast();
      const minlat = bounds.getSouth();
      const maxlat = bounds.getNorth();

      return (
        `${endpoint}/earthquakes?token=${token}` +
        `&minmag=${minmag}` +
        `&minlon=${minlon}` +
        `&maxlon=${maxlon}` +
        `&minlat=${minlat}` +
        `&maxlat=${maxlat}`
      );
    }

    function eqFetch(map, endpoint, token) {
      fetch(eqUrl(map, endpoint, token))
        .then((resp) => resp.json())
        .then((data) => {
          map.getSource("quakes-merged").setData(data);
        });
    }

    const that = this;
    function eqPopup(map, popup, evt) {
      const feat = evt.features[0];
      const loc = feat.geometry.coordinates.slice();
      const { time, mag, dep, id } = feat.properties;
      const t = new Date(time * 1000);
      const net = id.slice(0, 2);
      const nid = id.slice(2);
      const [url, text] = {
        CO: ["https://www.sgc.gov.co/sismos", "SGC, Colombia"],
        J0: ["https://www.hinet.bosai.go.jp/", "NIED, Japan"],
        J1: ["https://www.hinet.bosai.go.jp/", "NIED, Japan"],
        EM: ["https://www.emsc-csem.org/Earthquake/", "EMSC, France"],
        MX: ["http://www.ssn.unam.mx/sismicidad/ultimos/", "UNAM, México"],
        IT: [`http://cnt.rm.ingv.it/en/event/${nid}`, "Details (INGV, Italia)"],
        NZ: [
          `https://www.geonet.org.nz/earthquake/${nid}`,
          "Details (GeoNet, NZ)",
        ],
        TW: [
          `https://scweb.cwb.gov.tw/zh-tw/earthquake/details/${nid}`,
          "Details (CWB, Taiwan)",
        ],
        US: [
          `https://earthquake.usgs.gov/earthquakes/eventpage/${nid}/executive`,
          "Details (USGS)",
        ],
        PH: [
          "https://www.phivolcs.dost.gov.ph/index.php/earthquake/earthquake-information3",
          "PHIVOLCS",
        ],
      }[net];
      const html =
        /* `<h2 lang="en" style="margin: 0 0 4px 0;">Earthquake</h2>` +
         * `<h2 lang="zh" style="margin: 0 0 4px 0;">地震</h2>` + */
        [
          t.toDateString(),
          t.toTimeString(),
          `${that.props.t("magnitude")} ${mag}`,
          `${that.props.t("eq_depth", { d: dep })}`,
          `<a href="${url}">${text}</a>`,
        ].join("<br>");
      popup.setLngLat(loc).setHTML(html).addTo(map);
    }

    function quakesMerged(map, endpoint, token) {
      const legend = [
        document.getElementById("quakes-merged-legend-0"),
        document.getElementById("quakes-merged-legend-1"),
        document.getElementById("quakes-merged-legend-2"),
        document.getElementById("quakes-merged-legend-3"),
      ];
      const update = () => {
        const zoom = map.getZoom();
        const minmag = Math.min(Math.max(Math.ceil(5 - 0.5 * zoom), 1), 5);
        [0, 1, 2, 3].forEach((i) => {
          /* legend[i].textContent = minmag + i; */
        });
        map.setPaintProperty("quakes-merged", "circle-color", eqColor());
        // map.getSource("quakes-merged").setData(eqUrl(map, endpoint, token));
        eqFetch(map, endpoint, token);
      };
      update();
      map.on("moveend", update);
      const popup = new mapboxgl.Popup({ closeButton: false });
      const boundEqPopup = eqPopup.bind(null, map, popup);
      const canvas = map.getCanvasContainer();
      map.on(clickEvent, () => {
        popup.remove();
      });
      map.on(clickEvent, "quakes-merged", boundEqPopup);
      /* map.off("touchstart", "quakes-merged", boundEqPopup); */
      map.on("mouseenter", "quakes-merged", () => {
        canvas.style.cursor = "pointer";
      });
      map.on("mouseleave", "quakes-merged", () => {
        canvas.style.cursor = "default";
      });
    }

    // Temblor Hazard Map - geoff@elygeo.net

    // Default map options
    mapboxgl.accessToken = mapboxAccessToken;
    const opts = {
      style,
      attributionControl: false,
      container: "map",
      hash: false,
      /* ...externalOpts, */
      /* center: [this.state.lon, this.state.lat], */
      center: [this.props.lon, this.props.lat],
      zoom: this.props.zoom,
    };

    // Create map
    const map = new mapboxgl.Map(opts);
    document
      .getElementsByClassName("mapboxgl-ctrl-logo")[0]
      .parentNode.remove();

    // Scale bar
    map.addControl(new mapboxgl.ScaleControl(), "bottom-left");

    // Layer control
    /* if (this.props.screen !== "taiwan-alert") */
    if (this.props.screen !== "earthquakes") {
      map.addControl(new LayerControl(style.metadata.controls), "top-right");
    }
    // Navigation control
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Language
    function replace(pattern, replacement, elem) {
      if (Array.isArray(elem)) {
        return elem.map(replace.bind(null, pattern, replacement));
      }
      return pattern.test(elem) ? replacement : elem;
    }
    map.once("styledata", () => {
      const lang = localStorage.getItem("language").replace("cn", "zh");
      map.getStyle().layers.forEach((layer) => {
        const original = layer.layout && layer.layout["text-field"];
        console.log(original);
        if (original) {
          let updated = replace(/name_\S+/, `name_${lang}`, original);
          map.setLayoutProperty(layer.id, "text-field", updated);
        }
      });
    });

    // Clickable fault map
    const formatter = (props) =>
      Object.keys(props)
        .map((k) => k + ": " + props[k])
        .join("<br>");
    map.once(
      "styledata",
      clickable.bind(null, map, "fault_traces_target", formatter)
    );
    map.once("styledata", clickable.bind(null, map, "trenches", formatter));

    // Earthquakes
    map.once(
      "styledata",
      quakesMerged.bind(null, map, env.temblorApi, env.temblorToken)
    );

    const marker = new mapboxgl.Marker({ draggable: true });
    function onDragEnd() {
      var lngLat = marker.getLngLat();
      const id = encodeLocation({
        lat: lngLat.lat,
        lon: lngLat.lng,
      });
      const url = `/risk/custom-location/${id}`;
      that.props.redirect(lngLat.lat, lngLat.lng, id, "Custom Location", url);
      /* coordinates.style.display = 'block';
	       coordinates.innerHTML =
	       'Longitude: ' + lngLat.lng + '<br />Latitude: ' + lngLat.lat; */
    }

    marker.on("dragend", onDragEnd);
    if (this.props.hasMarker === true) {
      marker.setLngLat(opts.center).addTo(map);
    }
    if (this.props.screen === "risk") {
      /* const marker = new mapboxgl.Marker();
       * marker.setLngLat(opts.center).addTo(map); */
      let scoreColor;
      if (this.props.eqScore > 45) {
        scoreColor = "#ff0000";
      } else if (this.props.eqScore > 15) {
        scoreColor = "#4B253A";
      } else {
        scoreColor = "#0059b3";
      }

      const popupForScore = new mapboxgl.Popup({
        offset: 40,
        closeButton: false,
      }).setHTML(
        `<div>
            <div style="text-align: center; font-size: 12px"><strong>Earthquake Score</strong></div>
            <div style="text-align: center;">
              <span onClick="openScoreInfoModal()" style="color: ${scoreColor}; font-size: 32px;font-weight: bold;">${this.props.eqScore}</span>
              <span style="color: #CCC; font-size: 22px;font-weight: bold;">/100</span></div>
            </div>
            </div>`
      );
      /* .addTo(map); */
      /* if (this.props.region !== "tw") marker.setPopup(popupForScore); */
    }
    /* if (this.props.screen === "earthquakes" || this.props.screen === "palert") {
     *   let cwbi = document.getElementById("cwbi-legend");
     *   cwbi.style.display = "none";
     * } */

    if (this.props.screen === "earthquakes") {
      const popup = new mapboxgl.Popup({ closeButton: false });
      /* alert("map.js " + JSON.stringify(this.props.eqData)); */
      const data = { features: [this.props.eqData] };
      eqPopup(map, popup, data);
    }

    if (this.props.screen === "risk" && this.props.region === "tw") {
      // Palert
      const endpoint = "https://api-palert.temblor.net/";

      function setSource(map, marker, feature) {
        marker.setLngLat(feature.geometry.coordinates);
        if (map.getLayer("palert-events").visibility === "visible") {
          map.flyTo({
            center: feature.geometry.coordinates,
          });
        }
        const { id, time, lon, lat, dep, mag } = feature.properties;
        const date = new Date(time);
        const timeLocale = date.toLocaleString("zh-Hans", {
          timeZone: "Asia/Taipei",
        });
        if (document.getElementById("palert-event-info")) {
          document.getElementById(
            "palert-event-info"
          ).innerHTML = `${timeLocale} ${that.props.t(
            "magnitude"
          )} ${mag}<br>${lat}N, ${lon}E, ${that.props.t("eq_depth", {
            d: dep,
          })}`;
        }
        fetch(endpoint + id)
          .then((resp) => resp.json())
          .then((data) => {
            map.getSource("palert-stations").setData(data);
          });
      }

      function stationPopup(map, popup, evt) {
        const feat = evt.features[0];
        const loc = feat.geometry.coordinates.slice();
        const { city, cwbi } = feat.properties;
        popup
          .setLngLat(loc)
          .setHTML(
            `${that.props.t("City")}:&nbsp;${city}<br>${that.props.t(
              "Intensity"
            )}:&nbsp;${displayCwbi(cwbi, false)}`
          )
          .addTo(map);
      }

      function clickSource(map, marker, evt) {
        setSource(map, marker, evt.features[0]);
      }

      function pAlertInit(map) {
        const dot = create("div", { class: "palert-legend palert-event-dot" });
        dot.style.display = "none";
        const marker = new mapboxgl.Marker({
          element: dot,
          pitchAlignment: "map",
        });
        fetch(endpoint)
          .then((resp) => resp.json())
          .then((sources) => {
            const feature = sources.features[sources.features.length - 1];
            marker.setLngLat(feature.geometry.coordinates).addTo(map);
            setSource(map, marker, feature);
            map.getSource("palert-events").setData(sources);
          });
        const canvas = map.getCanvasContainer();
        const popup = new mapboxgl.Popup({ closeButton: false });
        const boundPopup = stationPopup.bind(null, map, popup);
        const boundClickSource = clickSource.bind(null, map, marker);

        map.on(clickEvent, () => {
          popup.remove();
        });

        map.on(clickEvent, "palert-stations", boundPopup);
        /* map.off("touchstart", "palert-stations", boundPopup); */
        map.on(clickEvent, "palert-events", boundClickSource);
        /* map.off("touchstart", "palert-events", boundClickSource); */

        map.on("mouseenter", "palert-stations", () => {
          canvas.style.cursor = "pointer";
        });
        map.on("mouseleave", "palert-stations", () => {
          canvas.style.cursor = "default";
        });
        map.on("mouseenter", "palert-events", () => {
          canvas.style.cursor = "pointer";
        });
        map.on("mouseleave", "palert-events", () => {
          canvas.style.cursor = "default";
        });
      }

      map.once("styledata", () => {
        pAlertInit(map);
      });
    }
  }
  render() {
    let legends =
      this.props.screen !== "earthquakes"
        ? this.renderLegends(this.props.region, this.props.t)
        : "";
    return (
      <div>
        <div id="map" style={{ height: this.props.height }}></div>
        {legends}
        {this.renderLogo(this.props.region)}
      </div>
    );
  }
}

/* export default withRouter(Map); */
export default withTranslation("translations")(Map);
